export const defaultRating =
{
    "id":"d5f465c8-c41b-4075-8c23-d476cbfc59d8",
    "name":"3-star system",
    "functionalId":"3-star system",
    "ratings":[
        {"id":"fde754cb-44a2-4768-8b35-04b2d034c2e9","name":"-","functionalId":"-"},
        {"id":"d7ba35c3-6e74-4b87-97be-070abc6b0d3a","name":"+","functionalId":"+"},
        {"id":"e2f70020-d6b2-4dd0-8242-8950d4ed0cf1","name":"++","functionalId":"++"},
        {"id":"bbd40fca-f6e8-4ff8-93d7-65e42b166c2b","name":"+++","functionalId":"+++"}
    ]
}
