import { useState } from "react";
import "./Login.css"; 
import axios from 'axios';
import { serviceEndpoints } from '../../config/ServiceEndpoints';
import {setLoginData} from '../../services/AxiosInterceptor';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
const Login = () => {
  const navigate = useNavigate();
    const [formValues,setFormValues] = useState({
      username:'',
      password:'',
      errors: {
        username:"",password:""
      }
    });
    const [unauthorized, setUnauthorized] = useState(false);
    const loginAction=(e)=>{
      e.preventDefault();
      let error = false;
      let payload = {...formValues};
      if(payload.username.trim()===""){
        payload.errors.username = t("Please enter a valid email id");
        error = true;
      }
      if(payload.password===""){
        payload.errors.password = t("Please enter a password");
        error = true;
      }
      setFormValues({...payload});
      if(!error){
        setLoginData(payload.username, payload.password); 
        try{ 
        axios.get(process.env.REACT_APP_API_BASEURL+serviceEndpoints['login']).then((result)=>{
          navigate("/home");
        }).catch((e)=>{
          console.log(e,'msg');
          if(e.response.status === 401 || e.response.status === 404)
          setUnauthorized(true);
          setTimeout(()=>{
            window.location = "/";
          },4500)
        })}
        catch(e){
          console.log(e,'msg2');
        }
      }
    }
    const handleChange = (e)=>{
      let updateNow = {...formValues};
      updateNow[e.target.id] = e.target.value;
      updateNow.errors[e.target.id]='';
      setFormValues({...updateNow})
    }
    const { t } = useTranslation();
    return <> 
      <div className="col-12 loginWrapper">
        <h2 >{ t('Welcome to the BASF DPP-database!')}</h2>
        <p className="centerTxt">{ t('DPP - Digital Product Profile - is a central database that provides guide-based data on the most important AP products worldwide and is addressed to internal use and data exchange with selected partner systems.')}</p>
        <div className="col-12">
          <div className="col-md-4 col-12 centerTxt">
            <div className={unauthorized?'failTxt':'d-none'}>{ t("Login unsuccessful!" )}</div>
            <form onSubmit={(e)=>loginAction(e)}>
              <>
              <input data-testid="username" type="text" value={formValues.username} className={formValues.errors.username!==''?'errorTxt':''} placeholder="E-Mail" id="username" onChange={(e)=>handleChange(e)}/> 
              <span className="d-block error">{formValues.errors.username}</span>
              </>
              <>
              <input data-testid="password" type="password" className={formValues.errors.password!==''?'errorTxt':''} value={formValues.password} id="password" placeholder="Passwort" onChange={(e)=>handleChange(e)}/>
              <span className="d-block error">{formValues.errors.password}</span>
              </>
              <div className="text-center submitWrapper">
              <div className="hvr-ripple-out" htmlFor="submit">
                <input type="submit" id="submit" name="submit" data-testid="login" value={t("Login")}  />
              </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className={unauthorized?'failToast':'d-none'}><span> { t("Login Failed!") } </span></div>
      </>;
  };
  
  export default Login;