import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { serviceEndpoints } from "../../../config/ServiceEndpoints";
import "./EfficacyModal.css";

function EfficacyModal(props) {
  const { t } = useTranslation();
  const { recommendationData, productId } = props;

  const [efficacyData, setEfficacyData] = useState(null);

  useEffect(() => { 
    if (
      recommendationData &&
      recommendationData.crops &&
      productId &&
      recommendationData.efficacy &&
      Object.keys(recommendationData.efficacy).length
    ) {
      // efficacy details are not returned from BE with GET/POST/PUT request
      // so fetch efficacies created for this particular product with the selected crops
      const crops = recommendationData.crops.map((crop) => crop.id).join(","); 
      const url = `${process.env.REACT_APP_API_BASEURL}${serviceEndpoints["products"]}/${productId}/efficacies?crops=${crops}`;
      axios.get(url).then((result) => {
        const efficacies = result && result.data ? result.data : [];
        const selectedEfficacy = efficacies.filter(
          (efficacy) => efficacy.id === recommendationData.efficacy.id
        );
        selectedEfficacy.length
          ? setEfficacyData(selectedEfficacy[0])
          : setEfficacyData(null);
      });
    }
  }, [recommendationData, productId]);

  return (
    <div className="row efficacyModalContainer">
      <div className="col-12">
        <div className="row">
          <div className="col-12 text-left">
            <h4 data-testid='efficacyHeader'>{t("Efficacies")}</h4>
            <span className="efficacyDesc">
              {t(
                "General or crop-specific efficacy ratings for each target. Different rating systems and data sources are available."
              )}
            </span>
          </div>
        </div>
        {efficacyData ? (
          <div className="row pt-3 contents">
            <div className="col-12">
              <div className="row py-2 recommendationBorder">
                <div className="col-md-6 col-sm-12">
                  <label className="w-100 text-left labelText">
                    {t("Functional ID")}
                    <div className="position-relative infoDiv">
                      <span>
                        {t(
                          "Predefined by the system for tracking (not adjustable)."
                        )}
                      </span>
                      <FontAwesomeIcon icon={faCircleInfo}></FontAwesomeIcon>
                    </div>
                  </label>
                  <label className="w-100 text-left">
                    {efficacyData && efficacyData.functionalId
                      ? efficacyData.functionalId
                      : "--"}
                  </label>
                </div>
              </div>
              <div className="row py-2 recommendationBorder">
                <div className="col-12">
                  <label className="w-100 text-left labelText">
                    {t("Crop(s)")}
                  </label>
                </div>
                <div className="col-12">
                  <div className="d-flex justify-content-start">
                    {efficacyData &&
                    efficacyData.crops &&
                    Array.isArray(efficacyData.crops)
                      ? efficacyData.crops.map((crop) => {
                          return (
                            <div className="chipWrapper" key={crop.id}>
                              <span>{crop.name}</span>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
              <div className="row py-2 recommendationBorder">
                <div className="col-12">
                  <div className="row">
                    <div className="col-5">
                      <label className="w-100 text-left labelText">
                        {t("Treatment Type")}
                      </label>
                    </div>
                    <div className="col-2">
                      <label className="w-100 text-center labelText">
                        {t("Min Rate")}
                      </label>
                    </div>
                    <div className="col-2">
                      <label className="w-100 text-center labelText">
                        {t("Max Rate")}
                      </label>
                    </div>
                    <div className="col-3">
                      <label className="w-100 text-center labelText">
                        {t("Unit of Measurement")}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-5">
                      <div className="d-flex justify-content-start">
                        {efficacyData &&
                        efficacyData.treatmentTypes &&
                        Array.isArray(efficacyData.treatmentTypes)
                          ? efficacyData.treatmentTypes.map((treatmentType) => {
                              return (
                                <div
                                  className="chipWrapper"
                                  key={treatmentType.id}
                                >
                                  <span>{treatmentType.name}</span>
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                    <div className="col-2">
                      <label className="w-100 text-center">
                        {efficacyData && efficacyData.minDose
                          ? efficacyData.minDose
                          : "--"}
                      </label>
                    </div>
                    <div className="col-2">
                      <label className="w-100 text-center">
                        {efficacyData && efficacyData.maxDose
                          ? efficacyData.maxDose
                          : "--"}
                      </label>
                    </div>
                    <div className="col-3">
                      <label className="w-100 text-center">
                        {efficacyData &&
                        efficacyData.uom &&
                        efficacyData.uom.functionalId
                          ? efficacyData.uom.functionalId
                          : "--"}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row py-2 recommendationBorder">
                <div className="col-12">
                  <label className="w-100 text-left labelText">
                    {t("Target Selection")}
                  </label>
                </div>
              </div>
              <div className="row py-2 recommendationBorder">
                <div className="col-12">
                  <label className="w-100 text-left labelText">
                    {t("Sources")}
                  </label>
                </div>
                <div className="col-12">
                  {efficacyData &&
                  efficacyData.links &&
                  Array.isArray(efficacyData.links)
                    ? efficacyData.links.map((link) => {
                        return (
                          <div className="w-100 text-left" key={link.id}>
                            <a href={link.url}>{link.displayTitle}</a>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
              <div className="row py-2 recommendationBorder">
                <div className="col-12">
                  <label className="w-100 text-left labelText">
                    {t("Comments")}
                  </label>
                </div>
                <div className="col-12">
                  <label className="w-100 text-left">
                    {efficacyData && efficacyData.comments
                      ? efficacyData.comments
                      : "--"}
                  </label>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="row pt-3">
            <div className="col-12">
              <label>{t("No efficacy selected")}</label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default EfficacyModal;
