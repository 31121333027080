import React, { useContext, useEffect, useState } from "react";
import FormValuesContext from "../FormValues-context";
import PristineValuesContext from "../PristineValues-context";
import { useTranslation } from "react-i18next"
import Scrollbars from "react-custom-scrollbars-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCopy, faPlus, faRemove, faTrash, faShareNodes, faCircleInfo, faPen, faTimes } from "@fortawesome/free-solid-svg-icons";
import SelectinputSingle from "../../../components/Selectinput/SelectInputSingle";
import Selectinput from "../../../components/Selectinput/Selectinput";
import ModalReusable from "../../../components/ModalReusable/ModalReusable";
import ShareModal from "../../../components/ModalTemplates/ShareModal/ShareModal";
import axios from 'axios';
import { serviceEndpoints } from '../../../config/ServiceEndpoints';
import TargetModal from "../../../components/ModalTemplates/TargetModal/TargetModal";
import { defaultRating } from "../../../config/RatingSystemConfig";
import { useParams } from 'react-router';


const EfficacyTab = (props) => {
    
    const { formValues, setFormValues } = useContext(FormValuesContext);
    const { pristineValues, setPristineValues } = useContext(PristineValuesContext);
    const {t} = useTranslation(); 
    const[modalConfig, setModalConfig] = useState({show:false}); 
    const scrollTopRef = React.createRef();
    const [multiFormFlag, setMultiFormFlag] = useState(false);
    const [selectedCrop, setSelectedCrop] = useState({crops:[]});
    const [currentTab, setCurrentTab] = useState(0);
    const scrollEndRef = React.createRef();
    const [cropCliked, setCropCliked] = useState(false);
    const [pristineList, setPristineList] =  useState([]); 
    const [scrollNow, setScrollNow] = useState(undefined);
    const [ratingSystem, setRatingSystem] = useState(defaultRating);
    const [multySelectItemList, setMultySelectItemList] = useState(
        {crops:[], sources:[], treatmentTypes:[]})
    const [linkDetails, setLinkDetails]= useState({});
    let efficacyAdded = false; //checks if clicked the buttons to add crop specific efficacy or general efficacy
    const [ratingSelectOptions,setRatingSelectedOptions] = useState([]);
    const [updateLink, setUpdateLink] = useState({});
    const [deleteEffError, setDeleteEffError] =useState(undefined);
    let {id} = useParams();
    useEffect(()=>{ 
        if(multiFormFlag){
         scrollTopRef.current.scrollIntoView({ behavior: 'smooth' })
        }
        if(!multiFormFlag && scrollEndRef.current){
         if(!scrollEndRef.current.classList.contains('cropClicked'))
         scrollEndRef.current.scrollIntoView({ behavior: 'smooth' })
        }
        if(cropCliked)
        setCropCliked(false); 
        // eslint-disable-next-line react-hooks/exhaustive-deps
             },[multiFormFlag, currentTab])
    useEffect(()=>{
        if(id && props.firstEffVisit && formValues.efficacy.length>0){ 
            const updateNow={...formValues}
            updateNow.product.id = id;
            let pList=[]
            formValues.efficacy.map((eff,indx)=>{ 
                if(eff.id)
                pList.push(true); else{
                    pList.push(false)
                }
                return eff;
            })  
            setFormValues({...updateNow})
            setPristineList(pList);
            let pristineNow = {...pristineValues};
            pristineNow.efficacy = pList;
            setPristineValues({...pristineNow});
            manageTabSelect(0);
            props.setfirstEffVisit(false);
            props.setEffVisit(false);
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps  
    },[formValues,currentTab])
    useEffect(()=>{
        if(!props.firstEffVisit || id===undefined ){
            presetFormFields(0);
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.effVisit])
    const manageForm = (status, general)=>{
        let crops=general?[]:[{}];
        setMultiFormFlag(status); setSelectedCrop({crops:crops}); 
    }
    const addGeneral = ()=>{ 
        manageForm(false,true);
        addCrops();
    }
    const addItemToCrops = ()=>{ 
        const updateNow = {...selectedCrop}; 
        updateNow.crops.push({});
        setSelectedCrop({...updateNow});
        efficacyAdded = true;
    }
    const handleChangeCrops = (event)=>{ 
        const val =  event.target.value;
        const indx = event.target.indx;
        const updateNow = {...selectedCrop}; 
            updateNow.crops[indx]= val; 
            setSelectedCrop({...updateNow});
    }
    const addCrops = () =>{
        const updateNow = {...formValues}; 
        const crops = {...selectedCrop};
        const pushableCrops = [];
        const pushableMultySelect = [];
        crops.crops.forEach(elm => {
            if(elm.name){
                pushableCrops.push(elm);
                pushableMultySelect.push({value:elm.id,label:elm.name, item:elm})
            }
        });
       // if(pushableCrops.length>0){
            let cropList = {...multySelectItemList};
            cropList['crops'] = pushableMultySelect;
            setMultySelectItemList(cropList);
        updateNow.efficacy.push({"crops":pushableCrops, "sources":[], "treatmentTypes":[], "links":[]});
        setFormValues({...updateNow});
        setSelectedCrop({crops:[{}]}); 
         setCurrentTab(formValues.efficacy.length-1);
         presetFormFields(formValues.efficacy.length-1);
         let updatePristine = pristineList;
         updatePristine.push(false);
         setPristineList(updatePristine);
         let pristineNow = {...pristineValues};
         pristineNow.efficacy.push(false);
         setPristineValues({...pristineNow});
      //  } 
        setMultiFormFlag(false);
          
    }
    const deleteSelectedCrop = (indx)=>{
        const updateNow = {...selectedCrop};
        updateNow.crops.splice(indx, 1);
        setSelectedCrop({...updateNow}); 
    }
    const manageTabSelect = (itemIndx)=>{
        setCropCliked(true); 
        setCurrentTab(itemIndx);
        presetFormFields(itemIndx);
      }
      const copyCrop = (indx,e)=>{ 
        e.preventDefault();
        e.stopPropagation();
        const updateNow = {...formValues}; 
        let copyItem  = {...updateNow.efficacy[indx]};
        delete copyItem.id;
        copyItem.status = undefined;
        updateNow.efficacy.push(copyItem);
        if(!updateNow.tabStatus)  updateNow.tabStatus={};  
        updateNow.tabStatus.efficaciesStatus = "In Editing";
        updateNow.product.efficaciesStatus = "In Editing";  
        setFormValues({...updateNow});
        setCurrentTab(formValues.efficacy.length-1);
        
        let updatePristine = pristineList;
             updatePristine.push(false);
             setPristineList(updatePristine);
             let pristineNow = {...pristineValues};
            pristineNow.efficacy.push(false);
            setPristineValues({...pristineNow});
            setScrollNow(formValues.efficacy.length-1);
      }
      const presetFormFields = (itemIndx)=>{ 
        let multySelectItems = {};
        if(formValues.efficacy[itemIndx]){
           multySelectItems = {
            crops: createItemList(formValues.efficacy[itemIndx].crops),
            sources: createItemList(formValues.efficacy[itemIndx].sources),
            treatmentTypes: createItemList(formValues.efficacy[itemIndx].treatmentTypes)
           }
       }
       setMultySelectItemList(multySelectItems); 
      }
      const handleChangeMultyCrop =(val, mapTo,)=>{ 
        const updateNow = {...formValues}; 
        let cropList = {...multySelectItemList}
        cropList[mapTo] = val; 
        setMultySelectItemList(cropList);
        updateNow.efficacy[currentTab][mapTo] = val.map(item=>item.item);
        updateNow.formPristine = false;  
        setFormValues({...updateNow});
        let updatePristine = pristineList;
         updatePristine[currentTab]= false;
         setPristineList(updatePristine);
         let pristineNow = {...pristineValues};
         pristineNow.efficacy[currentTab]= false;
         setPristineValues({...pristineNow});
      }
      const handleChange =(val, mapTo)=>{ 
        const updateNow = {...formValues}; 
        updateNow.efficacy[currentTab][mapTo] = val;
        updateNow.formPristine = false;
        let updatePristine = pristineList;
        updatePristine[currentTab] = false;
        setPristineList(updatePristine); 
        let pristineNow = {...pristineValues};
         pristineNow.efficacy[currentTab] = false;
         setPristineValues({...pristineNow});
        setFormValues({...updateNow});
        //setPristine(false);
      }
      const handleRating = (val, mapTo) =>{
        setRatingSystem(val);
        let ratingSelectOption = [];
         val.ratings.forEach((item,indx)=>{  
            ratingSelectOption.push({value:{...item,...{sorting:(indx+1)}}, label:item.name})
         }) 
         setRatingSelectedOptions(ratingSelectOption)
      }
      const handleTargetRating = (val, mapTo)=>{
        let itemIndex = mapTo.split('-')[1];
console.log(val); 
let updateNow = {...formValues};
let currentRatingSystem = {id:ratingSystem.id, name: ratingSystem.name, "sorting": null, functionalId: ratingSystem.functionalId};
let currentRating = {
    id:val.id, 
    name: val.name, 
    sorting:val.sorting,
    functionalId: val.functionalId,
    ratingSystem: currentRatingSystem
  } 
updateNow.efficacy[currentTab].targetEfficacies[itemIndex].rating = currentRating;
setFormValues(updateNow);
console.log(formValues)
}
const deleteTargetEfficacy = (indx)=>{
    let updateNow = {...formValues};
    updateNow.efficacy[currentTab].targetEfficacies.splice(indx, 1);
    setFormValues(updateNow);
}
      const manageModal = (config,status=false, inner='')=>{ 
        const modalCon = {...config,...{show:status, modalInner: inner}}
        setModalConfig(modalCon);
      }
      const shareModalConfig = {
        show:false,
        largeWindow: true,
        modalHeader:'Share Items', 
        closeButtonHandler:manageModal,
        modalButtons:[  ],           
        }
    const manageShareModalContent = (e)=>{ 
        e.preventDefault()
        manageModal(shareModalConfig, true,<ShareModal selectedRows={[formValues.product.id]} ></ShareModal> )
      }
      const targetModalConfig = {
        show:false,
        largeWindow: true,
        modalHeader:'Add Target', 
        closeButtonHandler:manageModal,
        modalButtons:[ ],           
        }
        const saveTarget = (val) =>{
            console.log(formValues);
            let updateNow = {...formValues}
            if(!updateNow.efficacy[currentTab].targetEfficacies)
            updateNow.efficacy[currentTab].targetEfficacies = [];
            let currentRatingSystem = {id:ratingSystem.id, name: ratingSystem.name, "sorting": null, functionalId: ratingSystem.functionalId};
            let currentRating = {
                id:ratingSystem.ratings[0].id, 
                name: ratingSystem.ratings[0].name, 
                sorting:1,
                functionalId: ratingSystem.ratings[0].functionalId,
                ratingSystem: currentRatingSystem
              } 
        val.forEach(item=>{
            updateNow.efficacy[currentTab].targetEfficacies.push({
                target:item.item,
                rating: currentRating
            })
        }) 
      
      let ratingSelectOption = [];
      ratingSystem.ratings.forEach((item,ind)=>{  
         ratingSelectOption.push({value:{...item,...{sorting:(ind+1)}}, label:item.name})
      }) 
      setRatingSelectedOptions(ratingSelectOption)
        setRatingSelectedOptions(ratingSelectOption);
        setFormValues({...updateNow});
        manageModal(targetModalConfig, false,
            '' )
            console.log(ratingSelectOptions)
        }
    const manageTargetModalContent = (e)=>{ 
        manageModal(targetModalConfig, true,
      <TargetModal saveTarget={saveTarget}></TargetModal> )
    }
    const updateLinkDetails = (e,mapTo)=>{
        let updateNow = {...linkDetails}
        updateNow[mapTo] = e.target.value;
        setLinkDetails(updateNow);
    }
    const addSource = ()=>{
        let updateNow = {...formValues}
        updateNow.efficacy[currentTab].links.push(linkDetails)
        setLinkDetails({});
        console.log(formValues); 
    }
    const handleChangeLinks = (val, mapTo)=>{
        let updateNow = {...updateLink}
        updateNow[mapTo] = val;
        setUpdateLink(updateNow); 
    }
    const saveUpdateLink = (indx)=>{
        let updateNow = {...formValues}; 
        updateNow.efficacy[currentTab].links[indx] = updateLink;
        setFormValues(updateNow);
        setUpdateLink({});
    }
    const deleteUpdateLink = (indx) =>{
        let updateNow = {...formValues}; 
        updateNow.efficacy[currentTab].links.splice(indx, 1);
        setFormValues(updateNow);
    }
    const createItemList=(data)=>{
        let elm = [];
        if(data)
        data.forEach((item)=>{
            elm.push({
                value: item.id,
                label: item.name,
                item:item
            });
        })
        return elm;
      }
      const saveCrop = (e)=>{
        e.preventDefault(); 
        const updateNow = {...formValues}; 
        const payload =  JSON.parse(JSON.stringify(updateNow.efficacy[currentTab])); 
        if(formValues.product.id) {
            if(!updateNow.efficacy[currentTab].id){
                axios.post(process.env.REACT_APP_API_BASEURL+serviceEndpoints['products']+'/'+formValues.product.id+'/efficacies',payload).then((res)=>{  
                    manageEffApiSuccess(res, "In Editing");
                }).catch(e=>{
                    manageEffApiCatch();
                }) 
            } else{
                axios.put(process.env.REACT_APP_API_BASEURL+serviceEndpoints['products']+'/'+formValues.product.id+'/efficacies',payload).then((res)=>{
                    manageEffApiSuccess(res,  "In Editing");
                }).catch(e=>{
                    manageEffApiCatch();
                }) 
            }
        }
      }
      const manageEffApiSuccess = (res, status, fromStatusChange)=>{ 
        let updateNow = {...formValues};  
        let data = res.data; 
        updateNow.efficacy[currentTab] = data;  
        updateNow.formError = false;
        updateNow.formSuccess = true;
        if(!updateNow.tabStatus) 
         { updateNow.tabStatus={};}

        if(status === "IN_EDITING" || status==="In Editing"){
            let r= "In Editing";
            updateNow.efficacy.forEach(elm=>{
                if(elm.status === undefined){
                    r= undefined; 
                }
            })
        updateNow.tabStatus.efficaciesStatus = r;
        updateNow.product.efficaciesStatus= r;
        } else if(status === "IN_REVIEW"){
            let r= "In Review";
            updateNow.efficacy.forEach(elm=>{
                if(elm.status === undefined){
                    r= undefined; 
                }
                if(elm.status==="In Editing"){
                  r = "In Editing";
                }
            });
            updateNow.tabStatus.efficaciesStatus = r;
             updateNow.product.efficaciesStatus = r;
        } else if(status === "APPROVED"){
            let r = [];
            updateNow.efficacy.forEach(elm=>{
                r.push(elm.status)
            });
            debugger;
            if(r.indexOf(undefined)!==-1){
                updateNow.tabStatus.efficaciesStatus = undefined;
                updateNow.product.efficaciesStatus = undefined;
            }
            else if(r.indexOf('In Editing')!==-1){
                updateNow.tabStatus.efficaciesStatus = "In Editing";
                updateNow.product.efficaciesStatus = "In Editing";
            } else if(r.indexOf('In Review')!==-1){
                updateNow.tabStatus.efficaciesStatus = "In Review";
                updateNow.product.efficaciesStatus = "In Review";
            } else{
                updateNow.tabStatus.efficaciesStatus = "Approved";
                updateNow.product.efficaciesStatus = "Approved";
            }
      }
      // updateNow.formPristine = true;
      setFormValues({...updateNow});
      // setPristine(true);
      //setReviewBtn(true);
      props.setSavedMessageFrom('efficacy');
      if(!fromStatusChange){
      let updatePristine = pristineList;
      updatePristine[currentTab] = true;
      setPristineList(updatePristine);
      let pristineNow = {...pristineValues};
         pristineNow.efficacy[currentTab] = true;
         setPristineValues({...pristineNow});
      }
       setTimeout(()=>{
           updateNow.formSuccess = false;
           setFormValues({...updateNow}); 
       }, 5000)
    }
    const manageEffApiCatch  = ()=>{
        let updateNow = {...formValues}; 
        updateNow.formError = true;
        updateNow.formSuccess = false;
        setFormValues({...updateNow});
        setTimeout(()=>{
            let updateNow = {...formValues}; 
            updateNow.formError = false;
            setFormValues({...updateNow}); 
        }, 5000)
    }
    const deleteReg = (indx)=>{
        
          if(formValues.efficacy[indx].id){
          axios.delete(process.env.REACT_APP_API_BASEURL+serviceEndpoints['products']+'/'+formValues.product.id+'/efficacies/'+formValues.efficacy[indx].id).then((res)=>{
          //  manageRegApiSuccess(res, 'In Editing', true);
          deleteSuccessUpdates(indx);
        }).catch(e=>{ 
          //  manageRegApiCatch(); 
          const message = e.response?e.response.data.message:"Opps something went wrong"
          setDeleteEffError(message);
          manageModal(deleteModalConfig, false,'');
          setTimeout(()=>{
            setDeleteEffError(undefined)
          },4000)
        })}
        else{
            deleteSuccessUpdates(indx);
        }     
    } 
    const deleteSuccessUpdates = (indx)=>{
        let updateNow = {...formValues};  
        updateNow.efficacy = [
            ...updateNow.efficacy.slice(0,indx),
            ...updateNow.efficacy.slice(indx+1)
          ]


        let updatePristine = pristineList;
        updatePristine = [
            ...updatePristine.slice(0,indx),
            ...updatePristine.slice(indx+1)
          ]
        setFormValues({...updateNow});
        setPristineList(updatePristine);
        let pristineNow = {...pristineValues};
        pristineNow.efficacy = [
            ...pristineNow.efficacy.slice(0,indx),
            ...pristineNow.efficacy.slice(indx+1)
          ]
        // pristineNow.efficacy = updatePristine;
         setPristineValues({...pristineNow});
          setCurrentTab(0);
          manageTabSelect(0);
       
          manageModal(deleteModalConfig, false,'');
    }
    const deleteModalConfig = {
        show:false,
        modalHeader:t('Approve Deletion of Efficacy'),  
        closeButtonHandler:manageModal,
        modalButtons:[
                      {label:t('Cancel'), handler:manageModal},
                      {label:t('Delete Efficacy'), classNames:"greenBtn",
                      handler:deleteReg}],           
                      }
    const deleteEffModalContent = (e, tabIndx) =>{
        e.preventDefault();
        deleteModalConfig.modalButtons[1].params = tabIndx;
        debugger;
            manageModal(deleteModalConfig, true ,t('Are you sure you want to delete this Efficacy?'))
    }
    const statusChange = (e, status)=>{
        e.preventDefault()
        let payload = {"statusChange":{"status":status,"statusChangeComment":""}};
        axios.put(process.env.REACT_APP_API_BASEURL+serviceEndpoints['products']+'/'+formValues.product.id+'/efficacies/'+formValues.efficacy[currentTab].id+'/status',payload).then((res)=>{
            manageEffApiSuccess(res, status, true);
        }).catch(e=>{ 
            manageEffApiCatch();
        })
    }
return <>
 { deleteEffError &&
 <div className="warning" data-testid="saveError"><span>!</span> <span>{deleteEffError}</span><span className="float-right cursor-pointer" onClick={()=>setDeleteEffError(undefined)}>x</span></div>
 }
     <div>   <div className={"sideBar3 efficacySidebar "+(formValues.efficacy.length ===0 ?" noVisibleScroll":"") }>
        <Scrollbars style={{ width: '310px', height: 'inherit' }} className={"customScrolld"}> 
        <div className={"p-3 pb-0 headerWrapper "+(formValues.efficacy.length !==0 ?"position-fixed":"")}>
            <h4 className="mt-0 ">{t('Efficacies')}</h4>

            <div>
            <button data-testid="addEfficacy" className="greenBtn" onClick={()=>manageForm(true,false)} disabled={multiFormFlag?true:false}>
            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
            {t('Add Efficacy (specific crops)')}</button>
            {efficacyAdded === false && <p className="infoP mb-0">{t('Click here to add target efficacy ratings for specific crops.')}</p>}
           </div>
           {<div>
           <button data-testid="addEfficacyGeneral" className="mt-3 " onClick={()=>addGeneral()} disabled={multiFormFlag?true:false}> 
            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
            {t('Add Efficacy (general)')}</button>
           </div> }
          
        </div>
        <div ref={scrollTopRef} className={"p-3 "+(formValues.efficacy.length !==0 ?"moveToBottom":"")+(multiFormFlag?" grayForm":"")+(efficacyAdded === false?" morePadding":"")}>
        {multiFormFlag && <div className="mb-2"> 
           {  selectedCrop.crops.map((item,indx) =><div className="d-flex pb-3">
           
            <SelectinputSingle config={{  endpoint:"crop", valueKey:"", labelKey:"name", labelKeySec:"eppoCode",
                            singleSelect:true, width:'220px'}} value={selectedCrop.crops[indx]}  mapTo='crop' handleChanges={handleChangeCrops} 
                            itrateValues ={{indx:indx, mapTo:'crop', stateKey:'crops'}}
                            disabledOptions = {selectedCrop.crops}></SelectinputSingle>
           { <FontAwesomeIcon data-testid="cropAddDelete" className="deleteSelectedCrop" icon={faTrash} onClick={()=>deleteSelectedCrop(indx)}/>}
            </div>  )} 
             <button data-testid="addCropItems" className="grayBtn" onClick={addItemToCrops}>{t('Add Crop')}</button>
           <FontAwesomeIcon data-testid="addCropFormValues" icon={faCheck} onClick={()=>addCrops()} className="greenBtn p-2 float-right"/>  
           <FontAwesomeIcon  data-testid="removeCropItems" icon={faRemove} onClick={()=>manageForm(false, true)} className="grayBtn p-2 float-right"/>
            </div>}


            {
                     formValues.efficacy.length>0 && formValues.efficacy.map((item, itemIndx)=>
                        <div data-testid="cropMenuElm" className={"cropsMenu"+(currentTab === itemIndx?' active':'')} onClick={()=>{manageTabSelect(itemIndx)}}> 
                            <span className={"float-left cropsSpan"+(pristineValues.efficacy[itemIndx]!==false?"":" notSaved")+
                            (item.status==="In Review"?" reviewed":item.status==="Approved"?" approved":"")}>
                            {(itemIndx+1).toString().length>=3? itemIndx+1 : 
                            ((itemIndx+1).toString().length===2? '0'+(itemIndx+1) :'00'+(itemIndx+1))}</span>
                            <div className="elepsisDiv">
                            { item.crops && item.crops.length===0 ?<>{t('General')}</>
                             :
                           item.crops&& item.crops.map((crop,indx) =>
                           <> 
                           {crop.name} {indx<item.crops.length-1 && <>,&nbsp;</>}
                           </>
                           )}
                          
                            <span className="copySpan delete"  onClick={(e)=>{deleteEffModalContent(e, itemIndx)}}><FontAwesomeIcon icon={faTrash} /></span>
                           <span className="copySpan" data-testid="copyCrop" onClick={(e)=>copyCrop(itemIndx,e)}><FontAwesomeIcon icon={faCopy} /> {t('Copy')}</span>
                          </div> 
                            {item.crops && item.crops.length!==0 && <span className="float-left">{item.crops.length} {t('Items')}</span>}
                        </div>)
                }
        </div>
        <div ref={scrollEndRef} className={("scrolled"+scrollNow)+(cropCliked?' cropClicked':'')} ></div>
        </Scrollbars>
        </div>
        <div className="tabs" data-testid="scrollTab" currentTab={currentTab} >
        <h3 className="innerHead">{t("Efficacies")} 
        {formValues.efficacy.length>0 && <button className="deleteReg" data-testid="effDelete" onClick={(e)=>{deleteEffModalContent(e, currentTab)}}>
            <FontAwesomeIcon icon={faTrash} /> {t('Delete')}
            </button>}
        </h3>
        {formValues.efficacy[currentTab] &&<>
        <div>
       {/* tab content {currentTab} crops index selected */}
        <p>{t('General or crop-specific efficacy ratings for each target. Different rating systems and data sources are available.')}</p>
            <div className="row formRow">
                    <div className="col-sm-6 col-12">
                        <label className="w-100  ">{t("Functional ID")} 
                            <div className="position-relative infoDiv">
                            <span>{t('Predefined by the system for tracking (not adjustable).')}</span>
                            <FontAwesomeIcon icon={faCircleInfo} ></FontAwesomeIcon></div>
                        </label>
                        <input type="text" value={formValues.efficacy[currentTab].functionalId?formValues.efficacy[currentTab].functionalId:''}   readOnly={true}  className="w-100" /> 
                    </div>
            </div>
            <div className="row formRow">
                    <div className="col-sm-6 col-12"> 
                        <Selectinput  value={multySelectItemList.crops} config={{endpoint:"crop", valueKey:"", labelKey:"name" , labelTxt:t('Crop(s)'), showChips:true }} handleChanges={handleChangeMultyCrop} mapTo='crops' ></Selectinput>
                    </div>
            </div> 
            <div className="row formRow">
                    <div className="col-sm-6 col-12"> 
                        <Selectinput  value={multySelectItemList.sources} config={{endpoint:"source", valueKey:"", labelKey:"name" , labelTxt:t('Sources'), showChips:true }} handleChanges={handleChangeMultyCrop} mapTo='sources' ></Selectinput>
                    </div>
            </div> 
            <div className="row formRow">
                    <div className="col-sm-3 col-12"> 
                        <Selectinput  value={multySelectItemList.treatmentTypes} config={{endpoint:"treatmentType", valueKey:"", labelKey:"name" , labelTxt:t('Treatment Type'), showChips:true }} handleChanges={handleChangeMultyCrop} mapTo='treatmentTypes' ></Selectinput>
                    </div>
                    <div className="col-sm-3 col-12">
                    <label className="w-100  ">{t("Min Rate")}
                        </label>
                        <input type="number" data-testid="minRateEff"  value={formValues.efficacy[currentTab].minDose?formValues.efficacy[currentTab].minDose:''}  className="w-100"  onChange={(e)=>handleChange(e.target.value,'minDose')}/> 
                    </div>
                    <div className="col-sm-3 col-12">
                    <label className="w-100  ">{t("Max Rate")}
                        </label>
                        <input type="number" data-testid="maxRateEff" value={formValues.efficacy[currentTab].maxDose?formValues.efficacy[currentTab].maxDose:''}  className="w-100"  onChange={(e)=>handleChange(e.target.value,'maxDose')}/> 
                    </div>
                    <div className="col-sm-3 col-12">
                    {formValues.efficacy[currentTab].uom && <SelectinputSingle config={{  endpoint:"uom", valueKey:"", labelKey:"name", labelTxt:t("Unit of Measurement"),
                            singleSelect:true}} value={formValues.efficacy[currentTab].uom}  mapTo='uom' handleChanges={handleChange} 
                    ></SelectinputSingle>}
                    {!formValues.efficacy[currentTab].uom && <SelectinputSingle config={{  endpoint:"uom", valueKey:"", labelKey:"name", labelTxt:t("Unit of Measurement"),
                            singleSelect:true}} value=""  mapTo='uom' handleChanges={handleChange} 
                    ></SelectinputSingle>}
                    </div>
            </div> 
            <div className="row formRow"> 
                    <div className="col-sm-3 col-12">
                    { <SelectinputSingle config={{  endpoint:"ratingSystem", valueKey:"", labelKey:"name", labelTxt:t("Rating System"), infoTxt:'Control = +++ = G/E = 9/10 Suppression = ++ = P/F = 6/7 n/a = + No Control = -',
                            singleSelect:true}} value={ratingSystem}  mapTo='ratingSystem' handleChanges={handleRating} disabled={formValues.efficacy[currentTab].targetEfficacies && formValues.efficacy[currentTab].targetEfficacies.length>0?true:false}
                    ></SelectinputSingle>} 
                    </div>
                    <div className="col-sm-9 col-12 text-right ">  
                        <button data-testid="addTarget" className="greenBtn mt-4 targetBtn" onClick={manageTargetModalContent}> {t('Add Target')} </button>
                    </div>
            </div>
            <div className="row formRow p-2">  
                { formValues.efficacy[currentTab].targetEfficacies && formValues.efficacy[currentTab].targetEfficacies.length>0 &&
                <table data-testid="targetTable" className="targetTable">
                    <tr>
                        <th>{t('Name')}</th>
                        <th>{t('Scientific Name')}</th>
                        <th style={{width:'100px'}}>{t('EPPO Code')}</th>
                        <th style={{width:'210px'}}>{t('Rating')}</th>
                        <th>{t('Remove')}</th>
                    </tr>
               
                    {formValues.efficacy[currentTab].targetEfficacies.map((item, targetIndx)=>
                    <tr>
                        <td>{item.target.name===""?t('Not defined'):item.target.name}</td>
                        <td>{item.target.scientificName}</td>
                        <td>{item.target.eppoCode}</td>
                        <td className="ratingInput">
                            {ratingSystem.ratings.length<=5?<>
                            {
                                ratingSystem.ratings.map((rating, indx)=>
                                     <span data-testid="ratingBtn" className={item.rating.sorting === (indx+1)?'active':''} onClick={()=>handleTargetRating(ratingSelectOptions[indx].value,'-'+targetIndx)}>{rating.name}</span>
                                    )
                            }
                           </>:<>
                           <SelectinputSingle data-testid="ratingBtn" config={{options:ratingSelectOptions, width:'200px'}} handleChanges={handleTargetRating} mapTo={'-'+targetIndx}></SelectinputSingle>
                           </>}
                        </td>
                        <td><FontAwesomeIcon data-testid="deleteTarget" icon={faTrash} onClick={()=>deleteTargetEfficacy(targetIndx)}/></td>
                    </tr>
                    )}  
                </table>
                }
            </div>
            <div className="row formRow">
                <h5>{t('Sources')}</h5>
                <div className="col-6 col-sm-4" linkUrl={linkDetails.url}> 
                <label className="w-100  ">{t("Add source")}</label>
                    <input data-testid="linktxt" type="text" placeholder={t("Type to add link")} value={linkDetails.url?linkDetails.url:''} onChange={(e)=>updateLinkDetails(e,'url')}></input>
                </div>
                <div className="col-6 col-sm-4" linkTitle={linkDetails.displayTitle}>
                <label className="w-100  ">{t("Add title (optional)")}</label>
                    <input data-testid="linktitle" type="text" placeholder={t("Type to add an optional title")}  value={linkDetails.displayTitle?linkDetails.displayTitle:''} onChange={(e)=>updateLinkDetails(e,'displayTitle')}></input>
                </div>
                <div className="col-12 col-sm-4 buttons ">
                    <button data-testid="addLinkBtn" className="sourceBtn" onClick={addSource} disabled={!linkDetails.url || linkDetails.url===''?true:false} >{t('Add Source')}</button>
            
                </div>
            </div>
            {formValues.efficacy[currentTab].links.map((link,linkIndx)=><div className="linkRow row formRow" >
                {updateLink.indx!==undefined && linkIndx === updateLink.indx? 
                <>
                <div className="col-sm-4 col-6 pb-2">
                    <label className="w-100  ">{t("Source")}</label>
                    <input data-testid="editLinkLink" type="text" value={updateLink.url} onChange={(e)=>handleChangeLinks(e.target.value, 'url' )} />
                </div>
                <div className="col-sm-4 col-6 pb-2">
                    <label className="w-100  ">{t("Title")}</label>
                    <input data-testid="editLinkTitle" type="text" value={updateLink.displayTitle} onChange={(e)=>handleChangeLinks(e.target.value, 'displayTitle' )} />
                </div>
                <div className="col-sm-4 col-6 pb-2 text-right">
                    <div className="linkActions">
                    <FontAwesomeIcon data-testid="updateEditLink" icon={faCheck} className="mr-1 cursor-pointer" onClick={()=> saveUpdateLink(linkIndx)}/>
                    <FontAwesomeIcon data-testid="cancelUpdateLink" icon={faTimes} className="cursor-pointer" onClick={()=>setUpdateLink({})} />
                    </div>
                </div>
                </>
                :
                <>
                <a href={link.url} target="blank">{link.displayTitle!==''&&link.displayTitle?link.displayTitle:link.url}</a>
                 <div className="editDiv">
                 <FontAwesomeIcon data-testid="editLink" icon={faPen} className="mr-2 cursor-pointer" onClick={()=>setUpdateLink({url:link.url, displayTitle:link.displayTitle, indx: linkIndx})}/>
                 <FontAwesomeIcon data-testid="removeLink" icon={faTrash} className="cursor-pointer" onClick={()=>deleteUpdateLink(linkIndx)} />
                 </div></>
                
                }
               
            </div>)}
            <div className="row formRow">
                <div className="col-sm-6 col-12"> 
                <label className="w-100  ">{t("Comments")}</label>
                 <textarea data-testid="efficacyComment" value={formValues.efficacy[currentTab].comments?formValues.efficacy[currentTab].comments:''} onChange={(e)=>handleChange(e.target.value,'comments')}></textarea>
                </div>
            </div>
        </div>
        </>}
       </div>
 </div>
 <div> 
    <div className="buttons bottomBtnWrapper">
        <div>
        { formValues.efficacy.length>0 && formValues.efficacy[currentTab].status === 'In Editing' && (formValues.efficacy[currentTab].id) &&
              <button className="greenBtn" data-testid="effSendToReview" onClick={(e)=>statusChange(e,'IN_REVIEW')}>{t("Send to Review")}</button>  }
            
            {formValues.efficacy.length>0 && formValues.efficacy[currentTab].status === 'In Review' &&
             <>
                <button data-testid="effApprove" className="greenBtn" onClick={(e)=>statusChange(e,'APPROVED')}>{t("Approve")}</button>
                <button data-testid="effReject" className="greenBtn" onClick={(e)=>statusChange(e,'IN_EDITING')}>{t("Reject")}</button>
             </>
             }
            <button   data-testid={'share-btn'} onClick={(e)=>manageShareModalContent(e)} disabled={!formValues.product.id}>
            <FontAwesomeIcon icon={faShareNodes}/>
            &nbsp; { t("Share")}...</button>
            
        </div>
             <div className="float-left"> 
               {pristineValues.efficacy.length!==0 && <button data-testid="saveEffBtn" className="greenBtn" disabled={pristineValues.efficacy[currentTab]} onClick={(e)=>saveCrop(e)} >{t("Save")}</button>
                }
             </div>
        </div>
        </div>
        <ModalReusable config={modalConfig}>
            
            </ModalReusable>
</>
}
export default EfficacyTab;