export const formConfig = {
    formElements:[
                    {
                        inputType: 'singleselect',
                        inputLabel: 'Application Type',
                        mapTo: 'waterApplicationType',
                        endpoint:"waterAppType", valueKey:"", labelKey:"name" 
                    }, 
                    {
                        inputType: 'text',
                        inputLabel: 'Min Rate',
                        mapTo: 'rateMin'
                    },
                    {
                        inputType: 'text',
                        inputLabel: 'Max Rate',
                        mapTo: 'rateMax'
                    },
                    {
                        inputType: 'singleselect',
                        inputLabel: 'Unit of Measurement',
                        mapTo: 'rateUom',
                        endpoint:"uom", valueKey:"", labelKey:"name",
                       // width:'100%'
                    }
                ],
    stateKey : 'waterApplications', //key name that saves these data in useState 
    addMoreText: 'Add Application Type',
    addMoreDefaults : { 
    }
}