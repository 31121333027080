/* istanbul ignore file */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next";
import './i18n';
import i18next from "i18next";
import './fonts/OpenSans-Bold.c0aa4130d9615e61666b.woff';
import './fonts/OpenSans-BoldItalic.43a27226ce08aac50314.woff';
import './fonts/OpenSans-ExtraBold.f83eb803365193fee4c4.woff';
import './fonts/OpenSans-ExtraBoldItalic.74b4b718f2b1f547ccb6.woff';
import './fonts/OpenSans-Italic.5c175da09766b582567b.woff';
import './fonts/OpenSans-Light.9b1ed45839c95bed559e.woff';
import './fonts/OpenSans-LightItalic.87055a89326386872bf8.woff';
import './fonts/OpenSans-Regular.c0015a47d8fe0333e4aa.woff';
import './fonts/OpenSans-SemiBold.f3c6de80d1289dfd5d4a.woff';
import './fonts/OpenSans-SemiBoldItalic.4bea77f5839b4642239c.woff';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
