export const formConfig = {
    formElements:[
                    {
                        inputType: 'singleselect',
                        inputLabel: 'Application Timing Type',
                        mapTo: 'timingType',
                        endpoint:"appTimingType", valueKey:"", labelKey:"name",
                       // width:'100%'
                    },
                    {
                        inputType: 'blankSpace',
                    },
                    {
                        inputType: 'multyselect',
                        inputLabel: 'Start',
                        mapTo: 'starts',
                        endpoint:"apptTiming", valueKey:"", labelKey:"name",
                        infoTxt: "Start of application timing threshold."
                       // width:'100%'
                    },
                    {
                        inputType: 'multyselect',
                        inputLabel: 'End',
                        mapTo: 'ends',
                        endpoint:"apptTiming", valueKey:"id", labelKey:"name",
                       // width:'100%'
                       infoTxt: "End of application timing threshold."
                    }
                ],
    stateKey : 'applicationTimings', //key name that saves these data in useState 
    addMoreText: 'Add Application Timing',
    addMoreDefaults : { 
    }
}