import "../../App.css";
import "./Selectinput.css";
import Select from "react-select";
import { components } from "react-select";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { serviceEndpoints } from "../../config/ServiceEndpoints";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faCaretDown,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
const Control = ({ children, ...props }) => (
  <components.Control {...props}>
    <div className="selectAnimationWrapper">
      <FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon>
      {children}
    </div>
  </components.Control>
);
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faCaretDown} className="rotateHover" />
    </components.DropdownIndicator>
  );
};
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <label>{props.label !== "" ? props.label : "----"}</label>
      </components.Option>
    </div>
  );
};
function SelectinputSingle(props) {
  const [selectedOption, setselectedOption] = useState(null);
  const [givenOptions, setGivenOptions] = useState([]);
  let itrateValue = props.itrateValues
    ? { indx: props.itrateValues.indx, mapTo: props.itrateValues.mapTo }
    : undefined;
  let mapTo = props.mapTo
    ? props.mapTo
    : itrateValue
    ? props.itrateValues.mapTo
    : undefined;
  useEffect(() => {
    let options = [{ value: undefined, label: "", item: undefined }];
    if (props.config.endpoint) {
      const url = props.config.isFullURL
        ? props.config.endpoint
        : process.env.REACT_APP_API_BASEURL +
          serviceEndpoints[props.config.endpoint];
      axios
        .get(url)
        .then((result) => {
          result.data.map((item) => {
            //console.log(item[props.config.valueKey])
            if (props.config.valueKey !== "")
              options.push({
                value: item[props.config.valueKey],
                label: item[props.config.labelKey]
                  ? item[props.config.labelKey] +
                    (props.config.labelKeySec && item[props.config.labelKeySec]
                      ? " / " + item[props.config.labelKeySec]
                      : "")
                  : "",
              });
            else
              options.push({
                value: item,
                label: item[props.config.labelKey]
                  ? item[props.config.labelKey] +
                    (props.config.labelKeySec && item[props.config.labelKeySec]
                      ? " / " + item[props.config.labelKeySec]
                      : "")
                  : "",
              });

            return item;
          });
          setGivenOptions(options);
          if (selectedOption) {
            let opt = options.find(
              (o) => o.value.id === selectedOption.value.id
            );
            setselectedOption(opt);
          }
        })
        .catch((e) => {});
    } else {
      setGivenOptions(props.config.options);
    }
    // debugger;
    if (props.value) {
      // if(props.config.valueKey===''){
      let opt = {
        value: props.value,
        label: props.value[props.config.labelKey]
          ? props.value[props.config.labelKey] +
            (props.config.labelKeySec && props.value[props.config.labelKeySec]
              ? " / " + props.value[props.config.labelKeySec]
              : "")
          : "",
      };
      setselectedOption(opt);
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (props.value) {
      let opt = {
        value: props.value,
        label: props.value[props.config.labelKey]
          ? props.value[props.config.labelKey] +
            (props.config.labelKeySec && props.value[props.config.labelKeySec]
              ? " / " + props.value[props.config.labelKeySec]
              : "")
          : "",
      };
      setselectedOption(opt);
    } else {
      setselectedOption(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);
  const handleChange = (selectedOption) => {
    setselectedOption(selectedOption);
    if (itrateValue) {
      props.handleChanges({
        target: {
          value: selectedOption.value,
          mapTo: itrateValue.mapTo,
          indx: itrateValue.indx,
          from: "select2",
        },
      });
    } else if (mapTo) {
      props.handleChanges(selectedOption.value, mapTo);
    }
  };

  return (
    <div
      className="singleSelectWrapper"
      data-testid={"singleselect-" + mapTo}
      style={{ width: props.config.width ? props.config.width : "100%" }}
    >
      <label className={props.config.labelTxt ? "" : "d-none"}>
        {props.config.labelTxt ? props.config.labelTxt : ""}
        {props.config.infoTxt && (
          <div className="position-relative infoDiv">
            <span>{props.config.infoTxt}</span>
            <FontAwesomeIcon icon={faCircleInfo}></FontAwesomeIcon>
          </div>
        )}
      </label>
      <Select
        value={selectedOption}
        selectedID={props.value}
        options={givenOptions}
        placeholder={""}
        onChange={handleChange}
        isMulti={false}
        isDisabled={props.disabled === true ? true : false}
        components={{
          Control,
          DropdownIndicator,
          Option,
        }}
        isOptionDisabled={(option) => {
          let disableFlag = false;
          //   if(props.disabledOptions && option.value && props.value.id && option.value.id !== props.value.id){
          if (
            props.disabledOptions &&
            (!option.value || option.value.id !== props.value.id)
          ) {
            props.disabledOptions.map((item) => {
              if (item.id && option.value && option.value.id === item.id) {
                console.log("here");
                disableFlag = true;
              }
              return disableFlag;
            });
          }
          return disableFlag;
        }}
        aria-label={
          "combobox" +
          (mapTo
            ? mapTo
            : props.config.labelTxt
            ? props.config.labelTxt.replace(/\s+/g, "")
            : undefined)
        }
      />
    </div>
  );
}
export default SelectinputSingle;
