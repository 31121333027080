import React, { Component } from "react"; 
import './Language.css'
  import {
    languages,
    defaultLanguage,
  } from "../../config/LanguageConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faGlobeAmericas } from "@fortawesome/free-solid-svg-icons";
import i18n from "i18next";
import  {setLang} from '../../services/AxiosInterceptor';
class Language extends Component {
    constructor(props) {
      super(props);
      this.state = {showLangFlag: false, lang: defaultLanguage,  languageList: languages } 
    }
    componentDidMount() {
      document.addEventListener("mousedown", this.handleClickOutside);
    }
    componentWillUnmount() {
      document.removeEventListener("mousedown", this.handleClickOutside);
    }
    setWrapperRef = node => {
      this.wrapperRef = node;
    };
    handleClickOutside = event => {
      if (this.wrapperRef && !this.wrapperRef.contains(event.target)) { 
        this.setState({ 
          showLangFlag: false
        });
      } 
     // console.log('lang menu outside click')
    };
    showLanguages = actionType => {
      if (actionType === "close")
        this.setState({
          showLangFlag: false
        });
      else
        this.setState({
          showLangFlag: true,
        });
    };
    /*
    getLanguage = () => {
      let lang =  i18n.language ||
      (typeof window !== "undefined" && window.localStorage.i18nextLng) ||
      this.state.lang;
     // return (
     //   lang.substr(0,2)
    //  );
      return lang;
    };*/
    setLanguageTxt = (lang) => {
      let currentLangTxt;
      let resultValue; 
     // if(lang!=='en-US'){
        currentLangTxt = this.state.languageList.find(item => item.id === lang);
        if(currentLangTxt){
          resultValue = currentLangTxt['name'];
        }
        else if(!currentLangTxt && lang.indexOf('-')!== -1){
          let newLang = lang.split('-');
          currentLangTxt = this.state.languageList.find(item => item.id === newLang[0]);
          resultValue = currentLangTxt['name'];
        }
        else{
          resultValue = 'English';
        } 
     /* } else {
        resultValue = 'English';
      } */
      return resultValue;
    }
    changeLanguage = lang => { 
      i18n.changeLanguage(lang);
      //this.props.setCurrentLang(lang);
      setLang(lang);
      this.setState({
        showLangFlag: false
      });
    };
    render() {
        return (
          <div>   
              <div className="form-inline my-2 my-lg-0 float-right mr-3">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <span className="nav-link cursor-pointer" data-testid="languageSetting"  onClick={this.showLanguages}>
                <FontAwesomeIcon icon={faGlobeAmericas}></FontAwesomeIcon> {this.setLanguageTxt(this.props.currentLang?this.props.currentLang:'en')}</span>
              </li>
            </ul>
          </div>
          { this.state.showLangFlag &&
          <div data-testid="lngMenu" className="navSubMenu" ref={this.setWrapperRef}><ul>
           { this.state.languageList.map((item) =>
                <li key={'lang'+item.id} data-testid={'lang'+item.id} className={ this.props.currentLang === item.id ? "active" : ""  }  onClick={() => { this.changeLanguage(item.id);}}> {item.name}</li> 
              )
            }
           </ul></div>
          }
       
          </div>
        )
    }
}
export default Language;