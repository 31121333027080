import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Home.css";
import {
  faAdd,
  faTrash,
  faGear,
  faSearch,
  faShareNodes,
  faCheck,
  faSort,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { useState, useMemo, useRef, useEffect } from "react";
import Selectinput from "../../components/Selectinput/Selectinput";
import ModalReusable from "../../components/ModalReusable/ModalReusable";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { serviceEndpoints } from "../../config/ServiceEndpoints";
import ShareModal from "../../components/ModalTemplates/ShareModal/ShareModal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const defaultValues = {
  indicationGroup: [],
  productfilter: "",
  countries: [],
};
const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const columns = [
    {
      name: (
        <div className="d-flex">
          <FontAwesomeIcon icon={faSort} className="m-0 customSort" />
          <input
            type="checkbox"
            className="customChkBoxInput"
            id={"row-select-all"}
            data-testid={"row-select-all"}
            onChange={(e) => {
              selectAll(e);
            }}
          />
          <label htmlFor={"row-select-all"} className="customChkBox">
            <FontAwesomeIcon icon={faCheck} />
          </label>{" "}
        </div>
      ),
      selector: (row) => row.checkBoxStatus,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <input
            type="checkbox"
            className="customChkBoxInput"
            id={"row-of-" + row.id}
            data-testid={"row-of-" + row.id}
            value={row.id}
            checked={selectedRows.indexOf(row.id) >= 0}
            onChange={(e) => managetableRow(e)}
          />
          <label htmlFor={"row-of-" + row.id} className="customChkBox">
            <FontAwesomeIcon icon={faCheck} />
          </label>{" "}
        </div>
      ),
      sortable: true,
      width: "83px",
    },
    {
      name: (
        <div>
          <FontAwesomeIcon icon={faSort} />
          {t("Product")}
        </div>
      ),
      selector: (row) => row.product,
      sortable: true,
    },
    {
      name: (
        <div>
          <FontAwesomeIcon icon={faSort} />
          {t("Group of Indication")}
        </div>
      ),
      selector: (row) => row.group,
      sortable: true,
    },
    {
      name: (
        <div>
          <FontAwesomeIcon icon={faSort} />
          {t("Country")}
        </div>
      ),
      selector: (row) => row.country,
      sortable: true,
    },
    {
      name: (
        <div>
          <FontAwesomeIcon icon={faSort} />
          {t("Status")}
        </div>
      ),
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: (
        <div>
          <FontAwesomeIcon icon={faSort} />
          {t("last edit/review by")} + {t("Date")}
        </div>
      ),
      selector: (row) => row.cDate,
      sortable: true,
      cell: (row) => (
        <div>
          <div>{row.lastEditedBy}</div>
          <div>{row.date}</div>
        </div>
      ),
    },
    {
      name: "Approval",
      selector: (row) => row.approval,
      cell: (row) => (
        <div
          className="approval"
          data-testid={"approval" + row.id}
          onMouseOver={(e) => showTooltip(e, row.id)}
          onMouseOut={() => setActivetooltip(0)}
        >
          <span
            className={
              row.approval[0] === "Approved"
                ? "green"
                : row.approval[0] === "In Review"
                ? "yellow"
                : ""
            }
          >
            Prod
          </span>
          <span
            className={
              row.approval[1] === "Approved"
                ? "green"
                : row.approval[1] === "In Review"
                ? "yellow"
                : ""
            }
          >
            Reg
          </span>
          <span
            className={
              row.approval[2] === "Approved"
                ? "green"
                : row.approval[2] === "In Review"
                ? "yellow"
                : ""
            }
          >
            Eff
          </span>
          <span
            className={
              row.approval[3] === "Approved"
                ? "green"
                : row.approval[3] === "In Review"
                ? "yellow"
                : ""
            }
          >
            Rec
          </span>
          <div
            className={
              (activeTooltip === row.id ? "" : "d-none") +
              " statusTooltip " +
              tooltipPosition
            }
          >
            <div className={"approve" + row.id}>
              <h5>{t("Approval Status")}</h5>
              <div className="statusWrapper">
                <div className=" pb-3">
                  <h6>{t("Product")}</h6>
                  <span>{row.approval[0]}</span>
                </div>
                <div className="pt-3 pb-3">
                  <h6>{t("Registration")}</h6>
                  <span>{row.approval[1]}</span>
                </div>
                <div className="pt-3 pb-3">
                  <h6>{t("Efficacy")}</h6>
                  <span>{row.approval[2]}</span>
                </div>
                <div className="pt-3 pb-3">
                  <h6>{t("Recommendation")}</h6>
                  <span>{row.approval[3]}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
      width: "300px",
    },
    {
      name: t("Actions"),
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <span title="View">
            <FontAwesomeIcon
              icon={faPen}
              onClick={() => navigate("/edit-product/" + row.id)}
              className="cursor-pointer animHover"
            />
          </span>
        </div>
      ),
    },
  ];

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSettings(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  let dataDefault = [];
  const [data, setData] = useState([]);
  const [formValues, setFormValues] = useState(defaultValues);
  const [settings, setSettings] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [activeTooltip, setActivetooltip] = useState(undefined);
  const [tooltipPosition, setTooltipPosition] = useState("posBottom");
  const [modalConfig, setModalConfig] = useState({ show: false });
  const [pending, setPending] = useState(true);
  const wrapperRef = useRef(null);
  const dataTableRef = useRef();
  useOutsideAlerter(wrapperRef);
  const handleChange = (val, mapTo) => {
    const updateNow = { ...formValues };
    updateNow[mapTo] = val;
    setFormValues({ ...updateNow });
  };
  const showSettings = () => {
    setSettings(true);
  };
  useMemo(() => {
    if (data.length === 0) {
      axios
        .get(
          process.env.REACT_APP_API_BASEURL +
            serviceEndpoints["products"] +
            "?name=&indicationGroup=&region="
        )
        .then((result) => {
          result.data.map((item) => {
            let indGroup = [];
            item.indicationGroups.forEach((ind) => {
              indGroup.push(ind.name);
            });
            let elm = {
              id: item.id,
              checkBoxStatus: true,
              product: item.name,
              group: indGroup.join(", "),
              country: item.region ? item.region.name : "",
              status: item.status,
              cDate: new Date(item.lastEditedAt),
              date: item.lastEditedAt,
              lastEditedBy: item.lastEditedBy,
              approval: [
                item.productHeaderStatus,
                item.registrationsStatus,
                item.efficaciesStatus,
                item.recommendationsStatus,
              ],
            };
            dataDefault.push(elm);
            setData(dataDefault);
            setPending(false);
            return item;
          });
          if (dataDefault.length > 0) console.log(dataDefault[0].product);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const managetableRow = (e) => {
    let selected = [...selectedRows];
    let currentData = [...data];
    let elm = currentData.find((o) => o.id === e.target.value);
    let indxE = currentData.indexOf(elm);
    if (e.target.checked) {
      selected.push(e.target.value);
      elm.checkBoxStatus = false;
    } else {
      let indx = selected.indexOf(e.target.value);
      selected.splice(indx, 1);
      elm.checkBoxStatus = true;
    }
    currentData[indxE] = elm;
    setData([...currentData]);
    console.log(selected.length);
    setSelectedRows(selected);
  };
  const selectAll = (e) => {
    let selected = [];
    if (e.target.checked) {
      let currentData = filterData(data);
      currentData.forEach((item) => {
        selected.push(item.id);
      });
    }
    setSelectedRows(selected);
  };
  const filterData = (data) => {
    return data.filter((item) => {
      let filteredItem;
      if (formValues.productfilter !== "") {
        if (
          item.product
            .toLowerCase()
            .search(formValues.productfilter.toLowerCase()) !== -1
        ) {
          if (formValues.indicationGroup.length === 0) {
            if (formValues.countries.length !== 0) {
              formValues.countries.forEach((cnt) => {
                if (item.country.toLowerCase() === cnt.label.toLowerCase())
                  filteredItem = item;
              });
            } else filteredItem = item;
          } else {
            formValues.indicationGroup.forEach((ind) => {
              let elmts = item.group.split(", ");
              if (elmts.indexOf(ind.label) !== -1) {
                if (formValues.countries.length !== 0) {
                  formValues.countries.forEach((cnt) => {
                    if (item.country.toLowerCase() === cnt.label.toLowerCase())
                      filteredItem = item;
                  });
                } else filteredItem = item;
              }
            });
          }
        }
      } else {
        if (formValues.indicationGroup.length === 0) {
          if (formValues.countries.length !== 0) {
            formValues.countries.forEach((cnt) => {
              if (item.country.toLowerCase() === cnt.label.toLowerCase())
                filteredItem = item;
            });
          } else filteredItem = item;
        } else {
          formValues.indicationGroup.forEach((ind) => {
            let elmts = item.group.split(", ");
            //item.group.toLowerCase() === ind.label.toLowerCase()
            if (elmts.indexOf(ind.label) !== -1) {
              if (formValues.countries.length !== 0) {
                formValues.countries.forEach((cnt) => {
                  if (item.country.toLowerCase() === cnt.label.toLowerCase())
                    filteredItem = item;
                });
              } else filteredItem = item;
            }
          });
        }
      }

      return filteredItem;
    });
  };
  const showTooltip = (e, id) => {
    // setTooltipPosition('posBottom');
    setTooltipPosition("posTop");
    setActivetooltip(id);
    /*setTimeout(()=>{
 let tooltipTop = e.nativeEvent.pageY;
 let currentTooltipHeight = document.getElementsByClassName('approve'+id)[0].clientHeight;
 let tableHeight = dataTableRef.current? (dataTableRef.current.offsetTop+dataTableRef.current.clientHeight):0;
 if((tooltipTop+currentTooltipHeight+80) >= tableHeight){
  setTooltipPosition('posTop');
 }    
},10)*/
  };
  const manageShareModalContent = (e) => {
    e.preventDefault();
    manageModal(
      shareModalConfig,
      true,
      <ShareModal selectedRows={selectedRows}></ShareModal>
    );
  };
  const manageModalContent = () => {
    selectedRows.length > 0
      ? manageModal(
          deleteModalConfig,
          true,
          <>{t("Do you want to delete the selected applications?")}</>
        )
      : manageModal(
          notSelectedModalConfig,
          true,
          <>{t("Please select any application to delete it.")}</>
        );
  };
  const manageModal = (config, status, inner) => {
    const modalCon = { ...config, ...{ show: status, modalInner: inner } };
    setModalConfig(modalCon);
  };
  const deleteRows = () => {
    /*let dataCopy = data;
  selectedRows.forEach((row)=>{
    dataCopy = dataCopy.filter(item=>item.id.toString()!==row); 
  });
  setData(dataCopy);*/
    //commented the above because api is not ready. This will delete only front end side data
    setModalConfig({ ...modalConfig, ...{ show: false } });
  };
  const deleteModalConfig = {
    show: false,
    modalHeader: t("Delete Applications"),
    closeButtonHandler: manageModal,
    modalButtons: [
      { label: t("Cancel"), handler: manageModal },
      { label: t("Delete"), classNames: "greenBtn", handler: deleteRows },
    ],
  };
  const shareModalConfig = {
    show: false,
    largeWindow: true,
    modalHeader: "Share Items",
    closeButtonHandler: manageModal,
    modalButtons: [],
  };
  const notSelectedModalConfig = {
    show: false,
    modalHeader: t("Delete Applications"),
    closeButtonHandler: manageModal,
    modalButtons: [
      { label: t("Cancel"), handler: manageModal },
      { label: "OK", classNames: "greenBtn", handler: manageModal },
    ],
  };

  return (
    <>
      <h1 className="pageHeader pt-3 pb-2">{t("Product Configuration")}</h1>
      <div className="container m-0 p-0 w-100">
        <div className="coL-12 buttons">
          <span
            className="d-none"
            onClick={() => {
              navigate("/token");
            }}
          >
            click
          </span>
          <button
            className="greenBtn"
            onClick={() => {
              navigate("/new-product");
            }}
            data-testid="addNewProduct"
          >
            <FontAwesomeIcon icon={faAdd} />
            &nbsp; {t("Add new Product")}{" "}
          </button>
          <button
            disabled={selectedRows.length <= 0 ? true : false}
            data-testid={"share-btn"}
            onClick={(e) => manageShareModalContent(e)}
          >
            <FontAwesomeIcon icon={faShareNodes} />
            &nbsp; {t("Share")}...
          </button>
        </div>

        <div className="col-12 row mr-0 mb-3">
          <div className="inputBoxWrapper col-12 col-md-3">
            <label>{t("Search for Product")}</label>
            <div>
              <input
                type="text"
                placeholder="Search"
                data-testid="searchProduct"
                value={formValues.productfilter}
                onChange={(e) => handleChange(e.target.value, "productfilter")}
              />
              <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
            </div>
          </div>

          <div className="inputBoxWrapper col-12 col-md-3">
            <div>
              <Selectinput
                config={{
                  endpoint: "indicationGroup",
                  valueKey: "id",
                  labelKey: "name",
                  labelTxt: t("Group of Indication"),
                }}
                handleChanges={handleChange}
                mapTo="indicationGroup"
              ></Selectinput>
            </div>
          </div>

          <div className="inputBoxWrapper col-12 col-md-3">
            <div>
              <Selectinput
                config={{
                  endpoint: "countries",
                  valueKey: "id",
                  labelKey: "name",
                  width: "200px",
                  labelTxt: t("Country"),
                }}
                handleChanges={handleChange}
                mapTo="countries"
              ></Selectinput>
            </div>
          </div>
          <div className="col p-0 position-relative">
            <p className="text-right settings">
              <FontAwesomeIcon
                icon={faGear}
                onClick={showSettings}
                data-testid="settingsMenu"
              ></FontAwesomeIcon>
            </p>
            {settings && (
              <div className="toolTip" ref={wrapperRef}>
                <ul>
                  <li
                    className="cursor-pointer"
                    data-testid="deleteSetting"
                    onClick={() => manageModalContent()}
                  >
                    <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>{" "}
                    {t("Delete")}
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div
          data-testid="datatable"
          ref={dataTableRef}
          className="table-container"
        >
          <DataTable
            columns={columns}
            pagination
            data={filterData(data)}
            progressPending={pending}
            className="table-parent"
          />
        </div>
      </div>
      <ModalReusable config={modalConfig}></ModalReusable>
    </>
  );
};
export default Home;
