import React from 'react';
import { faCaretDown, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ShareModal.css"
import { useState } from "react";
import { serviceEndpoints } from '../../../config/ServiceEndpoints'; 
import axios from 'axios';
const defaultFormValues = {
    propertiesToExport :"",
    registrationProperties:[],
    efficacyProperties:"",
    recommendationProperties:[]
};
function ShareModal(props) {
    const [accStatus,setAccStatus] = useState({ product: false, 
                                                registration: false,
                                                efficacies: false,
                                                recommendation:false});
    const [formValues, setFormValues] = useState(defaultFormValues)
    const manageAccHead = (head)=>{
        let status = {...accStatus};
        status[head] = !status[head];
        setAccStatus(status);
    }
    const checkBoxMapping = {
        product:["productCommercialName",
		"productFunctionalId",
		"productCompany",
		"productCountry",
		"productRegistrationNumber",
		"productFormulationType",
		"productGroupOfIndication",
		"productActiveIngredient",
		"productComments"],
        efficacies:["efficacyTitle",
		"efficacyFunctionalId",
		"efficacyCrops",
		"efficacySource",
		"efficacyTreatmentType",
		"efficacyMinDose",
		"efficacyMaxDose",
		"efficacyUom"],
        generalInfo:[
        "registrationFunctionalId",
		"registrationCrops",
		"registrationTraitsRequired",
		"registrationCropUsageType",
		"registrationApplicationPeriod",
		"registrationApplicationType",
		"registrationTaskType",
		"registrationTaskMethod",
		"registrationAdditives"
        ],
        appTiming:[
            "registrationApplicationTiming"
        ],
        appRate:[
            "registrationApplicationRateMin",
            "registrationApplicationRateMax",
            "registrationApplicationRateUom"
        ],
        appVolume:[
            "registrationWaterApplication"
        ],
        restrictions:[
            "registrationPhi",
            "registrationPpi",
            "registrationPgi",
            "registrationRei",
            "registrationDaysBetweenApplicationMin",
            "registrationDaysBetweenApplicationMax",
            "registrationMaxTreatments",
            "registrationMaxProductAmount",
            "registrationMaxProductAmountUom",
            "registrationPeriod"
        ],
        additionalInfo:[
            "registrationExpiryDate", 
            "registrationNotForUseIn", 
            "registrationComments"
        ],
        recGeneralInfo:[
            "recommendationFunctionalId", 
            "recommendationCrops", 
            "recommendationRegistrations"
        ],
        regionalSpec:[
            "recommendationRegionalSpecification"
        ],
        appInfo:[
            "recommendationCropUsageType",
            "recommendationTraitsRequired",
            "recommendationApplicationPeriod",
            "recommendationApplicationType",
            "recommendationTaskType",
            "recommendationTaskMethod",
            "recommendationApplicationTiming"
        ],
        recAppVolume1:[
            "recommendationRateMin", 
            "recommendationRateMax", 
            "recommendationRateUom", 
            "recommendationAdjuvant"
        ],
        recAppVolume2:[
            "recommendationWaterApplication"
        ],
        recRestrictions:[
            "recommendationPpi", 
            "recommendationPhi", 
            "recommendationCutOff"
        ],
        recAdditionalInfo:[
            "recommendationSoilType",
            "recommendationOrganicMatterContent",
            "recommendationTillageType",
            "recommendationPlantingTime"
        ],
        sources:[
            "recommendationComments"
        ],
        partOfSprayPgm:[]
    }
    const exportToExcel = ()=>{
        let payload = {
            propertiesToExport:[],
            registrationProperties:[],
            efficacyProperties:[],
            recommendationProperties:[]
        };
        if(formValues.propertiesToExport!==""){
            payload.propertiesToExport = checkBoxMapping.product
        } 
        if(formValues.efficacyProperties!==""){
            payload.efficacyProperties = checkBoxMapping.efficacies
        }
        if(formValues.registrationProperties.indexOf("generalInfo")!== -1){
            payload.registrationProperties.push(...checkBoxMapping.generalInfo)
        }
        if(formValues.registrationProperties.indexOf("appTiming")!== -1){
            payload.registrationProperties.push(...checkBoxMapping.appTiming)
        }
        if(formValues.registrationProperties.indexOf("appRate")!== -1){
            payload.registrationProperties.push(...checkBoxMapping.appRate)
        }
        if(formValues.registrationProperties.indexOf("appVolume")!== -1){
            payload.registrationProperties.push(...checkBoxMapping.appVolume)
        }
        if(formValues.registrationProperties.indexOf("restrictions")!== -1){
            payload.registrationProperties.push(...checkBoxMapping.restrictions)
        } 
        if(formValues.registrationProperties.indexOf("additionalInfo")!== -1){
            payload.registrationProperties.push(...checkBoxMapping.additionalInfo)
        }
        if(formValues.recommendationProperties.indexOf("recGeneralInfo")!== -1){
            payload.recommendationProperties.push(...checkBoxMapping.recGeneralInfo)
        }
        if(formValues.recommendationProperties.indexOf("regionalSpec")!== -1){
            payload.recommendationProperties.push(...checkBoxMapping.regionalSpec)
        } 
        if(formValues.recommendationProperties.indexOf("appInfo")!== -1){
            payload.recommendationProperties.push(...checkBoxMapping.appInfo)
        }
        if(formValues.recommendationProperties.indexOf("recAppVolume1")!== -1){
            payload.recommendationProperties.push(...checkBoxMapping.recAppVolume1)
        }
        if(formValues.recommendationProperties.indexOf("recAppVolume2")!== -1){
            payload.recommendationProperties.push(...checkBoxMapping.recAppVolume2)
        } 
        if(formValues.recommendationProperties.indexOf("recRestrictions")!== -1){
            payload.recommendationProperties.push(...checkBoxMapping.recRestrictions)
        } 
        if(formValues.recommendationProperties.indexOf("recAdditionalInfo")!== -1){
            payload.recommendationProperties.push(...checkBoxMapping.recAdditionalInfo)
        } 
        if(formValues.recommendationProperties.indexOf("sources")!== -1){
            payload.recommendationProperties.push(...checkBoxMapping.sources)
        }
        if(formValues.recommendationProperties.indexOf("partOfSprayPgm")!==-1){
            payload.recommendationProperties.push(...checkBoxMapping.partOfSprayPgm);
        }
        console.log(payload);
        const link = document.createElement("a");
        link.target = "_blank";
        let fileDate = new Date();
        link.download = fileDate.getDate()+'_'+fileDate.getMonth()+'_'+fileDate.getFullYear()+'_'+
        fileDate.getHours()+fileDate.getMinutes()+fileDate.getSeconds()+fileDate.getMilliseconds()+".xlsx";
    payload.ids = props.selectedRows; 
    axios.post(process.env.REACT_APP_API_BASEURL+serviceEndpoints['excel'],payload,  { responseType: 'blob' }).then((res)=>{
        link.href = URL.createObjectURL(
        new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
        );
        link.click();
    })
    }
    const [checkedFlag, setCheckedFlag] = useState(false);
    React.useEffect(() => {
        if(formValues.propertiesToExport!==""||formValues.efficacyProperties!==""||
        formValues.recommendationProperties.length!==0 ||
        formValues.registrationProperties.length!==0)
        setCheckedFlag(true);
        else
            setCheckedFlag(false);
        }, [formValues]);
    const handleChange = (e,type)=>{
        let currentFormValues = {...formValues};
        
        if(type === 'product'){
            if(e.target.checked){
                currentFormValues.propertiesToExport = "product"
            } else {
            currentFormValues.propertiesToExport = ""; 
            }
        } else if(type === 'registration'){
            if(e.target.checked){
                currentFormValues.registrationProperties.push("generalInfo", "appTiming", "appRate", "appVolume", "restrictions","additionalInfo");
                currentFormValues.registrationProperties = [...new Set(currentFormValues.registrationProperties)];   
            }else
                currentFormValues.registrationProperties=[];
        } else if(type === "generalInfo" || type === "appTiming" || type === "appRate" || type === "appVolume" || type === "restrictions" || type === "additionalInfo"){
                if(e.target.checked)
                currentFormValues.registrationProperties.push(type);
                else{ 
                    let  indexToRemove =  currentFormValues.registrationProperties.indexOf(type);
                    currentFormValues.registrationProperties = [
                        ...currentFormValues.registrationProperties.slice(0,indexToRemove),
                        ...currentFormValues.registrationProperties.slice(indexToRemove+1)
                      ]
                }
        } else if(type === 'efficacies'){
            if(e.target.checked){
                currentFormValues.efficacyProperties = "efficacies"
            } else
            currentFormValues.efficacyProperties = "";
        } else if(type === "recommendation"){
            if(e.target.checked){
                currentFormValues.recommendationProperties.push("recGeneralInfo", "regionalSpec", "appInfo", "recAppVolume1", "recAppVolume2", "recRestrictions","recAdditionalInfo", "sources","partOfSprayPgm");
                currentFormValues.recommendationProperties = [...new Set(currentFormValues.recommendationProperties)];   
            }else
                currentFormValues.recommendationProperties=[];
        }  else if(type === "recGeneralInfo" || type === "regionalSpec" || type === "appInfo" || type === "recAppVolume1" || type === "recAppVolume2" || type === "recRestrictions" || type === "recAdditionalInfo" || type === "sources" || type === "partOfSprayPgm"){
            if(e.target.checked)
            currentFormValues.recommendationProperties.push(type);
            else{ 
                let  indexToRemove =  currentFormValues.recommendationProperties.indexOf(type);
                currentFormValues.recommendationProperties = [
                    ...currentFormValues.recommendationProperties.slice(0,indexToRemove),
                    ...currentFormValues.recommendationProperties.slice(indexToRemove+1)
                  ]
            }
    }
        setFormValues({...currentFormValues}); 
    }
    return <>
    <div>
   <div className="formContent"> 
    <div className="accHead" data-testid="productHeader" onClick={()=>manageAccHead('product')}> 
        <input type="checkbox" id="shareProduct" className="customChkBoxInput" data-testid="shareProductChkBox"
        checked={formValues.propertiesToExport!==""?true:false} onChange={(e)=>handleChange(e,'product')} />
        <label  htmlFor={"shareProduct"} className='customChkBox' data-testid="shareProduct">
            <FontAwesomeIcon icon={faCheck} />
        </label>
            {" "}
        <label >Product</label>
        <span className={"accArrow "+(accStatus.product === true?'rotateUp':'')}><FontAwesomeIcon  icon={faCaretDown} /></span>
    </div>
       { <div data-testid="productBody" className={"accBody "+(accStatus.product === true?'showElm':'')}>
        <div className="d-flex">
        <div>
        <input type="checkbox" data-testid="shareProductSub" id="shareProductSub" className="customChkBoxInput" value={formValues.propertiesToExport} 
        checked={formValues.propertiesToExport!==""?true:false} onChange={(e)=>handleChange(e,'product')}  />
        <label  htmlFor={"shareProductSub"} className='customChkBox' >
            <FontAwesomeIcon icon={faCheck} />
        </label>
            {" "}
        </div>
        <div>
        <label className="labelTxt"> Commercial Name; Functional ID; Competitor Product; Company Name; Country; Registration Number; Formulation Type; Group of Indication; Active Ingredients (AI); Comments </label>
        </div>
        </div>
        </div>
        }

<div className="accHead" data-testid="registrationHeader" onClick={()=>manageAccHead('registration')}> 
        <input type="checkbox" id="shareReg" className="customChkBoxInput" data-testid="shareRegChkBox"
        checked={formValues.registrationProperties.length === 6?true:false} onChange={(e)=>handleChange(e,'registration')}  />
        <label  htmlFor={"shareReg"} className='customChkBox' data-testid="shareReg" >
            <FontAwesomeIcon icon={faCheck} />
        </label>
            {" "}
        <label>Registration</label>
        <span className={"accArrow "+(accStatus.registration === true?'rotateUp':'')}><FontAwesomeIcon  icon={faCaretDown} /></span>
    </div>
       { <div  data-testid="registrationBody" className={"accBody "+(accStatus.registration === true?'showElm':'')}>
            <div className="d-flex ">
            <div>
            <input data-testid="shareRegSub" type="checkbox" id="shareRegSub" className="customChkBoxInput"
            checked={formValues.registrationProperties.indexOf("generalInfo")>=0?true:false} onChange={(e)=>handleChange(e,'generalInfo')} />
            <label  htmlFor={"shareRegSub"} className='customChkBox' >
                <FontAwesomeIcon icon={faCheck} />
            </label>
                {" "}
            </div>
            <div>
            <h6 className="mb-0">General Information</h6>
            <label className="labelTxt">  Functional ID; Crop(s); Required Traits; Crop Usage Type; Application Period; Application Type; Task Type; Task Method; Additives  </label>
            </div>
            </div>

            <div className="d-flex ">
            <div>
            <input type="checkbox" id="shareRegAppTime" className="customChkBoxInput" data-testid="shareRegAppTime"
            checked={formValues.registrationProperties.indexOf("appTiming")>=0?true:false} onChange={(e)=>handleChange(e,'appTiming')} />
            <label  htmlFor={"shareRegAppTime"} className='customChkBox' >
                <FontAwesomeIcon icon={faCheck} />
            </label>
                {" "}
            </div>
            <div>
            <h6 className="mb-0">Application Timing</h6>
            <label className="labelTxt"> Application Timing </label>
            </div>
            </div>

            <div className="d-flex ">
            <div>
            <input type="checkbox" id="shareRegAppRate" className="customChkBoxInput" data-testid="shareRegAppRate"
            checked={formValues.registrationProperties.indexOf("appRate")>=0?true:false} onChange={(e)=>handleChange(e,'appRate')} />
            <label  htmlFor={"shareRegAppRate"} className='customChkBox' >
                <FontAwesomeIcon icon={faCheck} />
            </label>
                {" "}
            </div>
            <div>
            <h6 className="mb-0">Product Application Rate</h6>
            <label className="labelTxt"> Min Rate; Max Rate; Unit of Measurement </label>
            </div>
            </div>

            <div className="d-flex ">
            <div>
            <input type="checkbox" id="shareRegAppVol" className="customChkBoxInput" data-testid="shareRegAppVol"
            checked={formValues.registrationProperties.indexOf("appVolume")>=0?true:false} onChange={(e)=>handleChange(e,'appVolume')} />
            <label  htmlFor={"shareRegAppVol"} className='customChkBox' >
                <FontAwesomeIcon icon={faCheck} />
            </label>
                {" "}
            </div>
            <div>
            <h6 className="mb-0">Application Volume</h6>
            <label className="labelTxt">Water Application &nbsp;&nbsp; </label>
            </div>
            </div>

            <div className="d-flex ">
            <div>
            <input type="checkbox" id="shareRegRestriction" className="customChkBoxInput" data-testid="shareRegRestriction"
            checked={formValues.registrationProperties.indexOf("restrictions")>=0?true:false} onChange={(e)=>handleChange(e,'restrictions')} />
            <label  htmlFor={"shareRegRestriction"} className='customChkBox' >
                <FontAwesomeIcon icon={faCheck} />
            </label>
                {" "}
            </div>
            <div>
            <h6 className="mb-0">Restrictions</h6>
            <label className="labelTxt"> Pre-Harvest Interval (days); Pre-Planting Interval (days); Pre-Grazing Interval (days); Re-Entry Interval (hours); Min; Max; Max Number of Treatments; Max Product Amount; Unit of Measurement; Period  </label>
            </div>
            </div>

            <div className="d-flex ">
            <div>
            <input type="checkbox" id="shareRegadInfo" className="customChkBoxInput" data-testid="shareRegadInfo"
            checked={formValues.registrationProperties.indexOf("additionalInfo")>=0?true:false} onChange={(e)=>handleChange(e,'additionalInfo')} />
            <label  htmlFor={"shareRegadInfo"} className='customChkBox' >
                <FontAwesomeIcon icon={faCheck} />
            </label>
                {" "}
            </div>
            <div>
            <h6 className="mb-0">Additional Information</h6>
            <label className="labelTxt">Registration Expiry Date; Not For Use In; Comments </label>
            </div>
            </div>
        </div>
        }
<div className="accHead" data-testid="efficaciesHeader" onClick={()=>manageAccHead('efficacies')}> 
        <input type="checkbox" id="shareEfficacies" className="customChkBoxInput" data-testid="shareEfficaciesChkBox"
        checked={formValues.efficacyProperties!==""?true:false} onChange={(e)=>handleChange(e,'efficacies')} />
        <label  htmlFor={"shareEfficacies"} className='customChkBox' data-testid="shareEfficacies" >
            <FontAwesomeIcon icon={faCheck} />
        </label>
            {" "}
        <label>Efficacies</label>
        <span className={"accArrow "+(accStatus.efficacies === true?'rotateUp':'')}><FontAwesomeIcon  icon={faCaretDown} /></span>
    </div>
    { <div data-testid="efficaciesBody" className={"accBody "+(accStatus.efficacies === true?'showElm':'')}>
        <div className="d-flex">
        <div>
        <input  data-testid="shareEfficaciesSub" type="checkbox" id="shareEfficaciesSub" className="customChkBoxInput" value={formValues.efficacyProperties} 
        checked={formValues.efficacyProperties!==""?true:false} onChange={(e)=>handleChange(e,'efficacies')} />
        <label  htmlFor={"shareEfficaciesSub"} className='customChkBox' >
            <FontAwesomeIcon icon={faCheck} />
        </label>
            {" "}
        </div>
        <div>
        <label className="labelTxt">  Functional ID; Crop(s); Sources; Treatment Type; Min Rate; Max Rate; Unit of Measurement; Target Selection; Sources; Comments </label>
        </div>
        </div>
        </div>
        }
<div className="accHead" data-testid="recHeader" onClick={()=>manageAccHead('recommendation')}> 
        <input type="checkbox" id="shareRec" className="customChkBoxInput" data-testid="shareRecChkBox"
        checked={formValues.recommendationProperties.length === 9?true:false} onChange={(e)=>handleChange(e,'recommendation')}  />
        <label  htmlFor={"shareRec"} className='customChkBox' data-testid="shareRec" >
            <FontAwesomeIcon icon={faCheck} />
        </label>
            {" "}
        <label>Recommendation</label>
        <span className={"accArrow "+(accStatus.recommendation === true?'rotateUp':'')}><FontAwesomeIcon  icon={faCaretDown} /></span>
    </div>
    { <div data-testid="recBody" className={"accBody "+(accStatus.recommendation === true?'showElm':'')}>
            <div className="d-flex ">
            <div>
            <input data-testid="shareRecSub" type="checkbox" id="shareRecSub" className="customChkBoxInput"
            checked={formValues.recommendationProperties.indexOf("recGeneralInfo")>=0?true:false} onChange={(e)=>handleChange(e,'recGeneralInfo')} />
            <label  htmlFor={"shareRecSub"} className='customChkBox' >
                <FontAwesomeIcon icon={faCheck} />
            </label>
                {" "}
            </div>
            <div>
            <h6 className="mb-0">General Information</h6>
            <label className="labelTxt">  Functional ID; Crop(s); Efficacy ID; Open Efficacy Details; Registration  </label>
            </div>
            </div>

            <div className="d-flex ">
            <div>
            <input type="checkbox" id="regionalSpec" className="customChkBoxInput" data-testid="regionalSpec"
            checked={formValues.recommendationProperties.indexOf("regionalSpec")>=0?true:false} onChange={(e)=>handleChange(e,'regionalSpec')} />
            <label  htmlFor={"regionalSpec"} className='customChkBox' >
                <FontAwesomeIcon icon={faCheck} />
            </label>
                {" "}
            </div>
            <div>
            <h6 className="mb-0">Regional Specifications</h6>
            </div>
            </div>

            <div className="d-flex ">
            <div>
            <input type="checkbox" id="appInfo" className="customChkBoxInput" data-testid="appInfo"
            checked={formValues.recommendationProperties.indexOf("appInfo")>=0?true:false} onChange={(e)=>handleChange(e,'appInfo')} />
            <label  htmlFor={"appInfo"} className='customChkBox' >
                <FontAwesomeIcon icon={faCheck} />
            </label>
                {" "}
            </div>
            <div>
            <h6 className="mb-0">Application Information</h6>
            <label className="labelTxt">   Crop Usage Type; Required Traits; Application Period; Application Type; Task Type; Task Method; Application Timing  </label>
            </div>
            </div>

            <div className="d-flex ">
            <div>
            <input type="checkbox" id="recAppVolume1" className="customChkBoxInput" data-testid="recAppVolume1"
            checked={formValues.recommendationProperties.indexOf("recAppVolume1")>=0?true:false} onChange={(e)=>handleChange(e,'recAppVolume1')} />
            <label  htmlFor={"recAppVolume1"} className='customChkBox' >
                <FontAwesomeIcon icon={faCheck} />
            </label>
                {" "}
            </div>
            <div>
            <h6 className="mb-0">Application Volume</h6>
            <label className="labelTxt">Product; Most commonly used rate; Min Rate; Max Rate; Unit of Measurement </label>
            </div>
            </div>

            <div className="d-flex ">
            <div>
            <input type="checkbox" id="recAppVolume2" className="customChkBoxInput" data-testid="recAppVolume2"
            checked={formValues.recommendationProperties.indexOf("recAppVolume2")>=0?true:false} onChange={(e)=>handleChange(e,'recAppVolume2')} />
            <label  htmlFor={"recAppVolume2"} className='customChkBox' >
                <FontAwesomeIcon icon={faCheck} />
            </label>
                {" "}
            </div>
            <div>
            <h6 className="mb-0">Application Volume</h6>
            <label className="labelTxt">Water Application &nbsp;&nbsp;</label>
            </div>
            </div>

            <div className="d-flex ">
            <div>
            <input type="checkbox" id="recRestrictions" className="customChkBoxInput" data-testid="recRestrictions"
            checked={formValues.recommendationProperties.indexOf("recRestrictions")>=0?true:false} onChange={(e)=>handleChange(e,'recRestrictions')} />
            <label  htmlFor={"recRestrictions"} className='customChkBox' >
                <FontAwesomeIcon icon={faCheck} />
            </label>
                {" "}
            </div>
            <div>
            <h6 className="mb-0">Restrictions</h6>
            <label className="labelTxt">Pre-Plant Interval (days); Pre-Harvest Interval (days); Cut-Off Period (day/month)</label>
            </div>
            </div>

            <div className="d-flex ">
            <div>
            <input type="checkbox" id="recAdditionalInfo" className="customChkBoxInput" data-testid="recAdditionalInfo"
            checked={formValues.recommendationProperties.indexOf("recAdditionalInfo")>=0?true:false} onChange={(e)=>handleChange(e,'recAdditionalInfo')} />
            <label  htmlFor={"recAdditionalInfo"} className='customChkBox' >
                <FontAwesomeIcon icon={faCheck} />
            </label>
                {" "}
            </div>
            <div>
            <h6 className="mb-0">Additional Information</h6>
            <label className="labelTxt">Soil Type; Organic Matter Content; Tillage Type; Planting Time; Efficacies</label>
            </div>
            </div>

            <div className="d-flex ">
            <div>
            <input type="checkbox" id="sources" className="customChkBoxInput" data-testid="sources"
            checked={formValues.recommendationProperties.indexOf("sources")>=0?true:false} onChange={(e)=>handleChange(e,'sources')} />
            <label  htmlFor={"sources"} className='customChkBox' >
                <FontAwesomeIcon icon={faCheck} />
            </label>
                {" "}
            </div>
            <div>
            <h6 className="mb-0">Sources</h6>
            <label className="labelTxt"> Sources; Comments</label>
            </div>
            </div>

            <div className="d-flex ">
            <div>
            <input type="checkbox" id="partOfSprayPgm" className="customChkBoxInput" data-testid="partOfSprayPgm"
            checked={formValues.recommendationProperties.indexOf("partOfSprayPgm")>=0?true:false} onChange={(e)=>handleChange(e,'partOfSprayPgm')} />
            <label  htmlFor={"partOfSprayPgm"} className='customChkBox' >
                <FontAwesomeIcon icon={faCheck} />
            </label>
                {" "}
            </div>
            <div>
            <h6 className="mb-0">Recommendation is part of spraying program(s):</h6>
            <label className="labelTxt"> Recommendation is part of spraying program(s):</label>
            </div>
            </div>
    </div>}
    </div>
    <div> 
        <input data-testid="download"  type="button" value="Download File" className="greenBtn" disabled={checkedFlag===false?true:false} onClick={exportToExcel} /></div>
   </div>
    </>
}

export default ShareModal;