import '../../App.css';
import './Selectinput.css';
import Select from 'react-select';
import { components } from "react-select";
import { useState } from 'react';
import { useRef, useEffect } from 'react';
import axios from 'axios';
import {serviceEndpoints } from '../../config/ServiceEndpoints' ; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCaretDown, faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";

export const colourOptions = [
    { value: "ocean1", label: "Ocean" },
    { value: "blue", label: "Blue" },
    { value: "purple", label: "Purple" },
    { value: "red", label: "Red" },
    { value: "orange", label: "Orange" },
    { value: "yellow", label: "Yellow" },
    { value: "green", label: "Green" },
    { value: "forest", label: "Forest" },
    { value: "slate", label: "Slate" },
    { value: "silver", label: "Silver" }
  ];
let selectedElements = [];
const Control = ({ children, ...props }) => (
    <components.Control {...props}>
       <div className={props.menuIsOpen?'opened selectAnimationWrapper' :'selectAnimationWrapper'}>
    <FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon>
      {props.hasValue && 
      <div>
        {props.selectProps.value.length >1 ? <span>{props.selectProps.value.length} selected</span>:
        <span>{props.selectProps.value[0]&&props.selectProps.value[0].label} </span>
        } 
        </div>} 
     {children}</div>
    </components.Control>
  ); 
 
const Option =  (props) => {
    return (  
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"  className='customChkBoxInput'
            checked={ props.isSelected} id={"multiselectCheker"+props.value}
             area-label={props.label} data-testid="multyChkBoxes"
          />
      <label  htmlFor={"multiselectCheker"+props.value} className='customChkBox' data-testid="mltiselectCheker">
        <FontAwesomeIcon icon={faCheck} />
      </label>
          {" "}
          <label htmlFor={"multiselectCheker"+props.value}>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const DropdownIndicator =(props)=>  {
    return ( 
      <components.DropdownIndicator {...props}> 
       <FontAwesomeIcon icon={faCaretDown} className='rotateHover'/>
      </components.DropdownIndicator> 
    );
  };
function Selectinput(props) {
    const [selectedOption,setselectedOption] = useState(null);
    const [givenOptions, setGivenOptions] = useState([]);
   // const [showMenu, setShowMenu] = useState(false);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    let itrateValue = props.itrateValues?{indx:props.itrateValues.indx, mapTo:props.itrateValues.mapTo}:undefined;
    let mapTo =props.mapTo? props.mapTo :(itrateValue? props.itrateValues.mapTo :undefined);
    useEffect(()=>{
            let options = [];
            if(props.config.endpoint){
              const url = props.config.isFullURL ? props.config.endpoint : process.env.REACT_APP_API_BASEURL+serviceEndpoints[props.config.endpoint];
              axios.get(url).then((result)=>{
                result.data.map(item=>{
                    //console.log(item[props.config.valueKey])
                    
                    if(props.config.valueKey!=='')
                    options.push({value: item[props.config.valueKey], label: item[props.config.labelKey], item: item  })
                    else
                    options.push({value: item.id, label: item[props.config.labelKey], item: item })
                   return item;
                  }) 
                setGivenOptions(options);
                }).catch((e)=>{})
            } else{
                setGivenOptions(props.config.options);
            }
            if(props.value){
               setselectedOption(props.value)
            }
             // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    useEffect(()=>{
      if(props.value)
      {
        props.value.map(item=>{
          if(item.value && item.value.id)
          selectedElements.push(item.value.id);
          else
          selectedElements.push(item.value)
          return item;
        }) 
        setselectedOption(props.value)
      } else{
        setselectedOption([]) 
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.value]);
    const handleChange = (selectedOption)=>{      
         setselectedOption(selectedOption);
       /*  setTimeout(()=>{
           setShowMenu(true)
         })*/
         if(itrateValue){
          props.handleChanges({target:{value:selectedOption, mapTo:itrateValue.mapTo, indx:itrateValue.indx}})
         } else if(mapTo){
          props.handleChanges(selectedOption,mapTo)
         }
         //setShowMenu(true)
    }
  const removeItem = (indexToRemove)=>{
    console.log(selectedOption) 
    const updateNow =  [
      ...selectedOption.slice(0,indexToRemove),
      ...selectedOption.slice(indexToRemove+1)
    ];
    setselectedOption({...updateNow});
    if(itrateValue){
      props.handleChanges({target:{value:updateNow, mapTo:itrateValue.mapTo, indx:itrateValue.indx}})
     } else if(mapTo){
      props.handleChanges(updateNow,mapTo)
     }
  }
  return (
   <div ref={wrapperRef} data-testid={"multiselect-"+mapTo}  style={{width:props.config.width?props.config.width:'100%'}}> 
       <label>{props.config.labelTxt? props.config.labelTxt:''} 
       {props.children}
       </label> 
        <Select  
        value={selectedOption} 
        defaultValue = {selectedOption}
        options={givenOptions} 
        placeholder={''}
        onChange={handleChange}
       /* onFocus={()=>setShowMenu(true)}
        onBlur={()=>setShowMenu(false)}  
        menuIsOpen={showMenu}*/
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isMulti={true} 
        components={{
            Option, Control, DropdownIndicator
          }}
        controlShouldRenderValue={false}
        aria-label={"combobox"+mapTo}
      />
     <div>  
      {props.config.showChips && selectedOption && selectedOption.length>0 && selectedOption.map((item, indx)=>
        <div className='chipWrapper' data-testid={"chip"+mapTo}><span data-testid={"chipSpan"+mapTo}>{item.label}</span>
        <FontAwesomeIcon data-testid={"chipDelete"+mapTo} icon={faXmark} onClick={()=>removeItem(indx)}/></div>)}
     </div>
    </div>
  );

  /**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref) {
  useEffect(() => { 
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
      //  setShowMenu(false);
      }
    } 
    document.addEventListener("mousedown", handleClickOutside);
    return () => { 
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
}

export default Selectinput;
