import React, { useState } from 'react';
import Selectinput from '../../Selectinput/Selectinput';
function TargetModal(props) {
    const [targetSelected, setTargetSelected] = useState(undefined)
    const handleTarget= (val, mapTo)=>{
      console.log('handle target',val)
       setTargetSelected(val)
    }
    const save = ()=>{
        props.saveTarget(targetSelected);
    }
 return (
    <><div className='text-left'>  
      <Selectinput value={targetSelected} config={{endpoint:"target", valueKey:"", labelKey:"scientificName" , labelTxt:'Target'  }} handleChanges={handleTarget} mapTo='target'></Selectinput>
     </div>
     <div className='text-right mt-3'>
     <input data-testid="saveTarget" type="button" value="Save" className="greenBtn"  onClick={save} />
     </div>
    </>
 )
}
export default TargetModal