
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import './AddMoreReusable.css';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import AddMoreFormTemplate from './AddMoreFormTemplate';
function AddMoreReusable(props) {
  const {t} = useTranslation();
const handleChange=(event)=>{ 
    let targetVal = event.target.value;
    let indx = event.target.indx>=0? event.target.indx:event.target.getAttribute('data_index');
    let keyName = props.formConfig.stateKey;
    let mapTo =  event.target.mapTo? event.target.mapTo:(
      event.target.from?"":event.target.getAttribute('name'));
    console.log(targetVal,'...',indx); 
    props.handleChange(targetVal, indx, keyName, mapTo);
    //(value of the input, index of the object in the array, key that used to save array in parent state, key name of te current object)
}
const confirmDelete = (indx)=>{
 let deleteModalConfig = {
    show:false,
    modalHeader:t('Approve Deleting of Row'),  
    closeButtonHandler:props.manageModal,
    modalButtons:[
                  {label:t('Cancel'), handler:props.manageModal},
                  {label:t('Delete Row'), classNames:"greenBtn",
                  handler:props.deleteAddMore,params:{keyname:props.formConfig.stateKey, indx:indx}}],           
                  }
  props.manageModal(deleteModalConfig, true ,t('Are you sure you want to delete this row?'))
}

 return (
    <div>
       {
         props.formValues && props.formValues.map((formValue,indx)=>
        <> { Array.isArray(props.formConfig.formElements) ? <>
        <div className={'row-wrapper w-100'+(indx!==(props.formValues.length -1)?' mb-3':'')} key={'wrapper'+props.formConfig.stateKey+indx}>
        {
      
          <AddMoreFormTemplate handleChange={handleChange} elems={props.formConfig.formElements} specificWidth={props.formConfig.specificWidth}
          formValue={formValue} indx={indx} formValues={props.formValues} formConfig={props.formConfig} ></AddMoreFormTemplate>
         
        }
        { props.formValues.length >0 && <span onClick={()=>confirmDelete(indx)} data-testid={'delete-'+indx}>
          <FontAwesomeIcon icon={faTrash} />
           </span> }
         </div>
        </>
        :
      <>
      <div className={'row-wrapper w-100'+(indx!==(props.formValues.length -1)?' mb-3':'')} key={'wrapper'+props.formConfig.stateKey+indx}>
        {
          <AddMoreFormTemplate handleChange={handleChange} elems={props.formConfig.formElements.row1} specificWidth={props.formConfig.specificWidth.row1}
          formValue={formValue} indx={indx} formValues={props.formValues} formConfig={props.formConfig} ></AddMoreFormTemplate>

        }
        { props.formValues.length >0 && <span onClick={()=>confirmDelete(indx)} data-testid={'delete-'+indx}>
          <FontAwesomeIcon icon={faTrash} />
           </span> }
      </div>
      <div className={'row-wrapper w-100'+(indx!==(props.formValues.length -1)?' mb-3':'')} key={'wrapper'+props.formConfig.stateKey+indx}>
        {
          <AddMoreFormTemplate handleChange={handleChange} elems={props.formConfig.formElements.row2} specificWidth={props.formConfig.specificWidth.row2}
          formValue={formValue} indx={indx} formValues={props.formValues} formConfig={props.formConfig} ></AddMoreFormTemplate>

        }
      </div>
      </>
      }</>

        )
        
        }
        <input type="button" className='addBtn' data-testid={"add-button-"+props.formConfig.stateKey} value={t(props.formConfig.addMoreText)} 
        onClick={()=>props.addMore(props.formConfig.stateKey, props.formConfig.addMoreDefaults)}/>
    </div>
  );
}

export default AddMoreReusable;
