import React, { useContext, useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { Scrollbars } from "react-custom-scrollbars-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import {
  faCheck,
  faCopy,
  faPlus,
  faRemove,
  faTrash,
  faShareNodes,
  faCircleInfo,
  faPen,
  faTimes,
  faWandMagicSparkles,
} from "@fortawesome/free-solid-svg-icons";
import SelectinputSingle from "../../../components/Selectinput/SelectInputSingle";
import Selectinput from "../../../components/Selectinput/Selectinput";
import ModalReusable from "../../../components/ModalReusable/ModalReusable";
import ShareModal from "../../../components/ModalTemplates/ShareModal/ShareModal";
import EfficacyModal from "../../../components/ModalTemplates/EfficacyModal/EfficacyModal";
import AddMoreReusable from "../../../components/AddMoreReusable/AddMoreReusable";
import FormValuesContext from "../FormValues-context";
import PristineValuesContext from "../PristineValues-context";
import { serviceEndpoints } from "../../../config/ServiceEndpoints";
// import { formConfig as appTimingFormConfig } from "../../../config/AddMoreConfig/AppTimingConfigForRecommendation";
import { formConfig as appVolumeProductFormConfig } from "../../../config/AddMoreConfig/AppVolumeProductConfig";
import { formConfig as appVolumeAdjuvantFormConfig } from "../../../config/AddMoreConfig/AppVolumeAdjuvantConfig";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

const RecommendationsTab = (props) => {
  let { id } = useParams();
  const { t } = useTranslation();
  const { formValues, setFormValues } = useContext(FormValuesContext);
  const { pristineValues, setPristineValues } = useContext(
    PristineValuesContext
  );
  const [modalConfig, setModalConfig] = useState({ show: false });
  const [multiFormFlag, setMultiFormFlag] = useState(false);
  const [selectedCrop, setSelectedCrop] = useState({ crops: [] });
  const [currentTab, setCurrentTab] = useState(0);
  const [scrollNow, setScrollNow] = useState(undefined);
  const [cropCliked, setCropCliked] = useState(false);
  const [pristineList, setPristineList] = useState([]);
  const [linkDetails, setLinkDetails] = useState({});
  const [updateLink, setUpdateLink] = useState({});
  const [dropdownFullURLs, setDropdownFullURLs] = useState();
  const [alertObject, setAlertObject] = useState(null);
  const [countyOptions, setCountyOptions] = useState([]);
  const [multySelectItemList, setMultySelectItemList] = useState({
    crops: [],
    registrations: [],
    usageTypes: [],
    traits: [],
    taskTypes: [],
    taskMethods: [],
    soilType: [],
    tillageTypes: [],
    efficacies: [],
  });
  const scrollEndRef = React.createRef();
  const scrollTopRef = React.createRef();
  const scrollRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];
  const menuRef = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];
  const tabRef = useRef();
  const regMenuRef = useRef();
  const [deleteRecError, setDeleteRecError] = useState(undefined);
  let cropAdded = false; //checks if clicked the buttons to add crops or to add multiple crops

  useEffect(() => {
    if (multiFormFlag) {
      scrollTopRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (!multiFormFlag && scrollEndRef.current) {
      if (!scrollEndRef.current.classList.contains("cropClicked"))
        scrollEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (cropCliked) setCropCliked(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiFormFlag, currentTab]);

  useEffect(() => {
    if (id && props.firstRecVisit && formValues.recommendation.length > 0) {
      const updateNow = { ...formValues };
      updateNow.product.id = id;
      let pList = [];
      formValues.recommendation.map((reg, indx) => {
        if (reg.id) pList.push(true);
        else {
          pList.push(false);
        }
        if (reg.applicationTimings) {
          reg.applicationTimings.map((time, indx2) => {
            if (time.starts) {
              let nData = [];
              time.starts.map((s) => {
                nData.push({ item: s, label: s.name, value: s.id });
                return s;
              });
              updateNow.recommendation[indx].applicationTimings[indx2].starts =
                nData;
            }

            if (time.ends) {
              let nData = [];
              time.ends.map((s) => {
                nData.push({ item: s, label: s.name, value: s.id });
                return s;
              });
              updateNow.recommendation[indx].applicationTimings[indx2].ends =
                nData;
            }
            return reg;
          });
        }
        return reg;
      });
      setFormValues({ ...updateNow });
      setPristineList(pList);
      let pristineNow = { ...pristineValues };
      pristineNow.recommendation = pList;
      setPristineValues({ ...pristineNow });
      manageTabSelect(0);
      props.setfirstRecVisit(false);
      props.setRecVisit(false);
      props.setfirstRecVisit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, currentTab]);

  useEffect(() => {
    if (!props.firstRecVisit || id === undefined) {
      presetFormFields(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.recVisit]);

  useEffect(() => {
    setDropdownFullURLs(null);
  }, [currentTab]);

  /**
   * Efficacy and Registration dropdowns should have data from their respective tabs.
   * Prepare the URL based on product id and selected crops
   * @param {string} endpoint - whether registrations/efficacies
   * @returns
   */
  const getDropdownURL = (endpoint) => {
    const crops = formValues.recommendation[currentTab].crops
      .map((crop) => crop.id)
      .join(",");
    return `${process.env.REACT_APP_API_BASEURL}${serviceEndpoints["products"]}/${formValues.product.id}/${endpoint}?crops=${crops}`;
  };

  useEffect(() => {
    if (
      !dropdownFullURLs && // dropdownFullURLs will be set to null on tab change
      formValues.recommendation[currentTab] &&
      formValues.recommendation[currentTab].crops
    ) {
      const fullURLs = {
        efficacies: getDropdownURL("efficacies"),
        registrations: getDropdownURL("registrations"),
      };
      setDropdownFullURLs(fullURLs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownFullURLs, formValues]);

  const addItemToCrops = () => {
    const updateNow = { ...selectedCrop };
    updateNow.crops.push({});
    setSelectedCrop({ ...updateNow });
    cropAdded = true;
  };

  const handleChangeCrops = (event) => {
    const val = event.target.value;
    const indx = event.target.indx;
    const updateNow = { ...selectedCrop };
    updateNow.crops[indx] = val;
    setSelectedCrop({ ...updateNow });
  };

  const handleChangeMultyCrop = (val, mapTo) => {
    const updateNow = { ...formValues };
    let cropList = { ...multySelectItemList };
    cropList[mapTo] = val;
    setMultySelectItemList(cropList);
    updateNow.recommendation[currentTab][mapTo] = val.map((item) => item.item);
    updateNow.formPristine = false;
    setFormValues({ ...updateNow });
    let updatePristine = pristineList;
    updatePristine[currentTab] = false;
    setPristineList(updatePristine);
    let pristineNow = { ...pristineValues };
    pristineNow.recommendation[currentTab] = false;
    setPristineValues({ ...pristineNow });
  };

  const handleChangeForArray = (val, indx, keyName, mapTo) => {
    let updateNow = { ...formValues };
    if (mapTo) updateNow.recommendation[currentTab][keyName][indx][mapTo] = val;
    else updateNow.recommendation[currentTab][keyName][indx] = val;
    updateNow.formPristine = false;
    let updatePristine = pristineList;
    updatePristine[currentTab] = false;
    setPristineList(updatePristine);
    let pristineNow = { ...pristineValues };
    pristineNow.recommendation[currentTab] = false;
    setPristineValues({ ...pristineNow });
    setFormValues({ ...updateNow });
  };

  const handleChange = (val, mapTo) => {
    const updateNow = { ...formValues };
    updateNow.recommendation[currentTab][mapTo] = val;
    updateNow.formPristine = false;
    let updatePristine = pristineList;
    updatePristine[currentTab] = false;
    setPristineList(updatePristine);
    let pristineNow = { ...pristineValues };
    pristineNow.recommendation[currentTab] = false;
    setPristineValues({ ...pristineNow });
    // county is based on state. So any change in state will cause county to reset
    if (mapTo === "state") {
      updateNow.recommendation[currentTab].county = null;
      let counties = [];
      if (
        formValues.recommendation[currentTab] &&
        formValues.recommendation[currentTab].state &&
        formValues.recommendation[currentTab].state.subRegions &&
        Array.isArray(formValues.recommendation[currentTab].state.subRegions)
      ) {
        counties = formValues.recommendation[currentTab].state.subRegions.map(
          (county) => {
            return { label: county.name, value: county };
          }
        );
      }
      setCountyOptions(counties);
    }
    setFormValues({ ...updateNow });
  };

  const deleteSelectedCrop = (indx) => {
    const updateNow = { ...selectedCrop };
    updateNow.crops.splice(indx, 1);
    setSelectedCrop({ ...updateNow });
  };

  const addCrops = () => {
    const updateNow = { ...formValues };
    const crops = { ...selectedCrop };
    const pushableCrops = [];
    const pushableMultySelect = [];
    crops.crops.forEach((elm) => {
      if (elm.name) {
        pushableCrops.push(elm);
        pushableMultySelect.push({ value: elm.id, label: elm.name, item: elm });
      }
    });
    let cropList = { ...multySelectItemList };
    cropList["crops"] = pushableMultySelect;
    setMultySelectItemList(cropList);
    updateNow.recommendation.push({
      crops: pushableCrops,
      efficacy: {},
      registrations: [],
      usageTypes: [],
      applicationTimings: [],
      applicationPeriods: [],
      taskTypes: [],
      taskMethods: [],
      treatmentTypes: [],
      recommendationProducts: [],
      recommendationAdjuvants: [],
      waterApplications: [],
      comments: "",
      links: [],
    });
    setFormValues({ ...updateNow });
    setSelectedCrop({ crops: [{}, {}] });
    setCurrentTab(formValues.recommendation.length - 1);
    presetFormFields(formValues.recommendation.length - 1);
    let updatePristine = pristineList;
    updatePristine.push(false);
    setPristineList(updatePristine);
    let pristineNow = { ...pristineValues };
    pristineNow.recommendation.push(false);
    setPristineValues({ ...pristineNow });
    setMultiFormFlag(false);
  };

  const copyCrop = (indx, e) => {
    e.preventDefault();
    e.stopPropagation();
    const updateNow = { ...formValues };
    let copyItem = { ...updateNow.recommendation[indx] };
    delete copyItem.id;
    copyItem.status = undefined;
    updateNow.recommendation.push(copyItem);
    if (!updateNow.tabStatus) updateNow.tabStatus = {};
    updateNow.tabStatus.recommendationsStatus = "In Editing";
    updateNow.product.recommendationsStatus = "In Editing";
    setFormValues({ ...updateNow });
    setCurrentTab(formValues.recommendation.length - 1);
    let updatePristine = pristineList;
    updatePristine.push(false);
    setPristineList(updatePristine);
    let pristineNow = { ...pristineValues };
    pristineNow.recommendation.push(false);
    setPristineValues({ ...pristineNow });
    setScrollNow(formValues.recommendation.length - 1);
  };

  const manageRegApiCatch = () => {
    let updateNow = { ...formValues };
    updateNow.formError = true;
    updateNow.formSuccess = false;
    setFormValues({ ...updateNow });
    setTimeout(() => {
      let updateNow = { ...formValues };
      updateNow.formError = false;
      setFormValues({ ...updateNow });
    }, 5000);
  };

  const manageRegApiSuccess = (res, status, fromStatusChange) => {
    let updateNow = { ...formValues };
    let data = res.data;
    updateNow.recommendation[currentTab] = data;
    updateNow.formError = false;
    updateNow.formSuccess = true;
    if (data.cutOffMonth && data.cutOffDay) {
      updateNow.recommendation[currentTab].cutOffPeriod =
        ("0" + data.cutOffDay).slice(-2) +
        "/" +
        ("0" + data.cutOffMonth).slice(-2);
    }
    if (!updateNow.tabStatus) {
      updateNow.tabStatus = {};
    }

    if (status === "IN_EDITING" || status === "In Editing") {
      let r = "In Editing";
      updateNow.recommendation.forEach((elm) => {
        if (elm.status === undefined) {
          r = undefined;
        }
      });
      updateNow.tabStatus.recommendationsStatus = r;
      updateNow.product.recommendationsStatus = r;
    } else if (status === "IN_REVIEW") {
      let r = "In Review";
      updateNow.recommendation.forEach((elm) => {
        if (elm.status === undefined) {
          r = undefined;
        }
        if (elm.status === "In Editing") {
          r = "In Editing";
        }
      });
      updateNow.tabStatus.recommendationsStatus = r;
      updateNow.product.recommendationsStatus = r;
    } else if (status === "APPROVED") {
      let r = [];
      updateNow.recommendation.forEach((elm) => {
        r.push(elm.status);
      });
      if (r.indexOf("In Editing") !== -1) {
        updateNow.tabStatus.recommendationsStatus = "In Editing";
        updateNow.product.recommendationsStatus = "In Editing";
      } else if (r.indexOf("In Review") !== -1) {
        updateNow.tabStatus.recommendationsStatus = "In Review";
        updateNow.product.recommendationsStatus = "In Review";
      } else {
        updateNow.tabStatus.recommendationsStatus = "Approved";
        updateNow.product.recommendationsStatus = "Approved";
      }
    }
    setFormValues({ ...updateNow });
    props.setSavedMessageFrom("recommendation");
    if (!fromStatusChange) {
      let updatePristine = pristineList;
      updatePristine[currentTab] = true;
      setPristineList(updatePristine);
      let pristineNow = { ...pristineValues };
      pristineNow.recommendation[currentTab] = true;
      setPristineValues({ ...pristineNow });
    }
    setTimeout(() => {
      updateNow.formSuccess = false;
      setFormValues({ ...updateNow });
    }, 5000);
  };

  const manageForm = (status, multi) => {
    let crops = multi ? [{}, {}] : [{}];
    setMultiFormFlag(status);
    setSelectedCrop({ crops: crops });
  };

  const saveCrop = (e) => {
    e.preventDefault();
    const updateNow = { ...formValues };
    if (
      updateNow.recommendation[currentTab] &&
      updateNow.recommendation[currentTab].cutOffPeriod
    ) {
      const periodArray =
        updateNow.recommendation[currentTab].cutOffPeriod.split("/");
      updateNow.recommendation[currentTab].cutOffDay =
        periodArray && periodArray[0] ? periodArray[0] : null;
      updateNow.recommendation[currentTab].cutOffMonth =
        periodArray && periodArray[1] ? periodArray[1] : null;
    }
    let payload = {};
    Object.keys(updateNow.recommendation[currentTab]).forEach((key) => {
      if (updateNow.recommendation[currentTab][key]) {
        if (
          (Array.isArray(updateNow.recommendation[currentTab][key]) &&
            updateNow.recommendation[currentTab][key].length) ||
          (typeof updateNow.recommendation[currentTab][key] === "object" &&
            Object.keys(updateNow.recommendation[currentTab][key]).length) ||
          typeof updateNow.recommendation[currentTab][key] === "string" ||
          typeof updateNow.recommendation[currentTab][key] === "number"
        ) {
          payload[key] = updateNow.recommendation[currentTab][key];
        }
      }
    });
    if (formValues.product.id) {
      //commented for test case, todo---not saved product tab then save reg tab
      if (!updateNow.recommendation[currentTab].id) {
        axios
          .post(
            process.env.REACT_APP_API_BASEURL +
              serviceEndpoints["products"] +
              "/" +
              formValues.product.id +
              "/recommendations",
            payload
          )
          .then((res) => {
            manageRegApiSuccess(res, "In Editing");
          })
          .catch((e) => {
            manageRegApiCatch();
          });
      } else {
        payload.version = updateNow.recommendation[currentTab].version
          ? updateNow.recommendation[currentTab].version
          : 0;
        axios
          .put(
            process.env.REACT_APP_API_BASEURL +
              serviceEndpoints["products"] +
              "/" +
              formValues.product.id +
              "/recommendations/" +
              updateNow.recommendation[currentTab].id,
            payload
          )
          .then((res) => {
            manageRegApiSuccess(res, "In Editing");
          })
          .catch((e) => {
            manageRegApiCatch();
          });
      }
    }
  };

  const statusChange = (e, status) => {
    e.preventDefault();
    let payload = { statusChange: { status: status, statusChangeComment: "" } };
    axios
      .put(
        process.env.REACT_APP_API_BASEURL +
          serviceEndpoints["products"] +
          "/" +
          formValues.product.id +
          "/recommendations/" +
          formValues.recommendation[currentTab].id +
          "/status",
        payload
      )
      .then((res) => {
        manageRegApiSuccess(res, status, true);
      })
      .catch((e) => {
        manageRegApiCatch();
      });
  };

  const manageModal = (config, status = false, inner = "") => {
    const modalCon = { ...config, ...{ show: status, modalInner: inner } };
    setModalConfig(modalCon);
  };

  const shareModalConfig = {
    show: false,
    largeWindow: true,
    modalHeader: t("Share Items"),
    closeButtonHandler: manageModal,
    modalButtons: [],
  };

  const efficacyModalConfig = {
    show: false,
    largeWindow: true,
    modalHeader: "",
    closeButtonHandler: manageModal,
    modalButtons: [{ label: t("Close"), handler: manageModal }],
  };

  const manageShareModalContent = (e) => {
    e.preventDefault();
    manageModal(
      shareModalConfig,
      true,
      <ShareModal selectedRows={[formValues.product.id]}></ShareModal>
    );
  };

  const deleteReg = () => {
    if (formValues.recommendation[currentTab].id) {
      axios
        .delete(
          process.env.REACT_APP_API_BASEURL +
            serviceEndpoints["products"] +
            "/" +
            formValues.product.id +
            "/recommendations/" +
            formValues.recommendation[currentTab].id
        )
        .then((res) => {
          deleteSuccessUpdates();
        })
        .catch((e) => {
          const message = e.response
            ? e.response.data.message
            : "Opps something went wrong";
          setDeleteRecError(message);
          manageModal(deleteModalConfig, false, "");
          setTimeout(() => {
            setDeleteRecError(undefined);
          }, 4000);
        });
    } else {
      deleteSuccessUpdates();
    }
  };
  const deleteSuccessUpdates = () => {
    let updateNow = { ...formValues };
    updateNow.recommendation = [
      ...updateNow.recommendation.slice(0, currentTab),
      ...updateNow.recommendation.slice(currentTab + 1),
    ];

    let updatePristine = pristineList;
    updatePristine = [
      ...updatePristine.slice(0, currentTab),
      ...updatePristine.slice(currentTab + 1),
    ];
    setFormValues({ ...updateNow });
    setPristineList(updatePristine);
    let pristineNow = { ...pristineValues };
    pristineNow.recommendation = [
      ...pristineNow.recommendation.slice(0, currentTab),
      ...pristineNow.recommendation.slice(currentTab + 1),
    ];
    setPristineValues({ ...pristineNow });
    setCurrentTab(0);
    manageTabSelect(0);
    manageModal(deleteModalConfig, false, "");
  };
  const deleteModalConfig = {
    show: false,
    modalHeader: t("Approve Deletion of Recommendation"),
    closeButtonHandler: manageModal,
    modalButtons: [
      { label: t("Cancel"), handler: manageModal },
      {
        label: t("Delete Recommendation"),
        classNames: "greenBtn",
        handler: deleteReg,
      },
    ],
  };

  const deleteRegModalContent = (e) => {
    e.preventDefault();
    manageModal(
      deleteModalConfig,
      true,
      t("Are you sure you want to delete this Recommendation?")
    );
  };

  const scrollSmoothHandler = (index) => {
    scrollRefs[index].current.scrollIntoView({ behavior: "smooth" });
  };
  const addClassAttribute = (addClass) => {
    const removeClass = Array.from({ length: scrollRefs.length }, (v, i) => i);
    for (let item in removeClass)
      menuRef[item].current.classList.remove("active");
    menuRef[addClass].current.classList.add("active");
  };
  const setMenuItem = (e) => {
    let scrollHight = tabRef.current.scrollTop;
    if (scrollHight > 60) {
      regMenuRef.current.classList.add("positionFixed");
    } else {
      regMenuRef && regMenuRef.current.classList.remove("positionFixed");
    }
    
    let elementHeightSum = 0;
    for (let i = 0; i < scrollRefs.length; i++) {
      elementHeightSum += scrollRefs[i].current.offsetHeight;
      if (e.target.scrollTop < elementHeightSum) {
        addClassAttribute(i);
        break;
      }
    }
  };

  const addMore = (keyName, defaults) => {
    let updateNow = { ...formValues };
    updateNow.recommendation[currentTab][keyName].push({ ...defaults });
    let updatePristine = pristineList;
    updatePristine[currentTab] = false;
    setPristineList(updatePristine);
    let pristineNow = { ...pristineValues };
    pristineNow.recommendation[currentTab] = false;
    setPristineValues({ ...pristineNow });
    setFormValues({ ...formValues });
  };

  const deleteAddMore = (params) => {
    let updateNow = { ...formValues };
    delete updateNow.recommendation[currentTab][params.keyname][[params.indx]];
    let updatePristine = pristineList;
    updatePristine[currentTab] = false;
    setPristineList(updatePristine);
    let pristineNow = { ...pristineValues };
    pristineNow.recommendation[currentTab] = false;
    setPristineValues({ ...pristineNow });
    setFormValues({ ...updateNow });
    setModalConfig({ ...modalConfig, ...{ show: false } });
  };

  const manageTabSelect = (itemIndx) => {
    setCropCliked(true);
    setCurrentTab(itemIndx);
    presetFormFields(itemIndx);
  };

  const presetFormFields = (itemIndx) => {
    let multySelectItems = {};
    if (formValues.recommendation[itemIndx]) {
      multySelectItems = {
        crops: createItemList(formValues.recommendation[itemIndx].crops),
        registrations: createItemList(
          formValues.recommendation[itemIndx].registrations,
          "functionalId"
        ),
        usageTypes: createItemList(
          formValues.recommendation[itemIndx].usageTypes
        ),
        traits: createItemList(formValues.recommendation[itemIndx].traits),
        taskTypes: createItemList(
          formValues.recommendation[itemIndx].taskTypes
        ),
        taskMethods: formValues.recommendation[itemIndx].taskMethods
          ? createItemList(formValues.recommendation[itemIndx].taskMethods)
          : [],
        soilType: createItemList(formValues.recommendation[itemIndx].soilType),
        tillageTypes: createItemList(
          formValues.recommendation[itemIndx].tillageTypes
        ),
        efficacies: createItemList(
          formValues.recommendation[itemIndx].efficacies,
          "functionalId"
        ),
      };
    }
    setMultySelectItemList(multySelectItems);
  };

  const createItemList = (data, labelKey) => {
    let elm = [];
    if (data)
      data.forEach((item) => {
        elm.push({
          value: item.id,
          label: labelKey ? item[labelKey] : item.name,
          item: item,
        });
      });
    return elm;
  };

  /** Add/Edit/Delete Source - Start */
  const updateLinkDetails = (e, mapTo) => {
    let updateNow = { ...linkDetails };
    updateNow[mapTo] = e.target.value;
    setLinkDetails(updateNow);
  };

  const addSource = () => {
    let updateNow = { ...formValues };
    updateNow.recommendation[currentTab].links.push(linkDetails);
    setLinkDetails({});
  };

  const handleChangeLinks = (val, mapTo) => {
    let updateNow = { ...updateLink };
    updateNow[mapTo] = val;
    setUpdateLink(updateNow);
  };

  const saveUpdateLink = (indx) => {
    let updateNow = { ...formValues };
    updateNow.recommendation[currentTab].links[indx] = updateLink;
    setFormValues(updateNow);
    setUpdateLink({});
  };

  const deleteUpdateLink = (indx) => {
    let updateNow = { ...formValues };
    updateNow.recommendation[currentTab].links.splice(indx, 1);
    setFormValues(updateNow);
  };
  /** Add/Edit/Delete Source - Start */

  /**
   * Prefill recommendation tab data with selected registration data
   */
  const prefillWithRegistrationData = () => {
    if (
      // check if registration is selected in recommendation tab
      formValues.recommendation[currentTab] &&
      formValues.recommendation[currentTab].registrations &&
      formValues.recommendation[currentTab].registrations.length
    ) {
      const selectedRegistration =
        formValues.recommendation[currentTab].registrations[0];
      const updateNow = { ...formValues };
      /** Update form values - Start */
      updateNow.recommendation[currentTab].usageTypes =
        selectedRegistration.usageTypes
          ? [
              ...selectedRegistration.usageTypes.map((item) => ({
                item: item,
                label: item.name,
                id: item.id,
              })),
            ]
          : [];
      updateNow.recommendation[currentTab].traits = selectedRegistration.traits
        ? [
            ...selectedRegistration.traits.map((item) => ({
              item: item,
              label: item.name,
              id: item.id,
            })),
          ]
        : [];
      updateNow.recommendation[currentTab].taskTypes =
        selectedRegistration.taskTypes
          ? [
              ...selectedRegistration.taskTypes.map((item) => ({
                item: item,
                label: item.name,
                id: item.id,
              })),
            ]
          : [];
      updateNow.recommendation[currentTab].taskMethods =
        selectedRegistration.taskMethods
          ? [
              ...selectedRegistration.taskMethods.map((item) => ({
                item: item,
                label: item.name,
                id: item.id,
              })),
            ]
          : [];
      updateNow.recommendation[currentTab].applicationRateMax =
        selectedRegistration.applicationRateMax;
      updateNow.recommendation[currentTab].applicationRateMin =
        selectedRegistration.applicationRateMin;
      updateNow.recommendation[currentTab].applicationRateUom =
        selectedRegistration.applicationRateUom;
      updateNow.recommendation[currentTab].waterApplications =
        selectedRegistration.waterApplications
          ? [
              ...selectedRegistration.waterApplications.map((item) => ({
                item: item,
                label: item.name,
                id: item.id,
              })),
            ]
          : [];
      updateNow.recommendation[currentTab].ppiDays =
        selectedRegistration.ppiDays;
      updateNow.recommendation[currentTab].phiDays =
        selectedRegistration.phiDays;
      /** Update form values - End */

      /** Update multi select items - Start */
      let multySelectItems = { ...multySelectItemList };
      multySelectItems.usageTypes =
        updateNow.recommendation[currentTab].usageTypes;
      multySelectItems.traits = updateNow.recommendation[currentTab].traits;
      multySelectItems.taskTypes =
        updateNow.recommendation[currentTab].taskTypes;
      multySelectItems.taskMethods =
        updateNow.recommendation[currentTab].taskMethods;
      setMultySelectItemList(multySelectItems);
      /** Update multi select items - End */

      updateNow.formPristine = false;
      let updatePristine = pristineList;
      updatePristine[currentTab] = false;
      setPristineList(updatePristine);
      let pristineNow = { ...pristineValues };
      pristineNow.recommendation[currentTab] = false;
      setPristineValues({ ...pristineNow });
      setFormValues({ ...updateNow });
    } else {
      // if reg is not selected, show error
      const obj = {
        type: "warning",
        errorMsg: "Please select a registration to prefill data.",
      };
      setAlertObject(obj);
      setTimeout(() => {
        setAlertObject(null);
      }, 5000);
    }
  };

  return (
    <>
      {deleteRecError && (
        <div className="warning" data-testid="saveError">
          <span>!</span> <span>{deleteRecError}</span>
          <span
            className="float-right cursor-pointer"
            data-testid="removeDeleteError"
            onClick={() => setDeleteRecError(undefined)}
          >
            x
          </span>
        </div>
      )}
      {alertObject && (
        <div className={alertObject.type} data-testid="saveError">
          <span>!</span> <span>{alertObject.errorMsg}</span>
          <span
            className="float-right cursor-pointer"
            onClick={() => setAlertObject(null)}
          >
            x
          </span>
        </div>
      )}
      <div>
        {/* Add Crop section - Start */}
        <div
          className={
            "sideBar3 " +
            (formValues.recommendation.length === 0 ? " noVisibleScroll" : "")
          }
        >
          <Scrollbars
            style={{ width: "310px", height: "inherit" }}
            className={"customScrolld"}
          >
            <div
              className={
                "p-3 pb-0 headerWrapper " +
                (formValues.recommendation.length !== 0 ? "position-fixed" : "")
              }
            >
              <h4 className="mt-0 ">{t("Recommendation")}</h4>
              <div>
                <button
                  data-testid="addCrop"
                  className="greenBtn"
                  onClick={() => manageForm(true, false)}
                  disabled={multiFormFlag ? true : false}
                >
                  <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                  {t("Add Recommendation")}
                </button>
                {cropAdded === false && (
                  <p className="infoP mb-0">
                    {t(
                      "Click here to add application recommendation parameters for a single crop."
                    )}
                  </p>
                )}
              </div>
            </div>
            <div
              ref={scrollTopRef}
              className={
                "p-3 " +
                (formValues.recommendation.length !== 0 ? "moveToBottom" : "") +
                (multiFormFlag ? " grayForm" : "") +
                (cropAdded === false ? " morePadding" : "")
              }
            >
              {multiFormFlag && (
                <div className="mb-2">
                  {selectedCrop.crops.map((item, indx) => (
                    <div className="d-flex pb-3">
                      <SelectinputSingle
                        config={{
                          endpoint: "crop",
                          valueKey: "",
                          labelKey: "name",
                          labelKeySec: "eppoCode",
                          singleSelect: true,
                          width: "220px",
                        }}
                        value={selectedCrop.crops[indx]}
                        mapTo="crop"
                        handleChanges={handleChangeCrops}
                        itrateValues={{
                          indx: indx,
                          mapTo: "crop",
                          stateKey: "crops",
                        }}
                        disabledOptions={selectedCrop.crops}
                      ></SelectinputSingle>
                      <FontAwesomeIcon
                        data-testid="cropAddDelete"
                        className="deleteSelectedCrop"
                        icon={faTrash}
                        onClick={() => deleteSelectedCrop(indx)}
                      />
                    </div>
                  ))}
                  <button
                    data-testid="addCropItems"
                    className="grayBtn"
                    onClick={addItemToCrops}
                  >
                    {t("Add Crop")}
                  </button>
                  <FontAwesomeIcon
                    data-testid="addCropFormValues"
                    icon={faCheck}
                    onClick={() => addCrops()}
                    className="greenBtn p-2 float-right"
                  />
                  <FontAwesomeIcon
                    data-testid="removeCropItems"
                    icon={faRemove}
                    onClick={() => manageForm(false, true)}
                    className="grayBtn p-2 float-right"
                  />
                </div>
              )}
              {formValues.recommendation.length > 0 &&
                formValues.recommendation.map((item, itemIndx) => (
                  <div
                    data-testid="cropMenuElm"
                    className={
                      "cropsMenu" + (currentTab === itemIndx ? " active" : "")
                    }
                    onClick={() => {
                      manageTabSelect(itemIndx);
                    }}
                  >
                    <span
                      className={
                        "float-left cropsSpan" +
                        (pristineValues.recommendation[itemIndx] !== false
                          ? ""
                          : " notSaved") +
                        (item.status === "In Review"
                          ? " reviewed"
                          : item.status === "Approved"
                          ? " approved"
                          : "")
                      }
                    >
                      {(itemIndx + 1).toString().length >= 3
                        ? itemIndx + 1
                        : (itemIndx + 1).toString().length === 2
                        ? "0" + (itemIndx + 1)
                        : "00" + (itemIndx + 1)}
                    </span>
                    <div className="elepsisDiv">
                      {item.crops && item.crops.length === 0 && (
                        <>
                          <>{t("General")}</>
                          <span
                            className="copySpan"
                            onClick={(e) => copyCrop(itemIndx, e)}
                          >
                            <FontAwesomeIcon icon={faCopy} /> {t("Copy")}
                          </span>
                        </>
                      )}
                      {item.crops &&
                        item.crops.map((crop, indx) => (
                          <>
                            {crop.name}{" "}
                            {indx < item.crops.length - 1 && <>,&nbsp;</>}
                            <span
                              className="copySpan"
                              data-testid="copyCrop"
                              onClick={(e) => copyCrop(itemIndx, e)}
                            >
                              <FontAwesomeIcon icon={faCopy} /> {t("Copy")}
                            </span>
                          </>
                        ))}
                    </div>{" "}
                    {item.crops && item.crops.length !== 0 && (
                      <span className="float-left">
                        {item.crops.length} {t("Items")}
                      </span>
                    )}
                  </div>
                ))}
            </div>
            <div
              ref={scrollEndRef}
              className={
                "scrolled" + scrollNow + (cropCliked ? " cropClicked" : "")
              }
            ></div>
          </Scrollbars>
        </div>
        {/* Add Crop section - End */}
        {formValues.recommendation[currentTab] && (
          <div
            className="tabs"
            data-testid="scrollTab"
            currentTab={currentTab}
            ref={tabRef}
            onScroll={(e) => setMenuItem(e)}
          >
            <h3 className="innerHead">
              {t("Recommendation")}
              {formValues.recommendation.length > 0 && (
                <>
                  <button
                    className="deleteReg"
                    data-testid="regDelete"
                    onClick={(e) => {
                      deleteRegModalContent(e);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} /> {t("Delete")}
                  </button>
                  <button
                    className="deleteReg deleteRec"
                    data-testid="regPrefill"
                    onClick={(e) => {
                      prefillWithRegistrationData();
                    }}
                  >
                    <FontAwesomeIcon icon={faWandMagicSparkles} />{" "}
                    {t("Prefill with Registration Data")}
                  </button>
                </>
              )}
            </h3>
            <>
              <div>
                {/* tab content {currentTab} crops index selected */}
                <p>
                  {t(
                    "Crop-based recommendations for product usage in specific scenarios and regions. Single recommendations, tank mixes of two or more products and additives are supported. These can be combined into Recommendation Sequences in the next section."
                  )}
                </p>

                <ul className="regMenu recMenu" ref={regMenuRef}>
                  <li
                    className="active"
                    ref={menuRef[0]}
                    onClick={() => scrollSmoothHandler(0)}
                    data-testid="generalInfo"
                  >
                    {t("General Information")}
                  </li>
                  <li
                    ref={menuRef[1]}
                    onClick={() => scrollSmoothHandler(1)}
                    data-testid="regionalSpecifications"
                  >
                    {t("Regional Specifications")}
                  </li>
                  <li
                    ref={menuRef[2]}
                    onClick={() => scrollSmoothHandler(2)}
                    data-testid="applicationInformation"
                  >
                    {t("Application Information")}
                  </li>
                  <li
                    ref={menuRef[3]}
                    onClick={() => scrollSmoothHandler(3)}
                    data-testid="applicationVolume"
                  >
                    {t("Application Volume")}
                  </li>
                  <li
                    ref={menuRef[4]}
                    onClick={() => scrollSmoothHandler(4)}
                    data-testid="applicationVolumeSec"
                  >
                    {t("Application Type")}
                  </li>
                  <li
                    ref={menuRef[5]}
                    onClick={() => scrollSmoothHandler(5)}
                    data-testid="restrictions"
                  >
                    {t("Restrictions")}
                  </li>
                  <li
                    data-testid="additionalInfo"
                    ref={menuRef[6]}
                    onClick={() => scrollSmoothHandler(6)}
                  >
                    {t("Additional Information")}
                  </li>
                  <li
                    ref={menuRef[7]}
                    onClick={() => scrollSmoothHandler(7)}
                    data-testid="sources"
                  >
                    {t("Sources")}
                  </li>
                </ul>
              </div>

              {/* Tab 1 - Start */}
              <div ref={scrollRefs[0]} className="pt-2">
                <h5>{t("General Information")}</h5>
                <div className="productRows  mt-2">
                  <div className="row formRow">
                    <div className="col-sm-6 col-12">
                      <label className="w-100">
                        {t("Functional ID")}
                        <div className="position-relative infoDiv">
                          <span>
                            {t(
                              "Predefined by the system for tracking (not adjustable)."
                            )}
                          </span>
                          <FontAwesomeIcon
                            icon={faCircleInfo}
                          ></FontAwesomeIcon>
                        </div>
                      </label>
                      <input
                        type="text"
                        value={
                          formValues.recommendation[currentTab].functionalId
                            ? formValues.recommendation[currentTab].functionalId
                            : ""
                        }
                        readOnly={true}
                        className="w-100"
                      />
                    </div>
                  </div>
                  <div className="row formRow">
                    <div className="col-sm-6 col-12">
                      <Selectinput
                        value={multySelectItemList.crops}
                        config={{
                          endpoint: "crop",
                          valueKey: "",
                          labelKey: "name",
                          labelTxt: t("Crop(s)"),
                          showChips: true,
                        }}
                        handleChanges={handleChangeMultyCrop}
                        mapTo="crops"
                      ></Selectinput>
                    </div>
                    {formValues.product.id && dropdownFullURLs ? (
                      <div className="col-sm-6 col-12">
                        <SelectinputSingle
                          config={{
                            endpoint: dropdownFullURLs?.efficacies,
                            isFullURL: true,
                            valueKey: "",
                            labelKey: "functionalId",
                            labelTxt: t("Efficacy ID"),
                            singleSelect: true,
                          }}
                          value={formValues.recommendation[currentTab].efficacy}
                          mapTo="efficacy"
                          handleChanges={handleChange}
                        ></SelectinputSingle>
                      </div>
                    ) : null}
                  </div>
                  <div className="row formRow">
                    <div className="col-sm-6 col-12">
                      <button
                        className="efficacyBtn"
                        data-testid="efficacyBtn"
                        onClick={(e) => {
                          manageModal(
                            efficacyModalConfig,
                            true,
                            <EfficacyModal
                              recommendationData={
                                formValues.recommendation[currentTab]
                              }
                              productId={formValues.product.id}
                            ></EfficacyModal>
                          );
                        }}
                      >
                        {t("Open Efficacy Details")}
                      </button>
                    </div>
                  </div>
                  {formValues.product.id && dropdownFullURLs ? (
                    <div className="row formRow">
                      <div className="col-sm-6 col-12">
                        <Selectinput
                          value={multySelectItemList.registrations}
                          config={{
                            endpoint: dropdownFullURLs?.registrations,
                            isFullURL: true,
                            valueKey: "",
                            labelKey: "functionalId",
                            labelTxt: t("Registration"),
                            showChips: true,
                          }}
                          handleChanges={handleChangeMultyCrop}
                          mapTo="registrations"
                        >
                          <div className="position-relative infoDiv">
                            <span>
                              {t(
                                "Please select the corresponding registration entry to load prefilled information."
                              )}
                            </span>
                            <FontAwesomeIcon
                              icon={faCircleInfo}
                            ></FontAwesomeIcon>
                          </div>
                        </Selectinput>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              {/* Tab 1 - End */}

              {/* Tab 2 - Start */}
              <div ref={scrollRefs[1]} className="pt-2">
                <h5 className="pt-3">
                  {t("Regional Specifications")}
                  {formValues.product.region ? (
                    <div className="row formRow">
                      <div className="col-sm-6 col-12">
                        <SelectinputSingle
                          config={{
                            options: formValues.product.region.subRegions
                              ? formValues.product.region.subRegions.map(
                                  (state) => {
                                    return { label: state.name, value: state };
                                  }
                                )
                              : [],
                            singleSelect: true,
                            labelTxt: "State",
                            labelKey: "name",
                          }}
                          value={formValues.recommendation[currentTab].state}
                          mapTo="state"
                          handleChanges={handleChange}
                        ></SelectinputSingle>
                      </div>
                      {countyOptions.length ? (
                        <div className="col-sm-6 col-12">
                          <SelectinputSingle
                            config={{
                              options: countyOptions,
                              singleSelect: true,
                              labelTxt: "County",
                              labelKey: "name",
                            }}
                            value={formValues.recommendation[currentTab].county}
                            mapTo="county"
                            handleChanges={handleChange}
                          ></SelectinputSingle>
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <div className="position-relative infoDiv">
                      <span>
                        {t(
                          "No Regions available for the selected country. Please add regions for this country in master-data."
                        )}
                      </span>
                    </div>
                  )}
                </h5>
              </div>
              {/* Tab 2 - End */}

              {/* Tab 3 - Start */}
              <div ref={scrollRefs[2]} className="pt-2">
                <h5 className="pt-3">{t("Application Information")}</h5>
                <div className="row formRow">
                  <div className="col-sm-6 col-12">
                    <Selectinput
                      value={multySelectItemList.usageTypes}
                      config={{
                        endpoint: "cropUsageType",
                        valueKey: "",
                        labelKey: "name",
                        labelTxt: t("Crop Usage Type"),
                        showChips: true,
                      }}
                      handleChanges={handleChangeMultyCrop}
                      mapTo="usageTypes"
                    ></Selectinput>
                  </div>
                  <div className="col-sm-6 col-12">
                    <Selectinput
                      value={multySelectItemList.traits}
                      config={{
                        endpoint: "trait",
                        valueKey: "",
                        labelKey: "name",
                        labelTxt: t("Required Traits"),
                        showChips: true,
                      }}
                      handleChanges={handleChangeMultyCrop}
                      mapTo="traits"
                    ></Selectinput>
                  </div>
                </div>
                <div className="row formRow">
                  <div className="col-sm-6 col-12">
                    <Selectinput
                      value={multySelectItemList.taskTypes}
                      config={{
                        endpoint: "taskType",
                        valueKey: "",
                        labelKey: "name",
                        labelTxt: t("Task Type"),
                        showChips: true,
                      }}
                      handleChanges={handleChangeMultyCrop}
                      mapTo="taskTypes"
                    ></Selectinput>
                  </div>
                  <div className="col-sm-6 col-12">
                    <Selectinput
                      value={multySelectItemList.taskMethods}
                      config={{
                        endpoint: "taskMethod",
                        valueKey: "",
                        labelKey: "name",
                        labelTxt: t("Task Method"),
                        showChips: true,
                      }}
                      handleChanges={handleChangeMultyCrop}
                      mapTo="taskMethods"
                    ></Selectinput>
                  </div>
                </div>
                {/* <div className="formRow row">
                  <div className="col-sm-12 col-12">
                    <AddMoreReusable
                      formValues={
                        formValues.recommendation[currentTab].applicationTimings
                      }
                      formConfig={appTimingFormConfig}
                      handleChange={handleChangeForArray}
                      addMore={addMore}
                      manageModal={manageModal}
                      deleteAddMore={deleteAddMore}
                    ></AddMoreReusable>
                  </div>
                </div> */}
              </div>
              {/* Tab 3 - End */}

              {/* Tab 4 - Start */}
              <div ref={scrollRefs[3]} className="pt-2">
                <h5 className="pt-5">{t("Application Volume")}</h5>

                <div className="formRow row">
                  <div className="col-12">
                    <label className="w-100  ">{t("Product")}</label>
                    <input
                      type="text"
                      value={
                        formValues.recommendation[currentTab].product &&
                        formValues.recommendation[currentTab].product.name
                          ? formValues.recommendation[currentTab].product.name
                          : ""
                      }
                      className="w-100"
                      disabled
                    />
                  </div>
                </div>
                <div className="formRow row">
                  <div className="col-md-3">
                    <label className="w-100  ">
                      {t("Most commonly used rate")}
                    </label>
                    <input
                      type="number"
                      data-testid={"recVolumeCommonRate" + currentTab}
                      value={
                        formValues.recommendation[currentTab]
                          .mostCommonlyUsedRate
                          ? formValues.recommendation[currentTab]
                              .mostCommonlyUsedRate
                          : ""
                      }
                      className="w-100"
                      onChange={(e) =>
                        handleChange(e.target.value, "mostCommonlyUsedRate")
                      }
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="w-100  ">{t("Min Rate")}</label>
                    <input
                      type="number"
                      value={
                        formValues.recommendation[currentTab].applicationRateMin
                          ? formValues.recommendation[currentTab]
                              .applicationRateMin
                          : ""
                      }
                      className="w-100"
                      onChange={(e) =>
                        handleChange(e.target.value, "applicationRateMin")
                      }
                      data-testid="applicationRateMin"
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="w-100  ">{t("Max Rate")}</label>
                    <input
                      type="number"
                      value={
                        formValues.recommendation[currentTab].applicationRateMax
                          ? formValues.recommendation[currentTab]
                              .applicationRateMax
                          : ""
                      }
                      className="w-100"
                      onChange={(e) =>
                        handleChange(e.target.value, "applicationRateMax")
                      }
                      data-testid="applicationRateMax"
                    />
                  </div>
                  <div className="col-md-3">
                    <SelectinputSingle
                      config={{
                        endpoint: "uom",
                        valueKey: "",
                        labelKey: "name",
                        labelTxt: t("Unit of Measurement"),
                        singleSelect: true,
                      }}
                      value={
                        formValues.recommendation[currentTab].applicationRateUom
                      }
                      mapTo="applicationRateUom"
                      handleChanges={handleChange}
                    ></SelectinputSingle>
                  </div>
                </div>
                <div className="formRow row">
                  <div className="col-12">
                    <AddMoreReusable
                      formValues={
                        formValues.recommendation[currentTab]
                          .recommendationProducts
                      }
                      formConfig={appVolumeProductFormConfig}
                      handleChange={handleChangeForArray}
                      addMore={addMore}
                      manageModal={manageModal}
                      deleteAddMore={deleteAddMore}
                    ></AddMoreReusable>
                  </div>
                </div>
                <div className="formRow row">
                  <div className="col-12">
                    <AddMoreReusable
                      formValues={
                        formValues.recommendation[currentTab]
                          .recommendationAdjuvants
                      }
                      formConfig={appVolumeAdjuvantFormConfig}
                      handleChange={handleChangeForArray}
                      addMore={addMore}
                      manageModal={manageModal}
                      deleteAddMore={deleteAddMore}
                    ></AddMoreReusable>
                  </div>
                </div>
              </div>
              {/* Tab 4 - End */}

              {/* Tab 5 - Start */}
              <div ref={scrollRefs[4]} className="pt-2">
                <h5 className="pt-3">{t("Application Type")}</h5>
                <div className="formRow row">
                  <div className="col-md-3">
                    <SelectinputSingle
                      config={{
                        endpoint: "waterAppType",
                        valueKey: "",
                        labelKey: "name",
                        labelTxt: t("Water Application Type"),
                        singleSelect: true,
                      }}
                      value={
                        formValues.recommendation[currentTab]
                          .waterApplicationType
                      }
                      mapTo="waterApplicationType"
                      handleChanges={handleChange}
                    ></SelectinputSingle>
                  </div>
                  <div className="col-md-3">
                    <label className="w-100  ">{t("Min Rate")}</label>
                    <input
                      type="number"
                      value={
                        formValues.recommendation[currentTab]
                          .waterApplicationRateMin
                          ? formValues.recommendation[currentTab]
                              .waterApplicationRateMin
                          : ""
                      }
                      className="w-100"
                      onChange={(e) =>
                        handleChange(e.target.value, "waterApplicationRateMin")
                      }
                      data-testid="waterApplicationRateMin"
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="w-100  ">{t("Max Rate")}</label>
                    <input
                      type="number"
                      value={
                        formValues.recommendation[currentTab]
                          .waterApplicationRateMax
                          ? formValues.recommendation[currentTab]
                              .waterApplicationRateMax
                          : ""
                      }
                      className="w-100"
                      onChange={(e) =>
                        handleChange(e.target.value, "waterApplicationRateMax")
                      }
                      data-testid="waterApplicationRateMax"
                    />
                  </div>
                  <div className="col-md-3">
                    <SelectinputSingle
                      config={{
                        endpoint: "uom",
                        valueKey: "",
                        labelKey: "name",
                        labelTxt: t("Unit of Measurement"),
                        singleSelect: true,
                      }}
                      value={
                        formValues.recommendation[currentTab]
                          .waterApplicationRateUom
                      }
                      mapTo="waterApplicationRateUom"
                      handleChanges={handleChange}
                    ></SelectinputSingle>
                  </div>
                </div>
                {/* <div className="formRow row">
                  <div className="col-12">
                    <AddMoreReusable
                      formValues={
                        formValues.recommendation[currentTab].waterApplications
                      }
                      formConfig={appTypeConfig}
                      handleChange={handleChangeForArray}
                      addMore={addMore}
                      manageModal={manageModal}
                      deleteAddMore={deleteAddMore}
                    ></AddMoreReusable>
                  </div>
                </div> */}
              </div>
              {/* Tab 5 - End */}

              {/* Tab 6 - Start */}
              <div ref={scrollRefs[5]} className="pt-2">
                <h5 className="pt-3">{t("Restrictions")}</h5>
                <div className="row formRow">
                  <div className="col-sm-6 col-12">
                    <label className="w-100  ">
                      {t("Pre-Plant Interval (days)")}
                    </label>
                    <input
                      type="number"
                      value={
                        formValues.recommendation[currentTab].ppiDays
                          ? formValues.recommendation[currentTab].ppiDays
                          : ""
                      }
                      className="w-100"
                      onChange={(e) => handleChange(e.target.value, "ppiDays")}
                      data-testid="ppiDays"
                    />
                  </div>
                  <div className="col-sm-6 col-12">
                    <label className="w-100  ">
                      {t("Pre-Harvest Interval (days)")}
                    </label>
                    <input
                      type="number"
                      value={
                        formValues.recommendation[currentTab].phiDays
                          ? formValues.recommendation[currentTab].phiDays
                          : ""
                      }
                      className="w-100"
                      onChange={(e) => handleChange(e.target.value, "phiDays")}
                      data-testid="phiDays"
                    />
                  </div>
                </div>
                <div className="row formRow">
                  <div className="col-sm-6 col-12">
                    <label className="w-100  ">
                      {t("Cut-Off Period (day/month)")}
                    </label>
                    <input
                      type="text"
                      value={
                        formValues.recommendation[currentTab].cutOffPeriod
                          ? formValues.recommendation[currentTab].cutOffPeriod
                          : ""
                      }
                      className="w-100"
                      placeholder="dd/mm"
                      onChange={(e) =>
                        handleChange(e.target.value, "cutOffPeriod")
                      }
                      data-testid="cutOffPeriod"
                    />
                  </div>
                </div>
              </div>
              {/* Tab 6 - End */}

              {/* Tab 7 - Start */}
              <div ref={scrollRefs[6]} className="pt-2">
                <h5 className="pt-3">{t("Additional Information")}</h5>
                <div className="row formRow">
                  <div className="col-sm-4 col-12">
                    <Selectinput
                      value={multySelectItemList.soilType}
                      config={{
                        endpoint: "soilType",
                        valueKey: "",
                        labelKey: "name",
                        labelTxt: t("Soil Type"),
                        showChips: true,
                      }}
                      handleChanges={handleChangeMultyCrop}
                      mapTo="soilType"
                    ></Selectinput>
                  </div>
                  <div className="col-sm-4 col-12">
                    <SelectinputSingle
                      config={{
                        endpoint: "organicMatterContent",
                        valueKey: "",
                        labelKey: "name",
                        labelTxt: t("Organic Matter Content"),
                        singleSelect: true,
                      }}
                      value={
                        formValues.recommendation[currentTab]
                          .organicMatterContent
                      }
                      mapTo="organicMatterContent"
                      handleChanges={handleChange}
                    ></SelectinputSingle>
                  </div>
                  <div className="col-sm-4 col-12">
                    <Selectinput
                      value={multySelectItemList.tillageTypes}
                      config={{
                        endpoint: "tillageType",
                        valueKey: "",
                        labelKey: "name",
                        labelTxt: t("Tillage Type"),
                        showChips: true,
                      }}
                      handleChanges={handleChangeMultyCrop}
                      mapTo="tillageTypes"
                    ></Selectinput>
                  </div>
                </div>
                {/* Removing as part of demo feedback
                {dropdownFullURLs && (
                  <div className="row formRow">
                    <div className="col-sm-6 col-12">
                      <Selectinput
                        value={multySelectItemList.efficacies}
                        config={{
                          endpoint: dropdownFullURLs?.efficacies,
                          isFullURL: true,
                          valueKey: "",
                          labelKey: "functionalId",
                          labelTxt: t("Efficacies"),
                          showChips: true,
                        }}
                        handleChanges={handleChangeMultyCrop}
                        mapTo="efficacies"
                      ></Selectinput>
                    </div>
                  </div>
                )} */}
              </div>
              {/* Tab 7 - End */}

              {/* Tab 8 - Start */}
              <div ref={scrollRefs[7]} className="pt-2">
                <h5 className="pt-3">{t("Sources")}</h5>
                <div className="row formRow">
                  <div className="col-6 col-sm-4" linkUrl={linkDetails.url}>
                    <label className="w-100  ">{t("Add source")}</label>
                    <input
                      type="text"
                      placeholder={t("Type to add link")}
                      value={linkDetails.url ? linkDetails.url : ""}
                      onChange={(e) => updateLinkDetails(e, "url")}
                      data-testid="linktxt"
                    ></input>
                  </div>
                  <div
                    className="col-6 col-sm-4"
                    linkTitle={linkDetails.displayTitle}
                  >
                    <label className="w-100  ">
                      {t("Add title (optional)")}
                    </label>
                    <input
                      type="text"
                      placeholder={t("Type to add an optional title")}
                      value={
                        linkDetails.displayTitle ? linkDetails.displayTitle : ""
                      }
                      onChange={(e) => updateLinkDetails(e, "displayTitle")}
                      data-testid="linktitle"
                    ></input>
                  </div>
                  <div className="col-12 col-sm-4 buttons ">
                    <button
                      className="sourceBtn"
                      onClick={addSource}
                      disabled={
                        !linkDetails.url || linkDetails.url === ""
                          ? true
                          : false
                      }
                      data-testid="addLinkBtn"
                    >
                      {t("Add Source")}
                    </button>
                  </div>
                </div>
                <div className="row formRow">
                  <div className="col-sm-6 col-12">
                    {formValues.recommendation[currentTab].links &&
                    Array.isArray(
                      formValues.recommendation[currentTab].links
                    ) &&
                    formValues.recommendation[currentTab].links.length ? (
                      formValues.recommendation[currentTab].links.map(
                        (link, linkIndx) => {
                          JSON.stringify(link);
                          return (
                            <div className="linkRow row formRow">
                              {updateLink.indx !== undefined &&
                              linkIndx === updateLink.indx ? (
                                <>
                                  <div className="col-sm-4 col-6 pb-2">
                                    <label className="w-100  ">
                                      {t("Source")}
                                    </label>
                                    <input
                                      type="text"
                                      value={updateLink.url}
                                      onChange={(e) =>
                                        handleChangeLinks(e.target.value, "url")
                                      }
                                      data-testid="editLinkLink"
                                    />
                                  </div>
                                  <div className="col-sm-4 col-6 pb-2">
                                    <label className="w-100  ">
                                      {t("Title")}
                                    </label>
                                    <input
                                      type="text"
                                      value={updateLink.displayTitle}
                                      onChange={(e) =>
                                        handleChangeLinks(
                                          e.target.value,
                                          "displayTitle"
                                        )
                                      }
                                      data-testid="editLinkTitle"
                                    />
                                  </div>
                                  <div className="col-sm-4 col-6 pb-2 text-right">
                                    <div className="linkActions">
                                      <FontAwesomeIcon
                                        icon={faCheck}
                                        className="mr-1 cursor-pointer"
                                        onClick={() => saveUpdateLink(linkIndx)}
                                        data-testid="updateEditLink"
                                      />
                                      <FontAwesomeIcon
                                        icon={faTimes}
                                        className="cursor-pointer"
                                        onClick={() => setUpdateLink({})}
                                        data-testid="cancelUpdateLink"
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <a href={link.url} target="blank">
                                    {link.displayTitle
                                      ? link.displayTitle
                                      : link.url}
                                  </a>
                                  <div className="editDiv">
                                    <FontAwesomeIcon
                                      icon={faPen}
                                      className="mr-2 cursor-pointer"
                                      onClick={() =>
                                        setUpdateLink({
                                          url: link.url,
                                          displayTitle: link.displayTitle,
                                          indx: linkIndx,
                                        })
                                      }
                                      data-testid="editLink"
                                    />
                                    <FontAwesomeIcon
                                      icon={faTrash}
                                      className="cursor-pointer"
                                      onClick={() => deleteUpdateLink(linkIndx)}
                                      data-testid="removeLink"
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          );
                        }
                      )
                    ) : (
                      <span>{t("No sources added")}</span>
                    )}
                  </div>
                </div>
                <div className="row formRow">
                  <div className="col-sm-6 col-12">
                    <label className="w-100  ">{t("Comments")}</label>
                    <textarea
                      data-testid="recComment"
                      value={formValues.recommendation[currentTab].comments}
                      onChange={(e) => handleChange(e.target.value, "comments")}
                    ></textarea>
                  </div>
                </div>
              </div>
              {/* Tab 8 - End */}

              {/* Tab 9 - Start */}
              {/* <div ref={scrollRefs[8]} className="pt-2">
                <h5 className="pt-3">
                  {t("Recommendation is part of spraying program(s):")}
                </h5>
                <div className="row formRow">
                  <div className="col-12">
                    <label className="w-100  ">{t("No data available")}</label>
                  </div>
                </div>
              </div> */}
              {/* Tab 9 - End */}
            </>
          </div>
        )}
      </div>
      <div>
        <div className="buttons bottomBtnWrapper">
          <div>
            {formValues.recommendation.length > 0 &&
              formValues.recommendation[currentTab].status === "In Editing" &&
              formValues.recommendation[currentTab].id && (
                <button
                  className="greenBtn"
                  data-testid="regSendToReview"
                  onClick={(e) => statusChange(e, "IN_REVIEW")}
                >
                  {t("Send to Review")}
                </button>
              )}
            {formValues.recommendation.length > 0 &&
              formValues.recommendation[currentTab].status === "In Review" && (
                <>
                  <button
                    className="greenBtn"
                    onClick={(e) => statusChange(e, "APPROVED")}
                  >
                    {t("Approve")}
                  </button>
                  <button
                    className="greenBtn"
                    onClick={(e) => statusChange(e, "IN_EDITING")}
                  >
                    {t("Reject")}
                  </button>
                </>
              )}
            <button
              data-testid={"share-btn"}
              onClick={(e) => manageShareModalContent(e)}
              disabled={!formValues.product.id}
            >
              <FontAwesomeIcon icon={faShareNodes} />
              &nbsp; {t("Share")}...
            </button>
          </div>
          <div className="float-left">
            {pristineValues.recommendation.length !== 0 && (
              <button
                data-testid="saveRegBtn"
                className="greenBtn"
                disabled={pristineValues.recommendation[currentTab]}
                onClick={(e) => saveCrop(e)}
              >
                {t("Save")}
              </button>
            )}
          </div>
        </div>
      </div>
      <ModalReusable config={modalConfig}></ModalReusable>
    </>
  );
};
export default RecommendationsTab;
