
export const formConfig = {
    formElements:[
                    {
                        inputType: 'singleselect',
                        inputLabel: 'Active Ingredients (AI)',
                        mapTo: 'ingredient',
                        endpoint:"ingredient", valueKey:"", labelKey:"name",
                        width:'100%'
                    },
                    {
                        inputType: 'text',
                        inputLabel: 'AI Relative Amount',
                        mapTo: 'amount'
                    },
                    {
                        inputType: 'singleselect',
                        inputLabel: 'Relative Unit of Measurement',
                        mapTo: 'uom',
                        endpoint:"uom", valueKey:"", labelKey:"name",
                        width:'100%'
                    },
                    {
                        inputType: 'text',
                        inputLabel: 'AI Absolute Amount',
                        mapTo: 'amountAbs'
                    },
                    {
                        inputType: 'singleselect',
                        inputLabel: 'Absolute Unit of Measurement',
                        mapTo: 'uomAbs',
                        endpoint:"uom", valueKey:"", labelKey:"name",
                        width:'100%'
                    }

                ],
    stateKey : 'activeIngredients', //key name that saves these data in useState 
    addMoreText: 'Add AI',
    addMoreDefaults : {  
        sequenceNumber:0
    },
    specificWidth:['calc( 100% - 720px)','175px', '175px', '175px','195px']
}