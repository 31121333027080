import { Outlet, Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Language from "../../components/Language/Language";
import { getLang } from "../../services/AxiosInterceptor";
import { useEffect, useState } from "react";
import { defaultLanguage } from "../../config/LanguageConfig";
import { useTranslation } from "react-i18next";
const Layout = () => {
    const [cLang, setClang] = useState(defaultLanguage)
    useEffect(()=>{
      getLang().subscribe((lang) =>{
        setClang(lang)
      })
    })
    const location = useLocation(); 
    const { t } = useTranslation();
  return (
    <>
    <div className={"topHeaderOuter"+(location.pathname!=='/'?" innerWrapper":"")}>
    <div className="topHeader">
    <Language currentLang = {cLang}></Language>
     <div className="versionWrapper"><span>{t("Version")} 2.0.0</span></div>
     </div>
     </div>
   { location.pathname!=='/' && location.pathname.indexOf('product') === -1 && <>
     
      <nav>
        <ul>
          <li>
            <Link to="/home">{ t("Database")}</Link>
          </li>
          {/*<li>
            <Link to="/masterdata">Master data</Link>
  </li> */}
        </ul>
      </nav>
      <div className="subNav">
        <ul>
          <li><Link to="/home">{ t("Product Database Overview")}</Link></li>
        </ul>
      </div>
      </>
    }
      <div className="p-3 pt-0 pb-0"><Outlet /></div>
    
   
   </>
  )
};

export default Layout;