import axios from 'axios'
import { BehaviorSubject } from 'rxjs';
import { mockEndpoints } from '../config/MockEndpoints';
import {serviceEndpoints } from '../config/ServiceEndpoints'; 
import { defaultLanguage } from '../config/LanguageConfig'; 

let mockingEnabled = false; 
const mocks = {}
let loaderCount = 0;
const loader = new BehaviorSubject(false);
let loginData;

const currentLang = new BehaviorSubject(
 // i18n.language ||
   //   (typeof window !== "undefined" && window.localStorage.i18nextLng) ||
  defaultLanguage);
  export function getLang(){
    return currentLang.asObservable();
  }
  export function setLang(lang){
    currentLang.next(lang)
  }
export function setLoginData(username,password){
  loginData = btoa(username+":"+password); 
}
export function getLoaderStatus(){
  return loader.asObservable();
}
 
export  function addMock() {
  //console.log('here',global.REACT_APP_MOCKUP_ENDPOINTS.split(',') )
  let mockApiEndpoints = process.env.REACT_APP_MOCKUP_ENDPOINTS?process.env.REACT_APP_MOCKUP_ENDPOINTS.split(','):[]; 
   /* istanbul ignore next */
  mockApiEndpoints.forEach((item)=>{
    item = item.trim();
    let url = process.env.REACT_APP_API_BASEURL +serviceEndpoints[item];
    mocks[url] = item;
  });  
}

export function enableMocking(state) {
  mockingEnabled = state
}

const isUrlMocked = url => url in mocks
 /* istanbul ignore next */
const getMockError = async(config) => {
  const mockError = new Error();   
    let mockUrl =  mockEndpoints[mocks[config.url]];
    let res = await fetch(process.env.PUBLIC_URL+'/'+mockUrl);
    let data = await  res.json();  
    mockError.mockData = data
  mockError.config = config
  return Promise.reject(mockError)
}

const isMockError = error => Boolean(error.mockData)
 /* istanbul ignore next */
const getMockResponse = mockError => {
  const {mockData, config} = mockError
  // Handle mocked error (any non-2xx status code) 
  if (mockData.status && String(mockData.status)[0] !== '2') {
    const err = new Error(mockData.message || 'mock error')
    err.code = mockData.status
    return Promise.reject(err)
  } 
  // Handle mocked success
  return Promise.resolve(Object.assign({
    data: mockData,
    status: 200,
    statusText: 'OK',
    headers: {},
    config,
    isMock: true
  }, mockData))
}

// Add a request interceptor
axios.interceptors.request.use(config => {
  loaderCount++;
  if(loaderCount === 1){
    loader.next(true);
  }
  let customHead = config.headers?config.headers:{}  ;
  
  customHead = {...customHead,...{
    "Authorization" : "Basic " + loginData
  }}
  config.headers = customHead;
  if (mockingEnabled){ 
    let actualUrl =   config.url;
      config.url = actualUrl.split('?')[0]; 
       /* istanbul ignore next */
    if(isUrlMocked(config.url)) {
    console.log('axios mocking ' + config.url); 
    return getMockError(config)
  } else{
    config.url = actualUrl;
  }
  }
  return config
}, error => {
  console.log(error);
  Promise.reject(error)})

// Add a response interceptor
 /* istanbul ignore next */
axios.interceptors.response.use(response =>{ 
  loaderCount--;
  if(loaderCount === 0){
   loader.next(false);
  }
  return response;
}, error => {
  loaderCount--;
  if(loaderCount === 0){
    loader.next(false);
  }
   
  if (isMockError(error)) {  
    return getMockResponse(error)
  }
  if((error.response && error.response.status === 401) && error.config.url.indexOf('/login')===-1 )
 { window.location.href="/"; 
  return Promise.resolve({data:[]}) }
  else{
    return Promise.reject(error)
  }//Promise.reject(error)
})