import React, { useContext, useEffect, useState, useRef } from "react";
import FormValuesContext from "../FormValues-context";
import PristineValuesContext from "../PristineValues-context";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCopy, faPlus, faRemove, faTrash, faShareNodes, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import SelectinputSingle from "../../../components/Selectinput/SelectInputSingle";
import Selectinput from "../../../components/Selectinput/Selectinput";
import { useTranslation } from "react-i18next"; 
import ModalReusable from "../../../components/ModalReusable/ModalReusable";
import ShareModal from "../../../components/ModalTemplates/ShareModal/ShareModal";
import axios from 'axios';
import { serviceEndpoints } from '../../../config/ServiceEndpoints';
import AddMoreReusable from "../../../components/AddMoreReusable/AddMoreReusable";
import {formConfig as appTimingFormConfig } from "../../../config/AddMoreConfig/AppTimingConfig";
import {formConfig as appVolumeFormConfig } from "../../../config/AddMoreConfig/AppVolumeConfig";
import DatePicker from 'react-date-picker';
import { useParams } from 'react-router';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css'; 

const RegistrationTab = (props) => {
    const { formValues, setFormValues } = useContext(FormValuesContext);
    const { pristineValues, setPristineValues } = useContext(PristineValuesContext);
    const[modalConfig, setModalConfig] = useState({show:false});
    const [multiFormFlag, setMultiFormFlag] = useState(false); 
    const [selectedCrop, setSelectedCrop] = useState({crops:[]});
    const [currentTab, setCurrentTab] = useState(0);
    const [scrollNow, setScrollNow] = useState(undefined);
    const [cropCliked, setCropCliked] = useState(false);
    const [regExpiryDate, setRegExpiryDate] = useState(undefined);
    const scrollEndRef = React.createRef();
    const scrollTopRef = React.createRef();
    const [pristineList, setPristineList] =  useState([]); 
    //const [reviewBtn, setReviewBtn] = useState(false);
    const [multySelectItemList, setMultySelectItemList] = useState(
        {crops:[], traits:[], usageTypes:[], taskTypes:[], taskMethods:[], additives: []});
    const {t} = useTranslation();
    const scrollRefs = [useRef(),useRef(), useRef(),useRef(),useRef(), useRef()] ;
    const menuRef = [useRef(),useRef(), useRef(),useRef(),useRef(), useRef()] ;
    const tabRef = useRef();
    const regMenuRef = useRef();
    let cropAdded = false; //checks if clicked the buttons to add crops or to add multiple crops
    let {id} = useParams(); 
    useEffect(()=>{ 
       if(multiFormFlag){
        scrollTopRef.current.scrollIntoView({ behavior: 'smooth' })
       }
       if(!multiFormFlag && scrollEndRef.current){
        if(!scrollEndRef.current.classList.contains('cropClicked'))
        scrollEndRef.current.scrollIntoView({ behavior: 'smooth' })
       }
       if(cropCliked)
       setCropCliked(false);
       
     //  console.log('id is ',id);
     //  console.log(pristineList[0])
       // eslint-disable-next-line react-hooks/exhaustive-deps
            },[multiFormFlag, currentTab])
    useEffect(()=>{ 
        if(id && props.firstRegVisit && formValues.registration.length>0){ 
            const updateNow={...formValues}
            updateNow.product.id = id;
            let pList=[]
            formValues.registration.map((reg,indx)=>{ 
                if(reg.id)
                pList.push(true); else{
                    pList.push(false)
                }
                console.log(reg)
                if(reg.applicationTimings){
                    reg.applicationTimings.map((time,indx2)=>{
                        if(time.starts){
                            let nData=[]
                            time.starts.map(s=>{
                                nData.push({item: s, label: s.name, value: s.id});
                                return s
                            }) 
                        updateNow.registration[indx].applicationTimings[indx2].starts = nData;
                        }

                        if(time.ends){
                            let nData=[]
                            time.ends.map(s=>{
                                nData.push({item: s, label: s.name, value: s.id});
                                return s;
                            }) 
                        updateNow.registration[indx].applicationTimings[indx2].ends = nData;
                        }
                        return reg;
                    })
                }
                return reg;
            })  
                    setFormValues({...updateNow})
            setPristineList(pList);
            let pristineNow = {...pristineValues};
            pristineNow.registration = pList;
            setPristineValues({...pristineNow});
            manageTabSelect(0);
            props.setfirstRegVisit(false);
            props.setRegVisit(false);
           } 
       
          // presetFormFields(0);
       // eslint-disable-next-line react-hooks/exhaustive-deps    
    },[formValues,currentTab])
   useEffect(()=>{ 
        if(!props.firstRegVisit || id===undefined ){ 
            presetFormFields(0);
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.regVisit])
    useEffect(()=>{
        const updateNow = {...formValues};
        if(regExpiryDate && updateNow.registration[currentTab] ) { 
        updateNow.registration[currentTab]['regExpiryDate'] = regExpiryDate.toISOString();
        updateNow.formPristine = false;
        setFormValues({...updateNow});
        //setPristine(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[regExpiryDate])
    const addItemToCrops = ()=>{ 
        const updateNow = {...selectedCrop}; 
        updateNow.crops.push({});
        setSelectedCrop({...updateNow});
        cropAdded = true;
    }
    const handleChangeCrops = (event)=>{ 
        const val =  event.target.value;
        const indx = event.target.indx;
        const updateNow = {...selectedCrop}; 
            updateNow.crops[indx]= val; 
            setSelectedCrop({...updateNow});
    }
    const handleChangeMultyCrop =(val, mapTo,)=>{ 
        const updateNow = {...formValues}; 
        let cropList = {...multySelectItemList}
        cropList[mapTo] = val; 
        setMultySelectItemList(cropList);
        updateNow.registration[currentTab][mapTo] = val.map(item=>item.item);
        updateNow.formPristine = false;  
        setFormValues({...updateNow});
        let updatePristine = pristineList;
         updatePristine[currentTab]= false;
         setPristineList(updatePristine);
         let pristineNow = {...pristineValues};
            pristineNow.registration[currentTab]= false; 
            setPristineValues({...pristineNow});
      }
      const handleChangeForArray = (val,indx,keyName, mapTo)=>{ 
        let updateNow = {...formValues};
        if(mapTo)
        updateNow.registration[currentTab][keyName][indx][mapTo] = val;
        else
        updateNow.registration[currentTab][keyName][indx] = val; 
        updateNow.formPristine = false;
        let updatePristine = pristineList;
        updatePristine[currentTab] = false;
        setPristineList(updatePristine);
        let pristineNow = {...pristineValues};
        pristineNow.registration[currentTab]= false;
        setPristineValues({...pristineNow});
        setFormValues({...updateNow});
        //setPristine(false);
    }
    const handleChange =(val, mapTo)=>{ 
        const updateNow = {...formValues}; 
        updateNow.registration[currentTab][mapTo] = val;
        updateNow.formPristine = false;
        let updatePristine = pristineList;
        updatePristine[currentTab] = false;
        setPristineList(updatePristine);
        let pristineNow = {...pristineValues};
        pristineNow.registration[currentTab]= false;
        setPristineValues({...pristineNow});
        setFormValues({...updateNow});
        //setPristine(false);
      }
    const deleteSelectedCrop = (indx)=>{
        const updateNow = {...selectedCrop};
        updateNow.crops.splice(indx, 1);
        setSelectedCrop({...updateNow}); 
    }
    const addCrops = () =>{
        const updateNow = {...formValues}; 
        const crops = {...selectedCrop};
        const pushableCrops = [];
        const pushableMultySelect = [];
        crops.crops.forEach(elm => {
            if(elm.name){
                pushableCrops.push(elm);
                pushableMultySelect.push({value:elm.id,label:elm.name, item:elm})
            }
        });
      //  if(pushableCrops.length>0){
            let cropList = {...multySelectItemList};
            cropList['crops'] = pushableMultySelect;
            setMultySelectItemList(cropList);
        updateNow.registration.push({"crops":pushableCrops,"traits": [],
        "usageTypes": [],
        "applicationPeriods": [],
        "taskTypes": [],
        "taskMethods": [],
        "treatmentTypes": [],
        "applicationTimings": [ 
        ],
        "waterApplications": [
            {}
        ],
        "notForUseIn": [
            {
                "subRegions": []
            }
        ],
        "comments": ""});
        setFormValues({...updateNow});
        setSelectedCrop({crops:[{},{}]}); 
         setCurrentTab(formValues.registration.length-1);
         presetFormFields(formValues.registration.length-1);
         setRegExpiryDate(undefined);
         let updatePristine = pristineList;
         updatePristine.push(false);
         setPristineList(updatePristine);
         let pristineNow = {...pristineValues};
         pristineNow.registration.push(false);
         setPristineValues({...pristineNow});
      //  } 
        setMultiFormFlag(false);
          
    }
  const copyCrop = (indx,e)=>{ 
    e.preventDefault();
    e.stopPropagation();
    const updateNow = {...formValues}; 
    let copyItem  = {...updateNow.registration[indx]};
    delete copyItem.id;
    copyItem.status = undefined;
    updateNow.registration.push(copyItem);
    if(!updateNow.tabStatus)  updateNow.tabStatus={};  
    updateNow.tabStatus.registrationsStatus = "In Editing";
    updateNow.product.registrationsStatus = "In Editing"; 
    setFormValues({...updateNow});
    setCurrentTab(formValues.registration.length-1);
    if(updateNow.registration[indx].regExpiryDate)
    setRegExpiryDate(new Date(updateNow.registration[indx].regExpiryDate));
    let updatePristine = pristineList;
         updatePristine.push(false);
         setPristineList(updatePristine);
         let pristineNow = {...pristineValues};
         pristineNow.registration.push(false);
         setPristineValues({...pristineNow});
    setScrollNow(formValues.registration.length-1);
  }
    const manageRegApiCatch = ()=>{
        let updateNow = {...formValues}; 
        updateNow.formError = true;
        updateNow.formSuccess = false;
        setFormValues({...updateNow});
        setTimeout(()=>{
            let updateNow = {...formValues}; 
            updateNow.formError = false;
            setFormValues({...updateNow}); 
        }, 5000)
    }
    const manageRegApiSuccess = (res, status, fromStatusChange)=>{ 
        let updateNow = {...formValues};  
        let data = res.data;
        console.log(currentTab);
        console.log(res)
        if(data.applicationTimings){
            data.applicationTimings.map((time,indx2)=>{
                if(time.starts){
                    let nData=[]
                    time.starts.map(s=>{
                        nData.push({item: s, label: s.name, value: s.id});
                        return s
                    }) 
                data.applicationTimings[indx2].starts = nData;
                }

                if(time.ends){
                    let nData=[]
                    time.ends.map(s=>{
                        nData.push({item: s, label: s.name, value: s.id});
                        return s;
                    }) 
                data.applicationTimings[indx2].ends = nData;
                }
                return data;
            })
        }
        updateNow.registration[currentTab] = data;  
        updateNow.formError = false;
        updateNow.formSuccess = true;
        if(!updateNow.tabStatus) 
         { updateNow.tabStatus={};}

        if(status === "IN_EDITING" || status==="In Editing"){
            let r= "In Editing";
            updateNow.registration.forEach(elm=>{
                if(elm.status === undefined){
                    r= undefined; 
                }
            })
        updateNow.tabStatus.registrationsStatus = r;
        updateNow.product.registrationsStatus = r;
        } else if(status === "IN_REVIEW"){
            let r= "In Review";
            updateNow.registration.forEach(elm=>{
                if(elm.status === undefined){
                    r= undefined; 
                }
                if(elm.status==="In Editing"){
                  r = "In Editing";
                }
            });
            updateNow.tabStatus.registrationsStatus = r;
             updateNow.product.registrationsStatus = r;
        } else if(status === "APPROVED"){
            let r = [];
            updateNow.registration.forEach(elm=>{
                r.push(elm.status)
            });
            if(r.indexOf('In Editing')!==-1){
                updateNow.tabStatus.registrationsStatus = "In Editing";
                updateNow.product.registrationsStatus = "In Editing";
            } else if(r.indexOf('In Review')!==-1){
                updateNow.tabStatus.registrationsStatus = "In Review";
                updateNow.product.registrationsStatus = "In Review";
            } else{
                updateNow.tabStatus.registrationsStatus = "Approved";
                updateNow.product.registrationsStatus = "Approved";
            }
        }
       // updateNow.formPristine = true;
        setFormValues({...updateNow});
       // setPristine(true);
       //setReviewBtn(true);
       props.setSavedMessageFrom('registration');
       if(!fromStatusChange){
       let updatePristine = pristineList;
       updatePristine[currentTab] = true;
       setPristineList(updatePristine);
       let pristineNow = {...pristineValues};
       pristineNow.registration[currentTab]= true;
       setPristineValues({...pristineNow});
       }
       console.log(formValues)
        setTimeout(()=>{
            updateNow.formSuccess = false;
            setFormValues({...updateNow}); 
        }, 5000)
    }
    const manageForm = (status, multi)=>{
        let crops=multi?[{},{}]:[{}];
        setMultiFormFlag(status); setSelectedCrop({crops:crops}); 
    }
    const saveCrop = (e)=>{
        e.preventDefault();
        console.log(formValues.registration[currentTab]);
        console.log(currentTab);
        const updateNow = {...formValues}; 
        const payload =  JSON.parse(JSON.stringify(updateNow.registration[currentTab]));
        if(formValues.registration[currentTab].applicationTimings.length>0){
            payload.applicationTimings.map((itm,indx)=>{
                if(itm && itm.ends){
                itm.ends.map((end,indx2)=>{ 
                    payload.applicationTimings[indx]['ends'][indx2]=end.item;
                    return end;
                });
            }
            if(itm && itm.starts){
                itm.starts.map((start,indx2)=>{ 
                    payload.applicationTimings[indx]['starts'][indx2]=start.item;
                    return start;
                });
            }
                return itm
                })
        } 
       if(formValues.product.id) //commented for test case, todo---not saved product tab then save reg tab
        {
            if(!updateNow.registration[currentTab].id){
                axios.post(process.env.REACT_APP_API_BASEURL+serviceEndpoints['products']+'/'+formValues.product.id+'/registrations',payload).then((res)=>{  
                    manageRegApiSuccess(res, "In Editing");
                }).catch(e=>{
                    manageRegApiCatch();
                }) 
            } else{
                axios.put(process.env.REACT_APP_API_BASEURL+serviceEndpoints['products']+'/'+formValues.product.id+'/registrations',payload).then((res)=>{
                    manageRegApiSuccess(res,  "In Editing");
                }).catch(e=>{
                    manageRegApiCatch();
                }) 
            }
        }
    }
    const statusChange = (e, status)=>{ 
        e.preventDefault()
        let payload = {"statusChange":{"status":status,"statusChangeComment":""}}; 
        axios.put(process.env.REACT_APP_API_BASEURL+serviceEndpoints['products']+'/'+formValues.product.id+'/registrations/'+formValues.registration[currentTab].id+'/status',payload).then((res)=>{
            manageRegApiSuccess(res, status, true);
        }).catch(e=>{  
            manageRegApiCatch();
        })
    }
    const manageModal = (config,status=false, inner='')=>{ 
        const modalCon = {...config,...{show:status, modalInner: inner}}
        setModalConfig(modalCon);
      } 
    const shareModalConfig = {
        show:false,
        largeWindow: true,
        modalHeader:t('Share Items'), 
        closeButtonHandler:manageModal,
        modalButtons:[  ],           
        }
    const manageShareModalContent = (e)=>{ 
        e.preventDefault()
        manageModal(shareModalConfig, true,<ShareModal selectedRows={[formValues.product.id]} ></ShareModal> )
      }
      const deleteReg = (indx)=>{
        let updateNow = {...formValues};  
        updateNow.registration = [
            ...updateNow.registration.slice(0,indx),
            ...updateNow.registration.slice(indx+1)
          ]


        let updatePristine = pristineList;
        updatePristine = [
            ...updatePristine.slice(0,indx),
            ...updatePristine.slice(indx+1)
          ]
        if(formValues.registration[indx].id){
          axios.delete(process.env.REACT_APP_API_BASEURL+serviceEndpoints['products']+'/'+formValues.product.id+'/registrations/'+formValues.registration[indx].id).then((res)=>{
          //  manageRegApiSuccess(res, 'In Editing', true);
        }).catch(e=>{ 
          //  manageRegApiCatch();
        })}
        setFormValues({...updateNow});
        setPristineList(updatePristine);
        let pristineNow = {...pristineValues};
        pristineNow.registration = [
            ...pristineNow.registration.slice(0,indx),
            ...pristineNow.registration.slice(indx+1)
          ]
        setPristineValues({...pristineNow});
          setCurrentTab(0);
          manageTabSelect(0);
          
          manageModal(deleteModalConfig, false,'');
    }
    const deleteModalConfig = {
        show:false,
        modalHeader:t('Approve Deletion of Registration'),  
        closeButtonHandler:manageModal,
        modalButtons:[
                      {label:t('Cancel'), handler:manageModal},
                      {label:t('Delete Registration'), classNames:"greenBtn",
                      handler:deleteReg}],           
                      }
    const deleteRegModalContent = (e, tabIndx) =>{
        e.preventDefault();
        deleteModalConfig.modalButtons[1].params = tabIndx;
            manageModal(deleteModalConfig, true ,t('Are you sure you want to delete this Registration?'))
    }
    
      const scrollSmoothHandler = (index)=>{
          scrollRefs[index].current.scrollIntoView({ behavior: "smooth" });
      };
      const addClassAttribute = (addClass) =>{
        const removeClass = Array.from({ length: scrollRefs.length }, (v, i) => i);
        for(let item in removeClass)
            menuRef[item].current.classList.remove('active');
        menuRef[addClass].current.classList.add('active')
      }
      const setMenuItem=(e)=>{ //183+456 635
        let scrollHight = tabRef.current.scrollTop;
        if(scrollHight>60){
            regMenuRef.current.classList.add('positionFixed');
        } else{
            regMenuRef.current.classList.remove('positionFixed')
        }
        let elementHeightSum = 0;
        for (let i = 0; i < scrollRefs.length; i++) {
        elementHeightSum += scrollRefs[i].current.offsetHeight;
        if (e.target.scrollTop < elementHeightSum) {
            addClassAttribute(i);
            break;
        }
        } 
      }
      const addMore = (keyName, defaults)=>{
        let updateNow = {...formValues}; 
        updateNow.registration[currentTab][keyName].push({...defaults});
        let updatePristine = pristineList;
        updatePristine[currentTab] = false;
        setPristineList(updatePristine);
        let pristineNow = {...pristineValues};
        pristineNow.registration[currentTab] = false;
        setPristineValues({...pristineNow});
        setFormValues({...formValues})
      }
      const deleteAddMore  =(params)=>{ 
        let updateNow = {...formValues}; 
        delete(updateNow.registration[currentTab][params.keyname][[params.indx]]);
        let updatePristine = pristineList;
       updatePristine[currentTab] = false;
       setPristineList(updatePristine);
       let pristineNow = {...pristineValues};
       pristineNow.registration[currentTab] = false;
       setPristineValues({...pristineNow});
       setFormValues({...updateNow});
        setModalConfig({...modalConfig,...{show:false}})
      }
      const manageTabSelect = (itemIndx)=>{ 
        setCropCliked(true);  
        setCurrentTab(itemIndx);
        presetFormFields(itemIndx);
      }
      const presetFormFields = (itemIndx)=>{
        let additives = [];
        let multySelectItems = {};
        if(formValues.registration[itemIndx]){
        if(formValues.registration[itemIndx].additives)
           additives = createItemList(formValues.registration[itemIndx].additives) 
        multySelectItems = {
           crops: createItemList(formValues.registration[itemIndx].crops),
           traits: createItemList(formValues.registration[itemIndx].traits) , 
           usageTypes: createItemList(formValues.registration[itemIndx].usageTypes), 
           taskTypes: createItemList(formValues.registration[itemIndx].taskTypes),  
           taskMethods:  formValues.registration[itemIndx].taskMethods?createItemList(formValues.registration[itemIndx].taskMethods):[],
           additives: additives
       }
       if(formValues.registration[itemIndx].regExpiryDate){
       setRegExpiryDate(new Date(formValues.registration[itemIndx].regExpiryDate)); 
       }
       else
       setRegExpiryDate(undefined);}
       setMultySelectItemList(multySelectItems); 
      }
      const createItemList=(data)=>{
        let elm = [];
        if(data)
        data.forEach((item)=>{
            elm.push({
                value: item.id,
                label: item.name,
                item:item
            });
        })
        return elm;
      }
      const addGeneral = ()=>{ 
        manageForm(false,true);
        addCrops();
    }
    return <>
    <div>
        <div className={"sideBar3 "+(formValues.registration.length ===0 ?" noVisibleScroll":"") }>
            <Scrollbars style={{ width: '310px', height: 'inherit' }} className={"customScrolld"}> 
        <div className={"p-3 pb-0 headerWrapper "+(formValues.registration.length !==0 ?"position-fixed":"")}>
            <h4 className="mt-0 ">{t('Registration')}</h4>
            <div>
            <button data-testid="addCrop" className="greenBtn" onClick={()=>manageForm(true,false)} disabled={multiFormFlag?true:false}>
            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
            {t('Add crop')}</button>
            {cropAdded === false && <p className="infoP mb-0">{t('Click here to add one crop to the registration info.')}</p>}
           </div>
           {<div>
           <button data-testid="addRegGeneral" className="mt-3 " onClick={()=>addGeneral()} disabled={multiFormFlag?true:false}> 
            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
            {t('Add crop (general)')}</button>
           </div> }
            {/*<div>
           <button className="mt-3 " onClick={()=>manageForm(true,true)} disabled={multiFormFlag?true:false}> 
            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
            {t('Add multiple crops')}</button>
            {cropAdded === false && <p className="infoP mb-0">Click here to add multiple crops with identical attributes.</p>}
           </div> */}
        </div>
        <div ref={scrollTopRef} className={"p-3 "+(formValues.registration.length !==0 ?"moveToBottom":"")+(multiFormFlag?" grayForm":"")+(cropAdded === false?" morePadding":"")}>
            {multiFormFlag && <div className="mb-2"> 
           {  selectedCrop.crops.map((item,indx) =><div className="d-flex pb-3">
           
            <SelectinputSingle config={{  endpoint:"crop", valueKey:"", labelKey:"name",  labelKeySec:"eppoCode",
                            singleSelect:true, width:'220px'}} value={selectedCrop.crops[indx]}  mapTo='crop' handleChanges={handleChangeCrops} 
                            itrateValues ={{indx:indx, mapTo:'crop', stateKey:'crops'}}
                            disabledOptions = {selectedCrop.crops}></SelectinputSingle>
            <FontAwesomeIcon data-testid="cropAddDelete" className="deleteSelectedCrop" icon={faTrash} onClick={()=>deleteSelectedCrop(indx)}/>
            </div>  )} 
             <button data-testid="addCropItems" className="grayBtn" onClick={addItemToCrops}>{t('Add Crop')}</button>
                <FontAwesomeIcon data-testid="addCropFormValues" icon={faCheck} onClick={()=>addCrops()} className="greenBtn p-2 float-right"/>  
                <FontAwesomeIcon data-testid="addCropRemoveFormValues" icon={faRemove} onClick={()=>manageForm(false, true)} className="grayBtn p-2 float-right"/>
                </div>}
                {
                     formValues.registration.length>0 && formValues.registration.map((item, itemIndx)=>
                        <div data-testid="cropMenuElm" className={"cropsMenu"+(currentTab === itemIndx?' active':'')} onClick={()=>{manageTabSelect(itemIndx)}}> 
                            <span className={"float-left cropsSpan"+(pristineValues.registration[itemIndx]!==false?"":" notSaved")+
                            (item.status==="In Review"?" reviewed":item.status==="Approved"?" approved":"")}>
                            {(itemIndx+1).toString().length>=3? itemIndx+1 : 
                            ((itemIndx+1).toString().length===2? '0'+(itemIndx+1) :'00'+(itemIndx+1))}</span>
                            <div className="elepsisDiv">
                            {item.crops && item.crops.length===0 ? <>{t('General')}</>
                            :
                           item.crops&& item.crops.map((crop,indx) =>
                           <>{crop.name} {indx<item.crops.length-1 && <>,&nbsp;</>}
                           </>
                           )} 
                            <span className="copySpan delete"  onClick={(e)=>{deleteRegModalContent(e, itemIndx)}}><FontAwesomeIcon icon={faTrash} /></span>
                           <span className="copySpan" data-testid="copyCrop" onClick={(e)=>copyCrop(itemIndx,e)}><FontAwesomeIcon icon={faCopy} /> {t('Copy')}</span>
                           </div>  {item.crops && item.crops.length!==0 && <span className="float-left">{item.crops.length} {t('Items')}</span>}
                        </div>)
                }
        </div>
                <div ref={scrollEndRef} className={("scrolled"+scrollNow)+(cropCliked?' cropClicked':'')} ></div>
           </Scrollbars>
        </div> 
        <div className="tabs" data-testid="scrollTab" currentTab={currentTab} ref={tabRef} onScroll={(e)=>setMenuItem(e)}>
        
        <div>
        <h3 className="innerHead">{t("Registration")}
        {formValues.registration.length>0 && <button className="deleteReg" data-testid="regDelete" onClick={(e)=>{deleteRegModalContent(e, currentTab)}}>
            <FontAwesomeIcon icon={faTrash} /> {t('Delete')}
            </button>}</h3> 
        {formValues.registration[currentTab] &&<>
        <div>
       {/* tab content {currentTab} crops index selected */}
        <p>{t('Official boundaries and restrictions for product usage on a crop level. The main data source is the official label.')}</p>

        <ul className="regMenu" ref={regMenuRef}>
            <li data-testid="generalInfo" className="active" ref={menuRef[0]} onClick={()=>scrollSmoothHandler(0)}>{t('General Information')}</li>
            <li data-testid="applicationTime" ref={menuRef[1]} onClick={()=>scrollSmoothHandler(1)}>{t('Application Timing')}</li>
            <li data-testid="prodApplicationRate" ref={menuRef[2]} onClick={()=>scrollSmoothHandler(2)}>{t('Product Application Rate')}</li>
            <li data-testid="applicationVolume" ref={menuRef[3]} onClick={()=>scrollSmoothHandler(3)}>{t('Application Volume')}</li>
            <li data-testid="restrictions" ref={menuRef[4]} onClick={()=>scrollSmoothHandler(4)}>{t('Restrictions')}</li>
            <li data-testid="additionalInfo" ref={menuRef[5]} onClick={()=>scrollSmoothHandler(5)}>{t('Additional Information')}</li>
        </ul>
        </div>
        <div  ref={scrollRefs[0]}   >
            <h5  >{t('General Information')}</h5>
            <div className="productRows  mt-2">
                <div className="row formRow">
                    <div className="col-sm-6 col-12">
                        <label className="w-100  ">{t("Functional ID")} 
                            <div className="position-relative infoDiv">
                            <span>{t('Predefined by the system for tracking (not adjustable).')}</span>
                            <FontAwesomeIcon icon={faCircleInfo} ></FontAwesomeIcon></div>
                        </label>
                        <input type="text"  value={formValues.registration[currentTab].functionalId?formValues.registration[currentTab].functionalId:''}   readOnly={true}  className="w-100" /> 
                    </div>
                </div><div className="row formRow">
                    <div className="col-sm-6 col-12"> 
                        <Selectinput  value={multySelectItemList.crops} config={{endpoint:"crop", valueKey:"", labelKey:"name" , labelTxt:t('Crop(s)'), showChips:true }} handleChanges={handleChangeMultyCrop} mapTo='crops' ></Selectinput>
                    </div>
                    <div className="col-sm-6 col-12"> 
                        <Selectinput value={multySelectItemList.traits} config={{endpoint:"trait", valueKey:"", labelKey:"name" , labelTxt:t('Required Traits'), showChips:true }} handleChanges={handleChangeMultyCrop} mapTo='traits' ></Selectinput>
                        
                    </div>
                </div> 
                <div className="row formRow">
                    <div className="col-sm-6 col-12"> 
                    <Selectinput value={multySelectItemList.usageTypes} config={{endpoint:"cropUsageType", valueKey:"", labelKey:"name" , labelTxt:t('Crop Usage Type'), showChips:true }} handleChanges={handleChangeMultyCrop} mapTo='usageTypes' ></Selectinput>
                   </div>
                </div>
                <div className="row formRow">
                    <div className="col-sm-6 col-12"> 
                    <Selectinput value={multySelectItemList.taskTypes} config={{endpoint:"taskType", valueKey:"", labelKey:"name" , labelTxt:t('Task Type'), showChips:true }} handleChanges={handleChangeMultyCrop} mapTo='taskTypes' ></Selectinput>
                   </div> 
                   <div className="col-sm-6 col-12"> 
                    <Selectinput value={multySelectItemList.taskMethods} config={{endpoint:"taskMethod", valueKey:"", labelKey:"name" , labelTxt:t('Task Method'), showChips:true }} handleChanges={handleChangeMultyCrop} mapTo='taskMethods' ></Selectinput>
                   </div>
                </div>
                <div className="row formRow">
                    <div className="col-sm-6 col-12"> 
                    <Selectinput value={multySelectItemList.additives} config={{endpoint:"additive", valueKey:"", labelKey:"name" , labelTxt:t('Additives'), showChips:true }} handleChanges={handleChangeMultyCrop} mapTo='additives' >
                            <div className="position-relative infoDiv">
                            <span>{t('Roles of additives in the spraying mixture. Further details can be described via recommendations.')}</span>
                            <FontAwesomeIcon icon={faCircleInfo} ></FontAwesomeIcon></div>
                    </Selectinput>
                   </div>
                </div>
                {/* JSON.stringify(formValues.registration[currentTab])*/ }
            </div>
        </div>
        <div  ref={scrollRefs[1]} className="pt-4" >
            <h5 className="pt-5">{t('Application Timing')}
            <div className="position-relative infoDiv">
                            <span>{t('Crop or target-based definition of registered timing of application. Generic timing thresholds can be defined via “General Timing” and specific thresholds via “Start” and “End”.')}</span>
                            <FontAwesomeIcon icon={faCircleInfo} ></FontAwesomeIcon></div> </h5>

            <div className="formRow row"> 
            {<AddMoreReusable formValues={formValues.registration[currentTab].applicationTimings} formConfig={appTimingFormConfig}
                        handleChange={handleChangeForArray} 
                        addMore={addMore} 
                        manageModal={manageModal}
                        deleteAddMore={deleteAddMore}></AddMoreReusable>}    
            </div>    
        </div>
        <div  ref={scrollRefs[2]}  >
            <h5 className="pt-5">{t('Product Application Rate')}</h5>
                <div className="row formRow">
                    <div className="col-sm-4 col-12"> 
                    <label className="w-100  ">{t("Min Rate")}
                        </label>
                        <input type="number" data-testid="applicationRateMin" value={formValues.registration[currentTab].applicationRateMin}  className="w-100"  onChange={(e)=>handleChange(e.target.value,'applicationRateMin')}/> 
                    </div>
                    <div className="col-sm-4 col-12"> 
                    <label className="w-100  ">{t("Max Rate")}
                        </label>
                        <input type="number" data-testid="applicationRateMax" value={formValues.registration[currentTab].applicationRateMax}    className="w-100"  onChange={(e)=>handleChange(e.target.value,'applicationRateMax')} /> 
                    </div>
                    <div className="col-sm-4 col-12">
                    {formValues.registration[currentTab].applicationRateUom && <SelectinputSingle config={{  endpoint:"uom", valueKey:"", labelKey:"name", labelTxt:t("Unit of Measurement"),
                            singleSelect:true}} value={formValues.registration[currentTab].applicationRateUom}  mapTo='applicationRateUom' handleChanges={handleChange} 
                    ></SelectinputSingle>}
                    {!formValues.registration[currentTab].applicationRateUom && <SelectinputSingle config={{  endpoint:"uom", valueKey:"", labelKey:"name", labelTxt:t("Unit of Measurement"),
                            singleSelect:true}} value=""  mapTo='applicationRateUom' handleChanges={handleChange} 
                    ></SelectinputSingle>}
                    </div>
                </div>
            </div>
            <div  ref={scrollRefs[3]} className="pt-4" >
            <h5 className="pt-5">{t('Application Volume')}</h5>
            
            <div className="formRow row">
            {<AddMoreReusable formValues={formValues.registration[currentTab].waterApplications} formConfig={appVolumeFormConfig}
                        handleChange={handleChangeForArray} 
                        addMore={addMore} 
                        manageModal={manageModal}
                        deleteAddMore={deleteAddMore}></AddMoreReusable>}    
            </div>
            </div>
            <div  ref={scrollRefs[4]} className="pt-4" >
            <h5 className="pt-5">{t('Restrictions')}</h5>
                <div className="row formRow">
                    <div className="col-sm-3 col-12"> 
                    <label className="w-100  ">{t("Pre-Harvest Interval (days)")}
                        </label>
                        <input type="number" data-testid="phiDays" value={formValues.registration[currentTab].phiDays?formValues.registration[currentTab].phiDays:""}  className="w-100"  onChange={(e)=>handleChange(e.target.value,'phiDays')}/> 
                    </div>
                    <div className="col-sm-3 col-12"> 
                    <label className="w-100  ">{t("Pre-Planting Interval (days)")}
                        </label>
                        <input type="number" data-testid="ppiDays"  value={formValues.registration[currentTab].ppiDays?formValues.registration[currentTab].ppiDays:""}  className="w-100"  onChange={(e)=>handleChange(e.target.value,'ppiDays')}/> 
                    </div>
                    <div className="col-sm-3 col-12"> 
                    <label className="w-100  ">{t("Pre-Grazing Interval (days)")}
                        </label>
                        <input type="number" data-testid="pgiDays" value={formValues.registration[currentTab].pgiDays?formValues.registration[currentTab].pgiDays:""}  className="w-100"  onChange={(e)=>handleChange(e.target.value,'pgiDays')}/> 
                    </div>
                    <div className="col-sm-3 col-12"> 
                    <label className="w-100  ">{t("Re-Entry Interval (hours)")}
                        </label>
                        <input type="number" data-testid="reiHours"  value={formValues.registration[currentTab].reiHours?formValues.registration[currentTab].reiHours:""}  className="w-100"  onChange={(e)=>handleChange(e.target.value,'reiHours')}/> 
                    </div>
                </div>
                <div className="row formRow">
                    <h6 className="mt-1">
                    {t('Days Between Applications')}
                    <div className="position-relative infoDiv">
                            <span>{t('Interval in days required for sequential applications of the same product.')}</span>
                            <FontAwesomeIcon icon={faCircleInfo} ></FontAwesomeIcon></div>
                    </h6>
                    <div className="col-sm-3 col-12"> 
                    <label className="w-100  ">{t("Min")}
                        </label>
                        <input type="number" data-testid="daysMin" value={formValues.registration[currentTab].daysBetweenApplicationsMin?formValues.registration[currentTab].daysBetweenApplicationsMin:''}  className="w-100"  onChange={(e)=>handleChange(e.target.value,'daysBetweenApplicationsMin')}/> 
                    </div>
                    <div className="col-sm-3 col-12"> 
                    <label className="w-100  ">{t("Max")}
                        </label>
                        <input type="number" data-testid="daysBetweenApplicationsMax"  value={formValues.registration[currentTab].daysBetweenApplicationsMax?formValues.registration[currentTab].daysBetweenApplicationsMax:''}  className="w-100"  onChange={(e)=>handleChange(e.target.value,'daysBetweenApplicationsMax')}/> 
                    </div>
                </div>
                <div className="row formRow">
                <div className="col-sm-3 col-12"> 
                    <label className="w-100  ">{t("Max Number of Treatments")}
                        </label>
                        <input type="number" data-testid="maxTreatments" value={formValues.registration[currentTab].maxTreatments?formValues.registration[currentTab].maxTreatments:''}  className="w-100"  onChange={(e)=>handleChange(e.target.value,'maxTreatments')}/> 
                    </div>
                    <div className="col-sm-3 col-12"> 
                    <label className="w-100  ">{t("Max Product Amount")}
                        </label>
                        <input type="number" data-testid="maxProductApplications" value={formValues.registration[currentTab].maxProductApplications?formValues.registration[currentTab].maxProductApplications:''}  className="w-100"  onChange={(e)=>handleChange(e.target.value,'maxProductApplications')}/> 
                    </div>
                    <div className="col-sm-4 col-12">
                    {formValues.registration[currentTab].maxProductApplicationsUom && <SelectinputSingle config={{  endpoint:"uom", valueKey:"", labelKey:"name", labelTxt:t("Unit of Measurement"),
                            singleSelect:true}} value={formValues.registration[currentTab].maxProductApplicationsUom}  mapTo='maxProductApplicationsUom' handleChanges={handleChange} 
                    ></SelectinputSingle>}
                    {!formValues.registration[currentTab].maxProductApplicationsUom   && <SelectinputSingle config={{  endpoint:"uom", valueKey:"", labelKey:"name", labelTxt:t("Unit of Measurement"),
                    singleSelect:true}} value=''  mapTo='maxProductApplicationsUom' handleChanges={handleChange} 
                    ></SelectinputSingle>
                    }
                    </div>
                </div>
                <div className="row formRow">
                <div className="col-sm-4 col-12">
                    {formValues.registration[currentTab].period && <SelectinputSingle config={{  endpoint:"period", valueKey:"", labelKey:"name", labelTxt:t("Period"),
                            singleSelect:true}} value={formValues.registration[currentTab].period}  mapTo='period' handleChanges={handleChange} 
                    ></SelectinputSingle>}
                    {!formValues.registration[currentTab].period  && <SelectinputSingle config={{  endpoint:"period", valueKey:"", labelKey:"name", labelTxt:t("Period"),
                            singleSelect:true}} value=""  mapTo='period' handleChanges={handleChange} 
                    ></SelectinputSingle>}
                    </div>
                </div>
            </div>
            <div  ref={scrollRefs[5]} className="pt-4" >
            <h5 className="pt-5">{t('Additional Information')}</h5>
            <div className="row formRow">
                <div className="col-sm-6 col-12"> 
                <label className="w-100  ">{t("Registration Expiry Date")}</label>
                 <DatePicker data-testid="datepicker" onChange={setRegExpiryDate} value={regExpiryDate} />
                </div>
            </div>
            <div className="row formRow">
                <div className="col-sm-6 col-12"> 
                <label className="w-100  ">{t("Comments")}</label>
                 <textarea data-testid="regComment" value={formValues.registration[currentTab].comments} onChange={(e)=>handleChange(e.target.value,'comments')}></textarea>
                </div>
            </div>
            </div>
        </>}

        </div>
        </div></div>
        <div>
        


        <div className="buttons bottomBtnWrapper">
              <div> 
             { formValues.registration.length>0 && formValues.registration[currentTab].status === 'In Editing' && (formValues.registration[currentTab].id) &&
              <button className="greenBtn" data-testid="regSendToReview" onClick={(e)=>statusChange(e,'IN_REVIEW')}>{t("Send to Review")}</button>  }
             {formValues.registration.length>0 && formValues.registration[currentTab].status === 'In Review' &&
             <>
                <button data-testid="regApprove" className="greenBtn" onClick={(e)=>statusChange(e,'APPROVED')}>{t("Approve")}</button>
                <button data-testid="regReject" className="greenBtn" onClick={(e)=>statusChange(e,'IN_EDITING')}>{t("Reject")}</button>
             </>
             }
             <button   data-testid={'share-btn'} onClick={(e)=>manageShareModalContent(e)} disabled={!formValues.product.id}>
          <FontAwesomeIcon icon={faShareNodes}/>
          &nbsp; { t("Share")}...</button>
          
             </div>
             <div className="float-left"> 
               {pristineValues.registration.length!==0 && <button data-testid="saveRegBtn" className="greenBtn" disabled={pristineValues.registration[currentTab]} onClick={(e)=>saveCrop(e)}>{t("Save")}</button>
                }
             </div>
        </div>
        </div>
        <ModalReusable config={modalConfig}>
            
            </ModalReusable>
    </>

}
export default RegistrationTab;