import React, { useContext, useState, useEffect } from "react";
import FormValuesContext from "../FormValues-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faShareNodes } from "@fortawesome/free-solid-svg-icons"; 
import SelectinputSingle from "../../../components/Selectinput/SelectInputSingle";
import ModalReusable from "../../../components/ModalReusable/ModalReusable";
import AddMoreReusable from "../../../components/AddMoreReusable/AddMoreReusable";
import { useTranslation } from 'react-i18next';
import ShareModal from "../../../components/ModalTemplates/ShareModal/ShareModal";
import { serviceEndpoints } from '../../../config/ServiceEndpoints'; 
import axios from 'axios';
import { formConfig as indicationGroupFormConfig } from "../../../config/AddMoreConfig/AddIndGroupProdTabConfig";
import {formConfig as activeIngredientsFormConfig } from "../../../config/AddMoreConfig/AddAIProdTabConfig";

const ProductTab = (props) => {
    const { t } = useTranslation();
    const { formValues, setFormValues } = useContext(FormValuesContext);
    const[modalConfig, setModalConfig] = useState({show:false});
    const [pristine, setPristine ] = useState(true);
    const [competitorProduct, setCompetitorProduct] = useState(formValues.product.company?true:false);
   // const [reviewBtn, setReviewBtn] = useState(false);

    useEffect(()=>{
       if(formValues.product.company){
        setCompetitorProduct(true)
       }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[formValues.product]);

    const handleChange =(val, mapTo)=>{ 
        const updateNow = {...formValues}; 
        updateNow.product[mapTo] = val;
        updateNow.formPristine = false;
        setFormValues({...updateNow});
        setPristine(false);
      }
      
      const handleChangeForArray = (val,indx,keyName, mapTo)=>{
        let updateNow = {...formValues};
        if(mapTo)
        updateNow.product[keyName][indx][mapTo] = val;
        else
        updateNow.product[keyName][indx] = val; 
        updateNow.formPristine = false;
        setFormValues({...updateNow});
        setPristine(false);
      } 
    const addMore = (keyName, defaults)=>{
        let updateNow = {...formValues}; 
        updateNow.product[keyName].push({...defaults});
        setFormValues({...formValues})
      }
    const manageModal = (config,status=false, inner='')=>{ 
        const modalCon = {...config,...{show:status, modalInner: inner}}
        setModalConfig(modalCon);
      } 
    const deleteAddMore  =(params)=>{ 
        let updateNow = {...formValues}; 
        delete(updateNow.product[params.keyname][[params.indx]]);
        setFormValues({...updateNow});
        setModalConfig({...modalConfig,...{show:false}})
      }
    const saveProduct = (e)=>{
        e.preventDefault(); 
        if(formValues.product.id){
            axios.put(process.env.REACT_APP_API_BASEURL+serviceEndpoints['products']+'/'+formValues.product.id,formValues.product).then((res)=>{
               manageProdApiSuccess(res);
            }).catch(e=>{
                manageProdApiCatch();
            })
        } else{
        axios.post(process.env.REACT_APP_API_BASEURL+serviceEndpoints['products'],formValues.product).then((res)=>{
            manageProdApiSuccess(res);
        }).catch(e=>{
            manageProdApiCatch();
        })}
    }
    const setRadio= (e)=>{
        if(e.target.checked){
            setCompetitorProduct(true);
        }
        else {
             setCompetitorProduct(false);
             let updateNow = {...formValues};
             delete(updateNow.product.company);
             setFormValues({...updateNow}); 
        }
    }
    const statusChange = (e,status)=>{
        e.preventDefault()
        let payload = {"statusChange":{"status":status,"statusChangeComment":""}};
        axios.put(process.env.REACT_APP_API_BASEURL+serviceEndpoints['products']+'/'+formValues.product.id+'/status',payload).then((res)=>{
            manageProdApiSuccess(res);
        }).catch(e=>{
            manageProdApiCatch();
        })
    }
    const manageProdApiCatch = ()=>{
        let updateNow = {...formValues}; 
        updateNow.formError = true;
        updateNow.formSuccess = false;
        setFormValues({...updateNow});
        setTimeout(()=>{
            let updateNow = {...formValues}; 
            updateNow.formError = false;
            setFormValues({...updateNow}); 
        }, 5000)
    }
    const manageProdApiSuccess = (res)=>{
        let updateNow = {...formValues}; 
        updateNow.product = res.data; 
        updateNow.tabStatus = {
            productHeaderStatus: res.data.productHeaderStatus,
            registrationsStatus: res.data.registrationsStatus,
            efficaciesStatus: res.data.efficaciesStatus,
            recommendationsStatus: res.data.recommendationsStatus
        }
        updateNow.formError = false;
        updateNow.formSuccess = true;
        updateNow.formPristine = true;
        setFormValues({...updateNow});
        setPristine(true);
       // setReviewBtn(true);
        props.setSavedMessageFrom('Product');
        setTimeout(()=>{
            updateNow.formSuccess = false;
            setFormValues({...updateNow}); 
        }, 5000)
    }
    const shareModalConfig = {
        show:false,
        largeWindow: true,
        modalHeader:'Share Items', 
        closeButtonHandler:manageModal,
        modalButtons:[  ],           
        }
    const manageShareModalContent = (e)=>{ 
        e.preventDefault()
        manageModal(shareModalConfig, true,<ShareModal selectedRows={[formValues.product.id]} ></ShareModal> )
      }
    return <form>
    <div>
        <div className="sideBar3  d-none"></div>
        <div className="tabs">
            <h3 className="innerHead">{t("Product")} </h3> 
            <span>{t("General product information on a country level. The main data source is the official label")}.</span>
            {/*JSON.stringify(formValues)*/}
            <div className="productRows">
                <div className="row formRow">
                    <div className="col-sm-6 col-12">
                        <label className="w-100">{t("Commercial Name")}</label>
                        <input type="text" data-testid="prodName" value={formValues.product.name} onChange={(e)=>handleChange(e.target.value,'name')} className="w-100" />
                    </div>
                    <div className="col-sm-6 col-12">
                        <label className="w-100">{t("Functional ID")} <FontAwesomeIcon icon={faCircleInfo} ></FontAwesomeIcon></label>
                        <input type="text"   value={formValues.product.functionalId} readOnly={true}  className="w-100" />
                    </div>
                </div>
                <div className="row formRow"> 
                <label class="switch">
                <input type="checkbox" data-testid='competitorProduct' value={competitorProduct} checked={competitorProduct?true:false} onChange={(e)=>setRadio(e)} />
                <span class="slider round"></span>
                </label>
                <span className="spanLabel">{t("Competitor Product")}</span>
                </div> 
                {competitorProduct && <div className="row formRow">
                    <div className="col-sm-6 col-12">
                         <SelectinputSingle config={{
                            endpoint:"company", valueKey:"", labelKey:"name",
                            singleSelect:true, width:'100%', labelTxt: t("Company Name")}} value={formValues.product.company}  mapTo='company' handleChanges={handleChange} ></SelectinputSingle>
                    </div>
                </div>}

                <div className="row formRow">
                    <div className="col-sm-6 col-12"> 
                    <SelectinputSingle config={{
                            endpoint:"countries", valueKey:"", labelKey:"name",
                            singleSelect:true, width:'100%', labelTxt: t("Country") }} value={formValues.product.region} mapTo='region' handleChanges={handleChange} ></SelectinputSingle>
                    </div>
                    <div className="col-sm-6 col-12">
                        <label className="w-100">{t("Registration Number")} <FontAwesomeIcon icon={faCircleInfo} ></FontAwesomeIcon></label>
                        <input type="text" data-testid="registrationNumber" value={formValues.product.registrationNumber} onChange={(e)=>handleChange(e.target.value,'registrationNumber')} className="w-100" />
                    </div>
                </div>

                <div className="row formRow">
                    <div className="col-sm-6 col-12">
                    <SelectinputSingle config={{
                            endpoint:"formulation", valueKey:"", labelKey:"name",
                            singleSelect:true, width:'100%', labelTxt: t("Formulation Type"), setValueBy:'id'}}  value={formValues.product.formulationType} mapTo='formulationType' handleChanges={handleChange} ></SelectinputSingle>
                    </div> 
                </div>

                <div className="row formRow"> 
                {<AddMoreReusable formValues={formValues.product.indicationGroups} formConfig={indicationGroupFormConfig}
                        handleChange={handleChangeForArray} 
                        addMore={addMore} 
                        manageModal={manageModal}
                        deleteAddMore={deleteAddMore}></AddMoreReusable>}
                </div>

                <div className="row formRow">
                {<AddMoreReusable formValues={formValues.product.activeIngredients} formConfig={activeIngredientsFormConfig}
                        handleChange={handleChangeForArray} 
                        addMore={addMore} 
                        manageModal={manageModal}
                        deleteAddMore={deleteAddMore}></AddMoreReusable>}
                </div>

                <div className="row formRow">
                    <div className="col-sm-6 col-12">
                        <label className="w-100">{t("Comments")}</label>
                        <textarea data-testid="comments" onChange={(e)=>handleChange(e.target.value,'comments')} value={formValues.product.comments}></textarea>
                    </div>
                </div>
            </div>
         </div>
    </div>
        <div className="buttons bottomBtnWrapper">
             <div>
             {formValues.product.id && formValues.product.productHeaderStatus === 'In Editing' && <button className="greenBtn" data-testid="reviewBtn" onClick={(e)=>statusChange(e,'IN_REVIEW')}>{t("Send to Review")}</button>  }
             {formValues.product.productHeaderStatus === 'In Review' &&
             <>
                <button className="greenBtn" onClick={(e)=>statusChange(e,'APPROVED')}>{t("Approve")}</button>
                <button className="greenBtn" onClick={(e)=>statusChange(e,'IN_EDITING')}>{t("Reject")}</button>
             </>
             }
             <button   data-testid={'share-btn'} onClick={(e)=>manageShareModalContent(e)} disabled={!formValues.product.id}>
          <FontAwesomeIcon icon={faShareNodes}/>
          &nbsp; { t("Share")}...</button>
          
             </div>
             <div className="float-left">
                <button data-testid="productSave" className="greenBtn" disabled={pristine} onClick={(e)=>saveProduct(e)}>{t("Save")}</button>
             </div>
        </div>
        <ModalReusable config={modalConfig}>
            
          </ModalReusable>
    </form>

}
export default ProductTab;