import Modal from 'react-bootstrap/Modal'; 
import React  from "react";  
import "./ModalReusable.css";
function ModalReusable(props) { 
  return (
    <Modal show={props.config.show}  onHide={props.config.closeButtonHandler} className={props.config.largeWindow?"bigModal":""}>
      <Modal.Dialog >
        {props.config.modalHeader && <Modal.Header closeButton>
          <Modal.Title><span data-testid="modal-title">{props.config.modalHeader}</span></Modal.Title>
        </Modal.Header>}

        <Modal.Body>
        {props.config.modalInner} 
        </Modal.Body>

        <Modal.Footer>
          {props.config.modalButtons && props.config.modalButtons.map((item,indx)=>
                <input key={'modalBtn-'+indx} type="button" data-testid={"modelBtn"+indx} value={item.label} className={item.classNames} onClick={()=>item.handler((item.params||item.params===0)?item.params:false)} />
          )}
        </Modal.Footer>
      </Modal.Dialog>
    </Modal>
  );
}

export default ModalReusable;