export const formConfig = {
  formElements: {
    row1: [
      {
        inputType: "singleselect",
        inputLabel: "Adjuvant",
        mapTo: "adjuvant",
        endpoint: "adjuvant",
        valueKey: "",
        labelKey: "name",
      },
    ],
    row2: [
      {
        inputType: "text",
        inputLabel: "Most commonly used rate",
        mapTo: "mostCommonlyUsedRate",
      },
      {
        inputType: "text",
        inputLabel: "Min Rate",
        mapTo: "applicationRateMin",
      },
      {
        inputType: "text",
        inputLabel: "Max Rate",
        mapTo: "applicationRateMax",
      },
      {
        inputType: "singleselect",
        inputLabel: "Unit of Measurement",
        mapTo: "applicationRateUom",
        endpoint: "uom",
        valueKey: "",
        labelKey: "name",
        // width:'100%'
      },
    ],
  },
  stateKey: "recommendationAdjuvants", //key name that saves these data in useState
  addMoreText: "Add Adjuvant",
  addMoreDefaults: {},
  specificWidth: {
    row1: ["100%"],
    row2: [
      "calc( 25% - 23px)",
      "calc( 25% - 23px)",
      "calc( 25% - 23px)",
      "calc( 25% - 23px)",
    ],
  },
};
