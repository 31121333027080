export const serviceEndpoints = {
  login: "login",
  countries: "master-data/region",
  products: "products",
  indicationGroup: "master-data/indication-group",
  excel: "products/functions/export/excel",
  company: "master-data/company",
  formulation: "master-data/formulation",
  ingredient: "master-data/ingredient",
  uom: "master-data/uom",
  crop: "master-data/crop",
  trait: "master-data/trait",
  cropUsageType: "master-data/crop-usage-type",
  taskType: "master-data/task-type",
  taskMethod: "master-data/task-method",
  additive: "master-data/additive",
  appTimingType: "master-data/application-timing-type",
  apptTiming: "master-data/application-timing-name",
  waterAppType: "master-data/water-application-type",
  period: "master-data/period",
  source: "master-data/source",
  treatmentType: "master-data/treatment-type",
  ratingSystem: "master-data/rating-system",
  target: "master-data/target",
  soilType: "master-data/soil-type",
  organicMatterContent: "master-data/organic-matter-content",
  tillageType: "master-data/tillage-type",
  adjuvant: "master-data/adjuvant",
};
