import ProductTab from "./Tabs/ProductTab";
import { React, useState, useMemo } from 'react';
import FormValuesContext from "./FormValues-context";
import PristineValuesContext from "./PristineValues-context";
import RegistrationTab from "./Tabs/RegistrationTab";  
import RecommendationsTab from "./Tabs/RecommendationsTab";  
import "./Product.css"; 
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ModalReusable from "../../components/ModalReusable/ModalReusable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faCheck, faChevronRight, faChevronLeft, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { formConfig as indicationGroupFormConfig } from "../../config/AddMoreConfig/AddIndGroupProdTabConfig";
import { useParams } from 'react-router';
import axios from 'axios';
import { serviceEndpoints } from '../../config/ServiceEndpoints';
import EfficacyTab from "./Tabs/EfficacyTab";

const Product = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState({
        product:{
            indicationGroups: [ {...indicationGroupFormConfig.addMoreDefaults}],
            activeIngredients:[ {...{}}]
        },
        registration:[],
        efficacy:[],
        recommendation: [],
        formError:false,
        formSuccess:false,
        formPristine:true
    }); 
    const value = { formValues, setFormValues };
    const [ pristineValues, setPristineValues ] = useState({
        registration:[],
        efficacy:[],
        recommendation:[]
    })
    const pristines = { pristineValues, setPristineValues };
    const [menuStatus, setMenuStatus] = useState('show');
    const[modalConfig, setModalConfig] = useState({show:false});
    const [activeTab, setActiveTab] = useState(1);
    const [prevTab, setPrevTab] = useState(0);
    const [editDataLoad, setEditDataLoad] = useState(false);
    const [savedMessageFrom, setSavedMessageFrom] = useState('');
    const [firstRegVisit, setfirstRegVisit] = useState(true);
    const [regVisit, setRegVisit] = useState(true);
    const [firstEffVisit, setfirstEffVisit] = useState(true);
    const [effVisit, setEffVisit] = useState(true);
    const [firstRecVisit, setfirstRecVisit] = useState(true);
    const [recVisit, setRecVisit] = useState(true);
    const [ registrationFetchFlag, setRegistrationFetchFlag] = useState(false);
    const [efficacyFetchFlag, setEfficacyFetchFlag] = useState(false);
    const [recommendationFetchFlag, setRecommendationFetchFlag] = useState(false);
    let {id} = useParams();
    useMemo(()=>{
        if(id){
            setEditDataLoad(true);
            axios.get(process.env.REACT_APP_API_BASEURL+serviceEndpoints['products']+'/'+id).then((result)=>{
              console.log(result.data);
              let productTabData = {
                activeIngredients:result.data.activeIngredients,
                comments: result.data.comments,
                formulationType: result.data.formulationType,
                functionalId: result.data.functionalId,
                id: result.data.id,
                indicationGroups: result.data.indicationGroups?result.data.indicationGroups.reverse():[],
                name: result.data.name,
                productHeaderStatus: result.data.productHeaderStatus,
                registrationsStatus: result.data.registrationsStatus,
                efficaciesStatus: result.data.efficaciesStatus,
                recommendationsStatus: result.data.recommendationsStatus,
                region: result.data.region,
                registrationNumber: result.data.registrationNumber,
                version: result.data.version
              }
              if(result.data.company){
                productTabData['company'] =result.data.company;
              }
              const updateNow = {...formValues}; 
              updateNow.product = productTabData;
              updateNow.tabStatus = {
                productHeaderStatus: result.data.productHeaderStatus,
                registrationsStatus: result.data.registrationsStatus,
                efficaciesStatus: result.data.efficaciesStatus,
                recommendationsStatus: result.data.recommendationsStatus
              }
              setFormValues({...updateNow});
              setEditDataLoad(false)
            })
        }
   // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const setMenu = ()=>{
        if(menuStatus === 'show')
        setMenuStatus('hide')
        else
        setMenuStatus('show')
    }
    const manageModal = (config,status, inner)=>{ 
        const modalCon = {...config,...{show:status, modalInner: inner}}
        if(!formValues.formPristine)
        setModalConfig(modalCon);
        else
        confirmedBack();
      } 
      const confirmedBack = ()=>{
        console.log('back to home');
        navigate("/home");
    }
      const goBackModalConfig = {
        show:false,
        modalHeader:t('Unsaved changes'), 
        closeButtonHandler:manageModal,
        modalButtons:[{label:t('Cancel'), handler:manageModal, dataTestid:"goBackCancel"},
                      {label:t('Close Product Configuration'),  classNames:"greenBtn", handler:confirmedBack}
                    ],           
        }
    const goBackConfirm = ()=>{
         manageModal(goBackModalConfig, true, <>{t('You may have unsaved changes. Are you sure you want to continue?')}</>)
    }
    const saveProduct = ()=>{ 
        if(!formValues.formPristine){
            if(formValues.product.id){
                axios.put(process.env.REACT_APP_API_BASEURL+serviceEndpoints['products']+'/'+formValues.product.id,formValues.product).then((res)=>{
                    manageApiSuccess(res,'prod');
                }).catch(e=>{
                    manageProdApiCatch();
                })
            } else{
            axios.post(process.env.REACT_APP_API_BASEURL+serviceEndpoints['products'],formValues.product).then((res)=>{
                manageApiSuccess(res,'prod');
            }).catch(e=>{
                manageProdApiCatch();
            })}
        }
    }
    const manageProdApiCatch = ()=>{
        let updateNow = {...formValues}; 
        updateNow.formError = true;
        updateNow.formSuccess = false;
        setFormValues({...updateNow});
        setTimeout(()=>{
            let updateNow = {...formValues}; 
            updateNow.formError = false;
            setFormValues({...updateNow}); 
        }, 5000)
    }
    const manageApiSuccess = (res,type)=>{
        let updateNow = {...formValues}; 
        let pristineNow = {...pristineValues}
        if(!pristineNow.registration)
        pristineNow.registration = [];
        if(type==='prod')
        updateNow.product = res.data; 
        if(type==='reg'){
            updateNow.registration = res.data; 
            res.data.forEach(element => {
                pristineNow.registration.push(true)    
            });
        }
        if(type==='eff'){
            updateNow.efficacy = res.data;
            res.data.forEach(element => {
                pristineNow.efficacy.push(true)    
            });
        }
        if(type==='rec'){
            updateNow.recommendation = res.data;
            res.data.forEach((element, index) => {
                if (element.cutOffMonth && element.cutOffDay) {
                    updateNow.recommendation[index].cutOffPeriod =
                        ("0" + element.cutOffDay).slice(-2) +
                        "/" +
                        ("0" + element.cutOffMonth).slice(-2);
                }
                pristineNow.recommendation.push(true)    
            });
        }
        updateNow.formError = false;
        updateNow.formSuccess = true;
        updateNow.formPristine = true;
        setFormValues({...updateNow});
        setPristineValues({...pristineNow})
        setTimeout(()=>{
            updateNow.formSuccess = false;
            setFormValues({...updateNow}); 
        }, 5000)
    }
    const closeToast = () =>{
        let updateNow = {...formValues};
        updateNow.formSuccess = false;
        setFormValues({...updateNow}); 
    }
    const manageTabs = (tab)=>{
        if(activeTab === 1 && formValues.product.id)
        saveProduct();
        //else
        if(!formValues.product.id) return;
        setPrevTab(activeTab);
        setActiveTab(tab);
        if(tab===2){
            setRegVisit(true);
            if(id && registrationFetchFlag===false){ 
                setEditDataLoad(true);
                axios.get(process.env.REACT_APP_API_BASEURL+serviceEndpoints['products']+'/'+id+'/registrations').then((result)=>{
                  console.log(result.data); 
                  setEditDataLoad(false);
                  setRegistrationFetchFlag(true)
                  manageApiSuccess(result,'reg')
                })
            }  
        }
        if(tab===3){
            setEffVisit(true);  
            if(id && efficacyFetchFlag===false){
                setEditDataLoad(true);
                axios.get(process.env.REACT_APP_API_BASEURL+serviceEndpoints['products']+'/'+id+'/efficacies').then((result)=>{
                  console.log(result.data); 
                  setEfficacyFetchFlag(true);
                  setEditDataLoad(false); 
                  manageApiSuccess(result,'eff')
                })
            }
        }
        if(tab===4){
            setRecVisit(true);
            if(id && recommendationFetchFlag===false){
                setEditDataLoad(true);
                axios.get(process.env.REACT_APP_API_BASEURL+serviceEndpoints['products']+'/'+id+'/recommendations').then((result)=>{
                setRecommendationFetchFlag(true);
                setEditDataLoad(false); 
                manageApiSuccess(result,'rec')
                })
            }
        }
    }
    return <>
     <FormValuesContext.Provider value={value}>
        <PristineValuesContext.Provider value={pristines}>
   <div className="d-flex infoWrapper">
   <FontAwesomeIcon icon={faChevronLeft} className="goBackIcon" data-testid="goBackIcon" onClick={goBackConfirm} />
        <div>
            <ul>
                <li  >   
                    <label className="pt-2 mt-1">{t('Product')}</label>
                    <h6 className="pb-3 mb-1">{formValues.product.name?formValues.product.name:'-'}</h6>
                </li>
                <li>
                    <label className="pt-2 mt-1">{t("Country")}</label>
                    <h6 className="pb-3 mb-1">{formValues.product.region?formValues.product.region.name:'-'}</h6>
                </li>
                <li>
                    <label className="pt-2 mt-1">{t("Indication")}</label>
                    <h6 className="pb-3 mb-1" data-testid="indicationInfo">{formValues.product.indicationGroups[0]?(formValues.product.indicationGroups[0].name?formValues.product.indicationGroups[0].name:'-') :'-'}</h6>
                </li>
            </ul>
        </div>
        <div className="statusIndication" style={{borderLeft: "4px solid #ccc"}}>
            <div>
                <h6>{t("Status")}</h6>
                <span>{t("In Edit")}</span>
            </div>
            <div className="eyeWrapper">
                <FontAwesomeIcon icon={faEyeSlash} />
            </div>
        </div>
   </div>
   <div className="row">
    
    <div className={"sideBar2 "+(menuStatus==='show'?'hide':'show')} data-testid="sideBar2">
    <ul className="position-relative mt-1">
            <li data-testid='productTabNum1' className={(activeTab<=1?'active':"done") + " cursor-pointer"} onClick={()=>manageTabs(1)}> <div><span className="roundedElm">1</span></div> </li>
            <li data-testid='productTabNum2' className={activeTab===2?'active':(activeTab >2?"done":"") + (!formValues.product.id?" cursor-disabled ":" cursor-pointer")} onClick={()=>manageTabs(2)}><div><span className="roundedElm">2</span></div> </li>
            <li data-testid='productTabNum3' className={activeTab===3?'active':(activeTab >3?"done":"") + (!formValues.product.id?" cursor-disabled ":" cursor-pointer")} onClick={()=>manageTabs(3)}><div><span className="roundedElm">3</span></div>  </li>
            <li data-testid='productTabNum4' className={activeTab===4?'active':"" + (!formValues.product.id?" cursor-disabled ":" cursor-pointer")} onClick={()=>manageTabs(4)}><div><span className="roundedElm">4</span></div>  </li>
        </ul>
        <span className="setMenu" data-testid="setMenu" onClick={setMenu}><FontAwesomeIcon icon={faChevronRight} /></span>
    </div>
    <div className={"sideBar1 "+menuStatus} data-testid="sideBar1">
        <ul className="position-relative mt-2">
            <li data-testid='productTabNum5' className={(activeTab<=1?'active':"done")+" w-100 cursor-pointer"} onClick={()=>manageTabs(1)}>
                <div><span className="roundedElm">1</span> <span>{t("Product")}</span></div>
                <div>
                    {formValues.product.productHeaderStatus === "In Editing" && <span className="mode editSpan">{t("Edit")}</span>}
                    {formValues.product.productHeaderStatus === "In Review" && <span className="mode reviewSpan">{t("Review")}</span>}
                    {formValues.product.productHeaderStatus === "Approved" && <span className="mode approveSpan">{t("Approved")}</span>}
                </div></li>
            <li data-testid='productTabNum6' className={(activeTab===2?'active':(activeTab >2?"done":""))+" w-100" + (!formValues.product.id?" cursor-disabled ":" cursor-pointer")} onClick={()=>{ manageTabs(2)}}>
            <div><span className="roundedElm">2</span> <span>{t("Registration")}</span> </div>
            <div>{formValues.product.registrationsStatus === "In Editing" && <span className="mode editSpan">{t("Edit")}</span>}
                    {formValues.product.registrationsStatus === "In Review" && <span className="mode reviewSpan">{t("Review")}</span>}
                    {formValues.product.registrationsStatus === "Approved" && <span className="mode approveSpan">{t("Approved")}</span>}</div></li>
            <li data-testid='productTabNum7' className={(activeTab===3?'active':(activeTab >3?"done":""))+" w-100" + (!formValues.product.id?" cursor-disabled ":" cursor-pointer")} onClick={()=>manageTabs(3)}> 
            <div><span  className="roundedElm">3</span> <span>{t("Efficacies")}</span> </div> 
            <div>{formValues.product.efficaciesStatus === "In Editing" && <span className="mode editSpan">{t("Edit")}</span>}
                    {formValues.product.efficaciesStatus === "In Review" && <span className="mode reviewSpan">{t("Review")}</span>}
                    {formValues.product.efficaciesStatus === "Approved" && <span className="mode approveSpan">{t("Approved")}</span>}</div></li>
            <li data-testid='productTabNum8' className={(activeTab===4?'active':"")+" w-100" + (!formValues.product.id?" cursor-disabled ":" cursor-pointer")} onClick={()=>manageTabs(4)}> 
            <div><span  className="roundedElm">4</span> <span>{t("Recommendation")}</span> </div>
            <div>
                    {formValues.product.recommendationsStatus === "In Editing" && (
                      <span className="mode editSpan">{t("Edit")}</span>
                    )}
                    {formValues.product.recommendationsStatus === "In Review" && (
                      <span className="mode reviewSpan">{t("Review")}</span>
                    )}
                    {formValues.product.recommendationsStatus === "Approved" && (
                      <span className="mode approveSpan">{t("Approved")}</span>
                    )}
                  </div></li>
        </ul>
       
    </div>
{/* with second side bar sideBar3*/}
<div>
   
        
       
        {activeTab === 1 && <>
             
            <ProductTab setSavedMessageFrom={setSavedMessageFrom}></ProductTab> 
             </>}
        {activeTab === 2 && <>
        <RegistrationTab setSavedMessageFrom={setSavedMessageFrom} firstRegVisit={firstRegVisit} setfirstRegVisit={setfirstRegVisit} 
        regVisit={regVisit} setRegVisit={setRegVisit}></RegistrationTab>
        </>}
        {activeTab === 3 && <>
        <EfficacyTab setSavedMessageFrom={setSavedMessageFrom} firstEffVisit={firstEffVisit} setfirstEffVisit={setfirstEffVisit}
        effVisit={effVisit} setEffVisit={setEffVisit}></EfficacyTab>
        </>}  
        {activeTab === 4 && <>
            <RecommendationsTab setSavedMessageFrom={setSavedMessageFrom} firstRecVisit={firstRecVisit} setfirstRecVisit={setfirstRecVisit} recVisit={recVisit} setRecVisit={setRecVisit}></RecommendationsTab>
        </>}
    
</div>

   </div>
   {formValues.formError &&
   <div className="warning" data-testid="saveError"><span>!</span> <span>errormessage</span><span className="float-right">x</span></div>
    }
    {formValues.formSuccess &&
    <div className="success" data-testid="saveSuccess">
        <FontAwesomeIcon icon={faCheck} />
        {savedMessageFrom? <>{savedMessageFrom}</>:
        <>{prevTab === 1 && <>Product </>}
          {prevTab === 2 && <>Registration </>}
          {prevTab === 3 && <>Efficacies </>}
          {prevTab === 4 && <>Recommendation </>}</>} has been successfully saved!
        <span className="float-right cursor-pointer" onClick={closeToast}>x</span></div>}

        <ModalReusable config={modalConfig}>
            
          </ModalReusable>
         {editDataLoad && <div className="loader"><span></span></div>}
         </PristineValuesContext.Provider>
    </FormValuesContext.Provider>
    </>

}
export default Product;