import './App.css'; 
import "bootstrap/dist/css/bootstrap.min.css"; 
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout/Layout";
import Login from "./pages/Login/Login";
import Home from "./pages/Home/Home" 
import  {addMock, enableMocking, getLoaderStatus} from './services/AxiosInterceptor';
import {  useMemo } from 'react'; 
import i18n from './i18n';
import { defaultLanguage } from './config/LanguageConfig';
import Product from './pages/Product/Product'; 

function App() {
  useMemo(()=>{
    addMock();
     /* istanbul ignore next */
    enableMocking(process.env.REACT_APP_ISMOCK==='true'?true:false);
    getLoaderStatus().subscribe((loadr)=>{})
    i18n.changeLanguage(defaultLanguage);
  },[]);
 return(
    <div data-testid="mainWrapper">
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/new-product" element={<Product />} />
          <Route path="/edit-product/:id" element={<Product />} /> 
          <Route path="*" element={<Login />} />
        </Route>
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
