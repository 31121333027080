export const languages = [{
    id:"en",
    name:"English"
    },
    {
    id:"de",
    name:"Deutsche"
    },
    {
    id:"pl",
    name:"Polski"
    }];
  
  export const defaultLanguage = "en";