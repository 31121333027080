export const formConfig = {
    formElements:[
                    {
                        inputType: 'singleselect',
                        inputLabel: 'Group of Indication',
                       // mapTo: 'indication',
                        endpoint:"indicationGroup", valueKey:"", labelKey:"name",
                        width:'100%'
                    }
                ],
    stateKey : 'indicationGroups', //key name that saves these data in useState 
    addMoreText: 'Add Group of Indication',
    addMoreDefaults : { 
    }
}