

import { useTranslation } from 'react-i18next';
import Selectinput from '../Selectinput/Selectinput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import SelectinputSingle from '../Selectinput/SelectInputSingle';
const AddMoreFormTemplate = (props) => {
    
  const {t} = useTranslation();
return(
  props.elems && props.elems.map((item, indx2)=> 
    <div key={props.formConfig.stateKey+props.indx+indx2} className='addmoreColumnWrapper' 
    style={
      props.specificWidth?
      {width: props.specificWidth[indx2]}:
      {width:100/props.formConfig.formElements.length+'%'}} >
     { item.inputType==='text' && <div className='input-wrapper addmore-input-wrapper'>
          <label htmlFor={props.formConfig.stateKey+props.indx+indx2+item.mapTo}>{t(item.inputLabel)}</label>
          <input data-testid={"input"+props.formConfig.stateKey+props.indx+indx2+item.mapTo} type="text" name={item.mapTo} value={props.formValue[item.mapTo]?props.formValue[item.mapTo]:''}
           data_index={props.indx} 
           data_array={''}
           aria-label={item.inputLabel}
           id={props.formConfig.stateKey+props.indx+indx2+item.mapTo}
           onChange={props.handleChange} />
     </div> }
     { item.inputType==='multyselect' && <div> 
          {item.options ? 
          <Selectinput config={{options:item.options, width:item.width,showChips:true, labelTxt: t(item.inputLabel)}} 
          value={props.formValue[item.mapTo]} handleChanges={props.handleChange}
          itrateValues ={{indx:props.indx, mapTo:item.mapTo}} ></Selectinput>:
          <Selectinput
          handleChanges={props.handleChange} value={props.formValue[item.mapTo]}
          itrateValues ={{indx:props.indx, mapTo:item.mapTo}}
          config={{endpoint:item.endpoint, valueKey:item.valueKey, labelKey:item.labelKey,showChips:true, labelTxt: t(item.inputLabel)}}>
            {item.infoTxt && 
              <div className="position-relative infoDiv">
                      <span>{t(item.infoTxt)}</span> 
                      <FontAwesomeIcon icon={faCircleInfo} ></FontAwesomeIcon></div>
            }
          </Selectinput>
          }

     </div> }
     { item.inputType==='singleselect' && <div>  
          {item.options && 
          <SelectinputSingle config={{options:item.options, width:item.width, labelTxt: t(item.inputLabel)}} 
          value={item.mapTo?props.formValue[item.mapTo]:props.formValue} handleChanges={props.handleChange}
          itrateValues ={{indx:props.indx, mapTo:item.mapTo, stateKey:props.formConfig.stateKey}} ></SelectinputSingle>}
          {!item.options && item.mapTo && props.formValue[item.mapTo] &&
           <SelectinputSingle
          value={props.formValue[item.mapTo]}  handleChanges={props.handleChange}
          itrateValues ={{indx:props.indx, mapTo:item.mapTo, stateKey:props.formConfig.stateKey}}
          config={{endpoint:item.endpoint, valueKey:item.valueKey, labelKey:item.labelKey, labelTxt: t(item.inputLabel)}}></SelectinputSingle>
          }
          {!item.options && item.mapTo && !props.formValue[item.mapTo] &&
          <SelectinputSingle
          value=""  handleChanges={props.handleChange}
          itrateValues ={{indx:props.indx, mapTo:item.mapTo, stateKey:props.formConfig.stateKey}}
          config={{endpoint:item.endpoint, valueKey:item.valueKey, labelKey:item.labelKey, labelTxt: t(item.inputLabel)}}></SelectinputSingle>
          }
          {!item.mapTo && <SelectinputSingle
                  value={props.formValue}  handleChanges={props.handleChange}
                  itrateValues ={{indx:props.indx, mapTo:item.mapTo, stateKey:props.formConfig.stateKey}}
                  config={{endpoint:item.endpoint, valueKey:item.valueKey, labelKey:item.labelKey, labelTxt: t(item.inputLabel)}}></SelectinputSingle>
          }

     </div> }
      </div> )
)
}
export default AddMoreFormTemplate;
